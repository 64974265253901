<template>
	<div>
		<h1>Passwort zurücksetzen</h1>
		<p>
			Wenn Du Dein Passwort vergessen hast, kannst Du es hier mit wenigen klicks zurücksetzen. Du
			wirst einen Link an diese Email bekommen, mit dem Du das Passwort zurücksetzen kannst. Diese
			Email muss die Email sein, mit der Du dich registriert hast.
		</p>
		<form @submit.prevent="sendResetMail()">
			<input v-model="email" type="email" required class="styled" placeholder="Email" />
			<input type="submit" value="Email senden" class="styled mint" />
		</form>
	</div>
</template>

<script>
export default {
	data() {
		return {
			email: null,
		};
	},

	methods: {
		async sendResetMail() {
			let response = await this.getUser();
			if (response.body.empty) {
				this.$store.dispatch("alert", {
					type: "error",
					message: "Diese Email konnte nicht gefunden werden",
				});
				return;
			}
			let userId = response.body.id;
			this.$http
				.post(this.$store.getters.apiUrl + "reset-password", {
					user_id: userId,
				})
				.then((_res) => {
					this.$store.dispatch("alert", {
						type: "success",
						message: "Email wurde erfolgreich versendet",
					});
					this.$router.push("/reset-password-sent");
				})
				.catch((err) => {
					this.$store.dispatch("httpError");
					console.log(err);
				});
		},
		getUser() {
			return this.$http.get(this.$store.getters.usersUrl + "/" + this.email + "?key=email");
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

input[type="email"] {
	width: 100%;
	box-sizing: border-box;
}

@media (min-width: $m-width) {
	input {
		display: inline-block;
		max-width: 400px;
		margin-right: 20px !important;
	}
}
</style>
