<template>
	<div v-if="enabled">
		<h2 v-html="title"></h2>
		<p v-html="body"></p>
		<!-- Uncomment this to have "Froeschli" group also displayed -->
		<h3>1. Kindergarten</h3>
		<div class="groups mobile-borderless" style="margin-right: 20px">
			<div class="group" :style="froeschliStyle" style="margin-left: 0">
				<router-link :to="froeschliUrl">{{ froeschliName }}</router-link>
			</div>
		</div>
		<h3>2. Kindergarten</h3>
		<div class="groups mobile-borderless">
			<div class="group" :style="fGroup1Style">
				<router-link :to="fGroup1Url">{{ fGroup1Name }}</router-link>
			</div>
			<div class="group" :style="mGroup1Style">
				<router-link :to="mGroup1Url">{{ mGroup1Name }}</router-link>
			</div>
		</div>
		<p>
			Wir freuen uns, möglichst viele Kinder an den beiden Samstagnachmittag im Wald zu begrüssen!
		</p>
		<!-- <h3>1. Klasse</h3>
		<div class="groups mobile-borderless">
			<div class="group" :style="fGroup2Style">
				<router-link :to="fGroup2Url">{{ fGroup2Name }}</router-link>
			</div>
			<div class="group" :style="mGroup2Style">
				<router-link :to="mGroup2Url">{{ mGroup2Name }}</router-link>
			</div>
		</div> -->
		<p v-html="caption"></p>
	</div>
</template>

<script>
import content from "../../resources/new_group_news.json";

export default {
	data() {
		return {
			mGroup1: null,
			fGroup1: null,
			mGroup2: null,
			fGroup2: null,
			froeschli: null,
		};
	},
	computed: {
		enabled() {
			return this.$store.getters.features.new_groups_news.enabled;
		},
		mGroup1Id() {
			return this.$store.getters.features.new_groups_news.m_group_1_id;
		},
		fGroup1Id() {
			return this.$store.getters.features.new_groups_news.f_group_1_id;
		},
		mGroup2Id() {
			return this.$store.getters.features.new_groups_news.m_group_2_id;
		},
		fGroup2Id() {
			return this.$store.getters.features.new_groups_news.f_group_2_id;
		},
		froeschliId() {
			return this.$store.getters.features.new_groups_news.froeschli_id;
		},
		mGroup1Url() {
			return "/stufen/" + this.mGroup1Id;
		},
		fGroup1Url() {
			return "/stufen/" + this.fGroup1Id;
		},
		mGroup2Url() {
			return "/stufen/" + this.mGroup2Id;
		},
		fGroup2Url() {
			return "/stufen/" + this.fGroup2Id;
		},
		froeschliUrl() {
			return "/stufen/" + this.froeschliId;
		},
		mGroup1Name() {
			return this.mGroup1 == null ? "Lädt..." : this.mGroup1.name;
		},
		fGroup1Name() {
			return this.fGroup1 == null ? "Lädt..." : this.fGroup1.name;
		},
		mGroup2Name() {
			return this.mGroup2 == null ? "Lädt..." : this.mGroup2.name;
		},
		fGroup2Name() {
			return this.fGroup2 == null ? "Lädt..." : this.fGroup2.name;
		},
		froeschliName() {
			return "Fröschli";
		},
		mGroup1Style() {
			return this.mGroup1 == null ? "" : `background-image: url('${this.mGroup1.img_url}');`;
		},
		fGroup1Style() {
			return this.fGroup1 == null ? "" : `background-image: url('${this.fGroup1.img_url}');`;
		},
		mGroup2Style() {
			return this.mGroup2 == null ? "" : `background-image: url('${this.mGroup2.img_url}');`;
		},
		fGroup2Style() {
			return this.fGroup2 == null ? "" : `background-image: url('${this.fGroup2.img_url}');`;
		},
		froeschliStyle() {
			return this.froeschli == null ? "" : `background-image: url('${this.froeschli.img_url}');`;
		},
		title() {
			return content.title;
		},
		body() {
			return content.body;
		},
		caption() {
			return content.caption;
		},
	},

	created() {
		this.loadGroups();
	},

	methods: {
		async loadGroups() {
			this.loadGroup(this.mGroup1Id).then((res) => {
				this.mGroup1 = res;
			});
			this.loadGroup(this.fGroup1Id).then((res) => {
				this.fGroup1 = res;
			});
			this.loadGroup(this.mGroup2Id).then((res) => {
				this.mGroup2 = res;
			});
			this.loadGroup(this.fGroup2Id).then((res) => {
				this.fGroup2 = res;
			});
			this.loadGroup(this.froeschliId).then((res) => {
				this.froeschli = res;
			});
		},
		async loadGroup(id) {
			if (id == null) return null;
			let url = this.$store.getters.apiUrl + "groups/" + id;
			try {
				return (await this.$http.get(url)).body;
			} catch (err) {
				this.$store.dispatch("httpError");
				console.error(err);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars.scss";

.groups {
	display: flex;
	flex-direction: column;

	.group {
		background-color: #eee;
		background-size: cover;
		background-position: center center;
		margin: 10px 0;
		box-sizing: border-box;
		height: 120px;

		a {
			display: flex;
			width: 100%;
			height: 100%;
			justify-content: center;
			align-items: center;
			color: #fff;
			font-size: 20px;
			font-weight: bold;
			position: relative;
			text-shadow: 0 0 10px #0008;

			&:hover::before {
				content: "";
				height: 100%;
				width: 100%;
				background-color: #fff7;
				position: absolute;
			}
		}
	}
}

@media (min-width: $m-width) {
	.groups {
		flex-direction: row;

		.group {
			flex-basis: 50%;
			&:first-child {
				margin-right: 10px;
			}
			&:last-child {
				margin-left: 10px;
			}
		}
	}
}
</style>
