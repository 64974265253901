import store from "@/store";
import Vue from "vue";

/**
 * urlBase64ToUint8Array
 *
 * @param {string} base64String a public VAPID key
 * @export
 *
 */
export function urlBase64ToUint8Array(base64String) {
	const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (var i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

export async function permissionState() {
	if (!("serviceWorker" in navigator)) {
		console.warn("Service workers not supported");
		return "denied";
	}

	const vapidEndpoint = store.getters.apiUrl + "push/vapid";
	const vapid = await Vue.http
		.get(vapidEndpoint)
		.catch((e) => store.dispatch("httpError", e))
		.then((x) => x.data);
	const sw = await navigator.serviceWorker.ready;
	return await sw.pushManager.permissionState({
		userVisibleOnly: true,
		applicationServerKey: urlBase64ToUint8Array(vapid),
	});
}

export async function getPushSubscriptionStatus(subject, user_id) {
	if (!subject || !user_id) return "PUSH_UNKNOWN";
	const endpoint = await getPushSubscription().then((x) => x?.endpoint);
	if (!endpoint) return "PUSH_UNKNOWN";
	const url = store.getters.apiUrl + "push/status";
	const params = { endpoint, subject, user_id };
	return Vue.http.get(url, { params }).then((x) => x.data);
}

export async function getPushSubscription() {
	if (!("serviceWorker" in navigator)) {
		console.error("Failed to get subscription: Service workers not supported");
		return;
	}

	const sw = await navigator.serviceWorker.ready;

	return await sw.pushManager.getSubscription();
}

/**
 * Subscribes the current user to push notifications under the given subject
 *
 * @export
 * @param {*} subject Subject to subscribe to
 */
export async function subscribe(subject, type = "PUSH") {
	if (!("serviceWorker" in navigator)) {
		console.error("Failed to subscribe: Service workers not supported");
		store.dispatch("alert", {
			type: "error",
			title: "Nicht unterstützt",
			message:
				"Push-Benachrichtigungen sind auf deinem Browser nicht unterstützt. Bitte verwende einen " +
				"anderen Browser. (Tipp: auf iOS ist Push auf keinem Browser unterstützt)",
			duration: 10,
		});
		return;
	}

	const subscribeEndpoint = store.getters.apiUrl + "push/subscribe";

	let push = await getPushSubscription();

	if (!push) {
		const vapidEndpoint = store.getters.apiUrl + "push/vapid";
		const vapid = await Vue.http
			.get(vapidEndpoint)
			.catch((e) => store.dispatch("httpError", e))
			.then((x) => x.data);
		const sw = await navigator.serviceWorker.ready;
		push = await sw.pushManager.subscribe({
			userVisibleOnly: true,
			applicationServerKey: urlBase64ToUint8Array(vapid),
		});
	}

	const data = {
		type: type,
		user: store.state.user.id,
		subject: subject,
		subscriber: push,
	};

	console.log("Subscription data:");
	console.log(data);

	const sub = await Vue.http
		.post(subscribeEndpoint, data)
		.catch((e) => store.dispatch("httpError", e))
		.then((x) => x.data);

	store.dispatch("alert", {
		title: "Erfolg",
		message: sub.message,
		duration: 5,
		type: "success",
	});

	return push;
}

export async function unsubscribe(endpoint, subject = null, user_id = null, type = null) {
	const url = store.getters.apiUrl + "push/unsubscribe";
	const params = { endpoint };
	if (type) params.type = type;
	if (subject) params.subject = subject;
	if (user_id) params.user_id = user_id;
	const res = await Vue.http.delete(url, { params }).then((x) => x.data);
	store.dispatch("alert", {
		title: "Erfolgreich abbestellt",
		message: res.message,
		duration: 5,
		type: "success",
	});
	return res;
}
