import axios from "axios";

/**
 * Checks whether a string is absolute or relative and applies /#/ if it is relative
 * @param {string} url Url to check
 */
function getAbsoluteOrRelativeUrl(url) {
	if (typeof url !== "string") return null;
	if (url.match(/^(https?:\/\/)?\w+\.\w+.*/)) {
		return url;
	} else {
		return `/#/${url}`;
	}
}
export const transforms = [
	{
		tag: "textbox",
		format: (args, body) =>
			`<div class="news-info-box mobile-borderless ${args[0]}">
				<div class="news-info-text">
					${body}
				</div>
			</div>`,
	},
	{
		tag: "link",
		format: (args, body) =>
			`<a href="${getAbsoluteOrRelativeUrl(args[0])}" class="styled red">${body}</a>`,
	},
	{
		tag: "buttonlink",
		format: (args, body) => {
			let color = "mint";
			if (args.length > 1) color = args[1];
			return `<a class="button ${color} styled" href="${getAbsoluteOrRelativeUrl(
				args[0]
			)}">${body}</a>`;
		},
	},
	{
		tag: "stufenlink",
		format: async (args, body, thisarg) => {
			let groupName = args[0];
			let request = `${thisarg.$store.getters.apiUrl}group-functions/group-by-name/${groupName}`;
			let groupId;
			try {
				groupId = (await axios.get(request, { params: { fields: "id" } })).data.id;
			} catch (error) {
				console.error(error);
				return "Fehler";
			}
			return `<a href="/#/stufen/${groupId}/feed" class="styled red">${body}</a>`;
		},
	},
	{
		tag: "stufenbox",
		format: async (args, _body, thisarg) => {
			let groupName = args[0];
			let request = `${thisarg.$store.getters.apiUrl}group-functions/group-by-name/${groupName}`;
			let group;
			try {
				group = (await axios.get(request, { params: { fields: "id,name,img_url" } })).data;
			} catch (error) {
				console.error(error);
				return "Fehler";
			}
			return `<div class="group mobile-borderless" style="background-image: url(${group.img_url});">
				<a href="/#/stufen/${group.id}/feed">${group.name}</a>
			</div>`;
		},
	},
];
