<template>
	<div>
		<h1>Organisation</h1>
		<p>
			Cevianerinnen und Cevianer mit gleichem Alter und Geschlecht bilden zusammen eine
			<router-link to="/stufen" class="styled">Stufen</router-link> - Mädchen und Knaben sind also
			in getrennten Stufen (die Ausnahme bildet dabei die
			<router-link to="/stufen/9/feed" class="styled">Cevi-Fröschli</router-link>). Die Stufe und
			ihre Leiter (Stufen- und Gruppenleiter) bleiben in der Regel über die ganze Zeit (7 Jahre)
			zusammen. Ein bis zwei erwachsene Stufenleiter/-innen (SL; ursprünglich die Leiter der
			jetzigen Gruppenleiter) leiten die Stufe. Einige Gruppenleiter/-innen (GL), die normalerweise
			sechs oder sieben Jahre älter sind als die Teilnehmer, kümmern sich um die Kinder. Gewisse
			Stufen teilen sich in verschiedene Gruppen auf, die dann von 1 bis 2 GLs geleitet werden.
			<br />
			<br />Die unten stehende Abbildungen (Cevi-Schnägg) erklärt das System bildlich (links Frauen,
			rechts Männer; Zum vergrössern anklicken):
		</p>
		<div id="diagram">
			<div>
				<h3>Frauen</h3>
				<div class="clickable-image" @click="$refs.popupImg1.open()">
					<img
						src="http://api.ceviwie.ch/upload/img/static/cevi-schnaegg-f-alt.png"
						class="thumbnail"
					/>
				</div>
			</div>
			<div>
				<h3>Männer</h3>
				<div class="clickable-image" @click="$refs.popupImg2.open()">
					<img
						src="http://api.ceviwie.ch/upload/img/static/cevi-schnaegg-m-alt.png"
						class="thumbnail"
					/>
				</div>
			</div>
		</div>
		<popup ref="popupImg1" nobuttons>
			<h3>Frauen</h3>
			<div class="lightbox">
				<img src="http://api.ceviwie.ch/upload/img/static/cevi-schnaegg-f.jpg" alt="titelbild" />
			</div>
		</popup>
		<popup ref="popupImg2" nobuttons>
			<h3>Männer</h3>
			<div class="lightbox">
				<img src="http://api.ceviwie.ch/upload/img/static/cevi-schnaegg-m.jpg" alt="titelbild" />
			</div>
		</popup>
	</div>
</template>

<script>
import Popup from "@/views/Popup";

export default {
	title: ({ shortAppend }) => shortAppend("Organisation"),
	components: { Popup },
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

div.clickable-image {
	width: 100%;
	box-shadow: 0 0 15px rgba($dark, 0.2);
	border-radius: $border-radius-large;

	&:hover {
		cursor: pointer;
	}

	.thumbnail {
		display: block;
		margin: auto;
		padding: 20px;
	}
}
div#diagram {
	width: 100%;
}
.lightbox {
	img {
		width: 100%;
	}
}

@media screen and (min-width: $m-width) {
	div#diagram {
		display: grid;
		width: max-content;
		grid-template-columns: 1fr 1fr;
		grid-gap: 50px;
	}
	.clickable-image {
		border: 2px solid transparent;

		&:hover {
			border: 2px solid rgba($mint, 0.3);
		}

		.thumbnail {
			margin: 0;
			box-sizing: border-box;
			width: 100%;
		}
	}
}
</style>
