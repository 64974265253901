import emailValidator from "./forms/validators/email.validator";
import telValidator from "./forms/validators/tel.validator";

export default {
	title: "Zopf bestellen",
	items: {
		firstName: {
			type: "text",
			description: "Vorname",
			required: true,
		},
		lastName: {
			type: "text",
			description: "Nachname",
			required: true,
		},
		address_street: {
			type: "text",
			description: "Strasse",
			required: true,
			class: "colspan-5",
		},
		address_nr: {
			type: "text",
			description: "Nr.",
			required: true,
			class: "colspan-1",
		},
		address_zip: {
			type: "number",
			description: "PLZ",
			required: true,
			class: "colspan-2",
		},
		address_city: {
			type: "text",
			description: "Ort",
			required: true,
			class: "colspan-4",
		},
		email: {
			type: "email",
			description: "Email",
			required: true,
			validator: {
				validate: emailValidator,
				errorMessage: "E-Mail Adresse ungültig",
			},
		},
		tel: {
			type: "tel",
			description: "Telefon (optional)",
			validator: {
				validate: telValidator,
				errorMessage: "Telefonnummer ungültig",
			},
		},
		amount_small: {
			type: "number",
			value: 0,
			description: "Anzahl kleine Zöpfe (300g, CHF 6.-)",
		},
		amount_large: {
			type: "number",
			value: 0,
			description: "Anzahl grosse Zöpfe (600g, CHF 9.-)",
		},
		time: {
			type: "dropdown",
			description: "Gewünschte Lieferzeit",
			value: "time_8_9",
			options: [
				{ id: "time_8_9", name: "8 - 9 Uhr" },
				{ id: "time_9_10", name: "9 - 10 Uhr" },
				{ id: "time_10_11", name: "10 - 11 Uhr" },
			],
			class: "span",
			required: true,
		},
		_: {
			type: "space",
		},
		comment: {
			type: "textarea",
			description: "Bemerkungen",
			class: "colspan-12",
			rows: 2,
		},
		submit: {
			type: "submit",
			value: "Bestellen",
			class: "mobile-span align-right colspan-12",
		},
	},
};
