import { render, staticRenderFns } from "./Feedback.vue?vue&type=template&id=3fe2ab60&scoped=true"
import script from "./Feedback.vue?vue&type=script&lang=js"
export * from "./Feedback.vue?vue&type=script&lang=js"
import style0 from "./Feedback.vue?vue&type=style&index=0&id=3fe2ab60&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fe2ab60",
  null
  
)

export default component.exports