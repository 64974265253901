<template>
	<div id="main">
		<div v-if="authenticated">
			<h1>Stufen-Bio bearbeiten</h1>
			<text-editor ref="editor" />
			<div id="buttons">
				<button class="styled padded blue" style="float: left" @click="loadBio()">
					Aktuelle Bio laden
				</button>
				<button class="styled padded blue" @click="$refs.popupCancel.open()">Abbrechen</button>
				<button class="styled padded blue" @click="$refs.popupSave.open()">Speichern</button>
			</div>
			<!--Popups-->
			<popup ref="popupSave" title="Anschlag speichern" @confirm="saveBio()">
				<p>Willst du die Stufen-Bio speichern und öffentlich stellen?</p>
			</popup>
			<popup
				ref="popupCancel"
				title="Bearbeiten abbrechen?"
				confirm-text="Bearbeiten abbrechen"
				cancel-text="Weiter bearbeiten"
				@confirm="cancelEdit()"
			>
				<p>Willst du wirklich das Bearbeiten abbrechen? Jegliche Änderungen werden zurückgestzt.</p>
			</popup>
		</div>
		<div v-else>
			<no-permission title="Stufen-Bio bearbeiten" />
		</div>
	</div>
</template>

<script>
import url from "url";
import TextEditor from "../../components/TextEditor";
import Popup from "../../views/Popup";
import NoPermission from "./NoPermission";
import GroupAuth from "@/mixins/groupAuth";
export default {
	components: { TextEditor, Popup, NoPermission },
	mixins: [GroupAuth],

	data() {
		return {
			authenticated: false,
		};
	},

	computed: {
		groupId() {
			return this.$route.params.id;
		},
	},

	created() {
		this.authenticateGroupUser().then((authenticated) => {
			this.authenticated = authenticated;
			if (authenticated) {
				this.loadBio();
			}
		});
	},

	methods: {
		loadBio() {
			this.$http
				.get(`${this.$store.getters.groupsUrl}/${this.$route.params.id}`)
				.then((response) => {
					let bio = response.body.group_bio;
					this.$refs.editor.setEditorContent(bio);
				})
				.catch((err) => {
					console.log(err);
					this.$store.dispatch("httpError");
				});
		},
		saveBio() {
			// Close popup
			this.$refs.popupSave.close();
			// Set app loading
			this.$store.commit("setAppLoadingStatus", true);
			this.$store.commit("setAppLoadingText", "Anfrage wird verarbeitet...");
			// Send request
			let endpoint = url.resolve(
				this.$store.getters.apiUrl,
				`group-functions/update-group-bio/${this.groupId}`
			);
			let data = {
				content: this.$refs.editor.getEditorContent(),
			};
			this.$http
				.put(endpoint, data)
				.then((res) => this.$store.dispatch("alert", { message: res.body, type: "success" }))
				.catch((err) => this.$store.dispatch("alert", { message: err.body, type: "error" }))
				.finally(() => {
					this.$store.commit("setAppLoadingStatus", false);
					setTimeout(() => this.$router.push("feed"), 1000);
				});
		},
		cancelEdit() {
			this.$router.push("feed");
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";
#buttons {
	text-align: right;
	margin-bottom: $m-el-padding;
}

@media (min-width: $m-width) {
	#buttons {
		margin-bottom: $d-el-padding;
	}
}
</style>
