<template>
	<div>
		<article v-for="(article, index) of articles" :id="getArticleId(article.title)" :key="index">
			<h3 v-if="!article.title_hidden && article.title">{{ article.title }}</h3>
			<p v-if="article.transformedBody" v-html="article.transformedBody"></p>
			<span v-if="article.date" class="caption">
				Geschrieben am:
				<span style="color: #000a; font-weight: bold">{{
					" " + getDateString(new Date(article.date))
				}}</span>
			</span>
			<span v-if="article.author" class="caption"
				>Autor:<span style="color: #000a; font-weight: bold">{{ " " + article.author }}</span></span
			>
		</article>
	</div>
</template>

<script>
export default {
	props: {
		articles: { type: Array, default: new Array() },
	},
	methods: {
		getDateString(date) {
			return `${date.getDate()}. ${date.getMonth() + 1}. ${date.getFullYear()}`;
		},
		getArticleId(title) {
			if (!title) return null;
			if (typeof title !== "string") return null;
			// Use try catch to avoid news load failing due to compatibility
			try {
				return title
					.replace(/ /g, "-")
					.replace(/[^\w-]/g, "")
					.replace(/-{2,}/g, "-")
					.toLowerCase();
			} catch (e) {
				return null;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

span.caption {
	color: #aaa;
	display: block;
	margin-bottom: 10px;
	font-family: $app-serif-font;
	text-align: right;
}
</style>
