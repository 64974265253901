<template>
	<div class="buttons">
		<div class="push">
			<!-- Subscribe -->
			<button
				v-if="showPushSubscribe"
				class="styled blue fullwidth enable-notifications"
				@click="beforeSubscribeToPush"
			>
				<loading-spinner-alt v-if="pushLoading"></loading-spinner-alt>
				<i v-else class="fa-solid fa-bell"></i>
			</button>
			<!-- Unsubscribe -->
			<button
				v-if="showPushUnsubscribe"
				class="styled blue fullwidth enable-notifications"
				@click="pushUnsubscribe()"
			>
				<loading-spinner-alt v-if="pushLoading"></loading-spinner-alt>
				<i v-else class="fa-solid fa-bell-slash"></i>
			</button>
			<!-- Permissions denied -->
			<button v-if="showPushDenied" disabled class="styled blue fullwidth enable-notifications">
				<i class="fa-solid fa-ban"></i>
			</button>
		</div>
		<div class="mail">
			<button class="styled blue enable-notifications" @click="openEmailModal()">
				<i class="fa-solid fa-envelope"></i>
			</button>
		</div>
		<popup
			ref="confirmSubscribe"
			confirm-text="Push abonnieren"
			style="z-index: 200"
			@confirm="pushSubscribe()"
		>
			<h2>Push-Benachrichtigungen</h2>
			<p>
				Falls du benachrichtigt werden möchtest, wenn deine Helfer-Anfrage angenommen, aktualisiert
				oder abgelehnt wurde, dann kannst du dich den Push-Benachrichtigungen abonnieren.
			</p>
			<p>
				Momentan funktionieren diese auf den meisten modernen Chrome, Firefox, Edge, Opera etc.
				Browser, jedoch nicht auf iOS (Einschränkung von Apple)
			</p>
			<p>
				Du kannst danach Benachrichtigungen jederzeit wieder abbestellen, indem du auf den
				<i class="fa-solid fa-bell-slash"></i>-Button klickst.
			</p>
		</popup>
		<popup ref="confirmEmail" confirm-text="Email abonnieren" style="z-index: 200" nobuttons>
			<h2>Mail-Benachrichtigungen</h2>
			<p>
				Falls Push-Benachrichtigungen auf deinem Gerät nicht funktionieren, kannst du dich anstatt
				per Mail abonnieren.
			</p>
			<p>
				Falls du schon abonniert bist, kannst du Benachrichtigungen abbestellen, indem du die
				gleiche Email eingibst und "Abbestellen" klickst.
			</p>
			<form class="email-form" action="#" @submit.prevent>
				<label for="email">Email</label>
				<input id="email" v-model="email" required class="styled" type="email" name="email" />
				<button type="submit" class="styled mint" @click="onMailSubmit('subscribe')">
					Abonnieren
				</button>
				<button class="styled red" type="submit" @click="onMailSubmit('unsubscribe')">
					Abbestellen
				</button>
			</form>
		</popup>
	</div>
</template>

<script>
import {
	getPushSubscription,
	getPushSubscriptionStatus,
	permissionState,
	subscribe,
	unsubscribe,
} from "@/push";
import Popup from "@/views/Popup.vue";
import LoadingSpinnerAlt from "./LoadingSpinnerAlt.vue";

export default {
	name: "SubscriptionButtons",

	components: {
		LoadingSpinnerAlt,
		Popup,
	},

	props: {
		subject: {
			type: String,
			default: null,
		},

		userId: {
			type: [String, Number],
			default: null,
		},
	},

	data() {
		return {
			email: null,
			subscription: null,
			permissionStatus: "prompt",
			subscriptionStatus: "PUSH_UNKNOWN",
			pushLoading: false,
			mailLoading: false,
		};
	},

	computed: {
		subscriptionData() {
			return { subject: this.subject, userId: this.userId };
		},

		showPushSubscribe() {
			return (
				this.permissionStatus != "denied" &&
				(this.subscriptionStatus == "PUSH_UNKNOWN" ||
					this.subscriptionStatus == "PUSH_UNSUBSCRIBED")
			);
		},

		showPushUnsubscribe() {
			// Handles unsubscribing when permissions denied, so we do not check permission
			return this.subscriptionStatus == "PUSH_SUBSCRIBED";
		},

		showPushDenied() {
			return !this.showPushUnsubscribe && this.permissionStatus == "denied";
		},
	},

	watch: {
		subscriptionData(data) {
			if (data.userId && data.subject) {
				this.onInfoAvailable();
			}
		},
	},

	created() {
		this.pushStatusLoading = true;
		const data = this.subscriptionData;
		if (data.userId && data.subject) {
			this.onInfoAvailable();
		}
	},

	methods: {
		async onInfoAvailable() {
			this.pushLoading = true;

			try {
				// Check permissions
				const permissions = await permissionState();
				this.permissionStatus = permissions;

				this.subscription = await getPushSubscription();

				// Fetch subscription state
				const subscribed = await getPushSubscriptionStatus(this.subject, this.userId);
				this.subscriptionStatus = subscribed;
			} finally {
				this.pushLoading = false;
			}
		},

		beforeSubscribeToPush() {
			this.$refs.confirmSubscribe.open();
		},

		async pushSubscribe() {
			this.pushLoading = true;
			try {
				const sub = await subscribe(this.subject, "PUSH");
				this.subscription = sub;
				this.subscriptionStatus = "PUSH_SUBSCRIBED";
				this.permissionStatus = "granted";
			} finally {
				this.pushLoading = false;
			}
		},

		async pushUnsubscribe() {
			this.pushLoading = true;
			try {
				if (!this.subscription) this.subscription = getPushSubscription();
				await unsubscribe(this.subscription.endpoint, this.subject, this.userId, "PUSH");
				this.subscriptionStatus = "PUSH_UNSUBSCRIBED";
			} finally {
				this.pushLoading = false;
			}
		},

		openEmailModal() {
			this.$refs.confirmEmail.open();
		},

		async onMailSubmit(action) {
			if (!this.email || this.email == "") return;

			const subscriber = { endpoint: this.email };

			if (action === "subscribe") {
				const data = {
					type: "MAIL",
					user: this.userId,
					subject: this.subject,
					subscriber: subscriber,
				};
				const endpoint = this.$store.getters.apiUrl + "push/subscribe";
				this.pushStatusLoading = true;
				const res = await this.$http
					.post(endpoint, data)
					.catch(this.displayError)
					.finally(() => {
						this.$refs.confirmEmail.close();
						this.setReady();
					});
				this.displaySuccess(res.data.message);
			} else if (action === "unsubscribe") {
				const params = {
					subject: this.subject,
					endpoint: this.email,
					user_id: this.userId,
				};
				const endpoint = this.$store.getters.apiUrl + "push/unsubscribe";
				await this.$http
					.delete(endpoint, { params })
					.catch(this.displayError)
					.finally(() => {
						this.$refs.confirmEmail.close();
						this.setReady();
					});
				this.displaySuccess();
			} else {
				console.error("Unsupported action");
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";

.enable-notifications {
	width: 1em;
	height: 1em;
	line-height: 1em;
	box-sizing: content-box;
	text-align: center;
}

.buttons {
	display: flex;
	gap: 10px;
}

.email-form {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
}

@media screen and (max-width: $m-width) {
	.email-form {
		flex-direction: column;
	}
}
</style>
