<template>
	<nav ref="nav" :class="{ active: menuEnabled }">
		<div id="menubar">
			<router-link to="/home" class="home-link">
				<img src="@/assets/svg/logo-ceiviwie-white.svg" alt="logo" @click="onLogoClick" />
			</router-link>
			<menu-icon-widget ref="menuIcon" @toggled="toggleMenu" />
		</div>
		<transition enter-active-class="animated fadeInDown" leave-active-class="animated fadeOutUp">
			<div v-show="menuEnabled" id="menu-screen">
				<transition
					:enter-active-class="'animated ' + (transition.reverse ? 'fadeInLeft' : 'fadeInRight')"
					:leave-active-class="'animated ' + (transition.reverse ? 'fadeOutRight' : 'fadeOutLeft')"
				>
					<div v-show="!transition.transitioning" id="menu">
						<button v-if="activeComposition.root" class="btn-back" @click="back()">
							<i class="fas fa-arrow-left"></i>
						</button>
						<router-link
							v-for="(item, index) of activeComposition.items"
							:key="index"
							:to="item.path"
							class="menu-item"
							@click.native="handleOnClick(index)"
						>
							<span class="title">{{ item.title }}</span>
							<i v-if="item.submenus" class="fas fa-caret-right expand-icon"></i>
						</router-link>
					</div>
				</transition>
				<div id="user">
					<group-quick-actions
						v-if="showGroupQuickActions"
						mode="mobile"
						@click="$refs.menuIcon.toggleIconOverride()"
					></group-quick-actions>
					<nav-login-box :mobile="true" @clicked="$refs.menuIcon.toggleIconOverride()" />
				</div>
			</div>
		</transition>
	</nav>
</template>

<script>
import MenuIconWidget from "@/components/MenuIcon.vue";
import NavLoginBox from "@/views/NavLoginBox.vue";
import GroupQuickActions from "@/components/GroupQuickActions.vue";

export default {
	components: {
		MenuIconWidget,
		NavLoginBox,
		GroupQuickActions,
	},
	props: {
		navbarItems: {
			type: Array,
			required: true,
		},
	},

	data() {
		return {
			menus: this.navbarItems,
			menuEnabled: false,
			activeComposition: null,
			transition: {
				transitioning: false,
				reverse: false,
			},
		};
	},

	computed: {
		showGroupQuickActions() {
			return ["group_user", "helper_user", "global_admin"].includes(this.$store.state.user?.role);
		},
	},

	created() {
		this.setActiveComposition(this.menus);
	},

	methods: {
		onLogoClick() {
			if (this.menuEnabled) {
				this.$refs.menuIcon.toggleIconOverride();
			}
		},
		toggleMenu(state) {
			this.menuEnabled = state;
			if (!state) {
				this.resetComposition();
			}
			this.$emit("mobile-menu-enabled", state);
		},
		setActiveComposition(composition, root) {
			this.transition.transitioning = true;
			this.activeComposition = {
				root: root,
				items: composition,
			};
			setTimeout(() => (this.transition.transitioning = false), 150);
		},
		resetComposition() {
			this.setActiveComposition(this.menus, null);
		},
		handleOnClick(index) {
			let item = this.activeComposition.items[index];

			if (!item) {
				return;
			}

			if (!item.submenus) {
				this.$refs.menuIcon.toggleIconOverride();
				return;
			}
			this.transition.reverse = false;
			this.setActiveComposition(item.submenus, this.activeComposition.items);
		},
		back() {
			if (!this.activeComposition.root) {
				return;
			}
			this.transition.reverse = true;
			this.setActiveComposition(this.activeComposition.root, null);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

nav {
	color: #fff;
	width: 100%;
	position: sticky;
	z-index: 10;
	box-sizing: border-box;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: stretch;

	&.active {
		position: fixed;
		height: 100%;
	}

	#menubar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px $m-page-padding;
		padding-left: 0px;
		background-color: $dark;
		box-sizing: border-box;

		.home-link {
			height: 60px;
			max-width: 70%;

			img {
				height: 100%;
				width: 100%;
			}
		}
	}

	#menu-screen {
		background-color: desaturate(lighten($dark, 10%), 50%);
		box-sizing: border-box;
		overflow-y: scroll;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		position: relative;
		justify-content: space-between;

		animation-duration: $fade-time;

		@supports (backdrop-filter: none) {
			background-color: desaturate(rgba($dark, 0.8), 50%);
			backdrop-filter: blur(15px);
		}

		#user {
			background-color: $dark;
			align-items: center;
			align-self: center;
			width: 100%;
			box-sizing: border-box;
		}

		#menu {
			width: 100%;
			padding: 20px;
			box-sizing: border-box;
			overflow-y: scroll;
			font-size: 18px;

			animation-duration: $fade-time;

			.btn-back {
				position: absolute;
				top: 20px;
				left: 20px;
				color: #fff;
				font-size: 25px;
				background-color: transparent;
				border: none;
			}

			.menu-item {
				display: block;
				width: 100%;
				box-sizing: border-box;
				color: #fff;
				text-align: center;
				padding: 20px;
				text-transform: uppercase;
				letter-spacing: 2.5px;
				font-weight: 400;

				span.title {
					display: inline-block;
				}
				i.expand-icon {
					display: inline-block;
					margin-left: 10px;
				}
			}
		}
	}
}
</style>
