<template>
	<div id="container mobile-borderless">
		<div class="text-box">
			<h1>Login</h1>
			<br />
			<form @submit.prevent="submit" @input="validates = validate()">
				<form-field
					ref="identifier"
					v-model="identifier"
					name="identifier"
					type="text"
					placeholder="Benutzername oder email"
					autofocus
				/>
				<form-field
					ref="pass"
					v-model="password"
					name="password"
					type="password"
					placeholder="Passwort"
				/>
				<div class="iforgot">
					<router-link to="/reset-password" class="styled iforgot">Passwort vergessen?</router-link>
				</div>
				<toggle-switch
					v-model="stayLoggedIn"
					name="stay-logged-in"
					:default-state="true"
					color-class="vibrant"
					>Eingeloggt bleiben</toggle-switch
				>
				<input type="submit" value="Einloggen" class="styled blue" :disabled="!validates" />
			</form>
		</div>
	</div>
</template>

<script>
import FormField from "../components/FormField";
import ToggleSwitch from "../components/ToggleSwitch";

import auth from "../mixins/auth";

export default {
	components: {
		FormField,
		ToggleSwitch,
	},
	mixins: [auth],
	data() {
		return {
			identifier: "",
			password: "",
			stayLoggedIn: true,

			validates: false,

			usernameValidation: [],
			passwordValidation: [],

			locked: false,
		};
	},
	computed: {
		baseUrl: () => window.location.hostname,
	},
	created() {},
	methods: {
		validate() {
			return this.$refs.pass.validates && this.$refs.identifier.validates;
		},
		submit() {
			if (this.locke == true) {
				return;
			}
			this.locked = true;
			this.loginUser(
				{
					identifier: this.identifier,
					password: this.password,
					stayLoggedIn: this.stayLoggedIn,
				},
				(user) => {
					this.$store.dispatch("alert", {
						message: "Du bist jetzt eingeloggt als: <b>" + user.username + "</b>",
						type: "success",
					});
					this.$router.push("/home");
					this.locked = false;
				},
				(reason) => {
					if (reason == "password") {
						this.$store.dispatch("alert", {
							message: "Falsches Passwort",
							title: "Login fehlgeschlagen",
							type: "error",
						});
						this.password = null;
					} else if (reason == "identifier") {
						this.$store.dispatch("alert", {
							message: "Benutzername oder Email nicht gefunden",
							title: "Login fehlgeschlagen",
							type: "error",
						});
						this.identifier = this.password = null;
					}
					this.validates = false;
					this.locked = false;
				}
			);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/vars";

.text-box {
	background-color: #eee;
	max-width: 500px;
	margin: auto;
	padding: 20px;
	border-radius: $border-radius-large;
}
.error {
	position: relative;
	top: 10px;
}
.error-message {
	color: $red-vibrant;
	font-size: 1.2em;
	font-weight: bold;
}
label {
	position: relative;
}
h1 {
	color: $blue;
	margin-top: 20px;
}
form {
	text-align: center;
}
input {
	display: block;
	margin: $d-el-padding 0;
	width: calc(100% - 20px);
	background-color: #fff;
	font-size: 1em;

	&::placeholder {
		font-style: normal;
	}

	&[type="submit"] {
		max-width: 250px;
		margin: auto;
	}
}

.iforgot {
	margin-bottom: 20px;
	text-align: right;
}

#container {
	text-align: center;
	margin-bottom: 100px;
}

@media screen and (min-width: $m-width) {
	.text-box {
		width: 80vw;
	}
}

@media screen and (max-width: $m-width) {
	.text-box {
		box-sizing: border-box;
		width: 85vw;
	}
}
</style>
