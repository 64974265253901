<template>
	<div id="container">
		<div :class="'spinner ' + theme">
			<p v-if="infoText" class="sans">{{ infoText }}</p>
			<div class="bounce1"></div>
			<div class="bounce2"></div>
			<div class="bounce3"></div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		infoText: { type: String, default: "Bitte warten..." },
		theme: { type: String, default: "red" },
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/vars";
.spinner {
	margin: 20px auto;
	text-align: center;
}

.spinner > div {
	width: 18px;
	height: 18px;

	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.red {
	div {
		background-color: $red;
	}
	p {
		color: $red;
	}
}
.blue {
	div {
		background-color: $blue;
	}
	p {
		color: $blue;
	}
}
.mint {
	div {
		background-color: $mint;
	}
	p {
		color: $mint;
	}
}
.light {
	div {
		background-color: $light;
	}
	p {
		color: $light;
	}
}
.inverted {
	width: max-content;
	background-color: $dark;
	border-radius: $border-radius-large;
	padding: 10px;
	div {
		background-color: #fff;
	}
	p {
		color: #fff;
		margin-bottom: 5px;
		margin-top: 0;
	}
}
.inverted-transparent {
	div {
		background-color: #fff;
	}
	p {
		color: #fff;
	}
}
</style>
