<template>
	<div>
		<h1>Feedback abgeben</h1>
		<p>Hier kannst Du Feedback zur Website des Cevi Wiesendangen-Elsau-Hegi abgeben.</p>
		<form @submit.prevent="sendForm()">
			<p class="sans input-descriptor">Name (optional)</p>
			<input v-model="senderName" type="text" class="styled input" />
			<p class="sans input-descriptor">Email (optional, eingeben falls Antwort erwünscht)</p>
			<input v-model="senderEmail" type="email" class="styled input" />
			<p class="sans input-descriptor">Feedback</p>
			<textarea v-model="feedbackContent" rows="10" required></textarea>
			<input type="submit" value="Feedback senden" class="styled button mint" />
		</form>
	</div>
</template>

<script>
export default {
	data() {
		return {
			senderName: null,
			senderEmail: null,
			feedbackContent: null,
		};
	},

	methods: {
		sendForm() {
			this.$http
				.post(this.$store.getters.apiUrl + "sendmail/feedback", {
					sender_name: this.senderName,
					sender_email: this.senderEmail,
					feedback_content: this.feedbackContent,
				})
				.then((_res) => {
					this.$store.dispatch("alert", {
						message: "Feedback erfolgreich versendet",
						type: "success",
					});
				});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

.input {
	width: 100%;
	box-sizing: border-box;
}
.input-descriptor {
	font-weight: bold;
	display: block;
	margin-bottom: 0;
	padding-bottom: 5px;
	color: #666;
}

textarea {
	margin-bottom: 20px;
	margin-top: 5px;
}

@media (min-width: $m-width) {
	.input {
		max-width: 400px;
	}
}
</style>
