<template>
	<div>
		<div>
			<a class="styled" href="#" @click="$router.back()"
				><i class="fas fa-angle-left"></i> Zurück zur Übersicht</a
			>
		</div>
		<h1>Helfer anfragen</h1>
		<auto-form v-model="formModel" class="wide columns bold" @submit="handleOnSubmit"></auto-form>
	</div>
</template>

<script>
import { collection, formatDirectusDateFromString } from "@/cms/directus";
import AutoForm from "@/components/AutoForm";
import { nextSaturday } from "@/functions";
import helperRequestFormModel from "@/resources/forms/helper-request-form-model";

export default {
	name: "RequestHelpers",
	components: {
		AutoForm,
	},

	data() {
		return {
			formModel: { ...helperRequestFormModel },
			groups: [],
		};
	},

	computed: {
		administeredGroups() {
			if (!this.$store.state.loggedIn) return [];
			return JSON.parse(this.$store.state.user.administered_groups);
		},
		currentGroupId() {
			const adminGroups = this.administeredGroups;
			if (!adminGroups || adminGroups.includes("*")) return -1;
			return adminGroups[0];
		},
	},

	watch: {
		currentGroupId(val) {
			console.log("Value changed: ", val);
			if (val < 0) return;
			this.formModel.items.group.value = val.toString();
		},
	},

	created() {
		this.setStaticDefaults();
		this.fetchGroups().then((groups) => {
			this.groups = groups;
			this.setDynamicDefaults();
		});
	},

	methods: {
		async fetchGroups() {
			let groupsEndpoint = this.$store.getters.groupsUrl;
			const params = { order: ["year_DESC", "gender_ASC"] };
			return await this.$http.get(groupsEndpoint, { params }).then((res) => res.data);
		},

		setStaticDefaults() {
			const items = this.formModel.items;
			items.dateTimeFrom.value = nextSaturday(14, 0);
			items.dateTimeTo.value = nextSaturday(17, 0);
			items.briefingTime.value = nextSaturday(13, 0);
			items.briefingLocation.value = "Cevi HB";
			items.helpersMin.value = 2;
			items.helpersDesired.value = 5;
			if (this.currentGroupId >= 0) items.group.value = this.currentGroupId;
		},

		async setDynamicDefaults() {
			const items = this.formModel.items;
			items.group.options = this.groups.filter((x) =>
				this.administeredGroups.includes(parseInt(x.id))
			);
		},

		async handleOnSubmit(data) {
			this.$store.commit("setAppLoadingStatus", true);
			this.$store.commit("setAppLoadingText", "Anfrage wird verschickt...");
			const item = {
				name: data.name,
				cevi_name: data.ceviName,
				group_id: data.group,
				group_name: this.groups.find((x) => x.id == data.group).name,
				tel: data.tel,
				email: data.email,
				datetime_from: formatDirectusDateFromString(data.dateTimeFrom),
				datetime_to: formatDirectusDateFromString(data.dateTimeTo),
				location: data.operationLocation,
				briefing_location: data.briefingLocation,
				briefing_time: formatDirectusDateFromString(data.briefingTime),
				helpers_min: data.helpersMin,
				helpers_desired: data.helpersDesired,
				theme: data.theme,
				costume: data.costume,
				remarks: data.remarks,
			};
			const client = collection("helper_requests");
			try {
				await client.create(item);
				this.$store.dispatch("alert", {
					message: "Die Helferstufe wird benachrichtigt.",
					title: "Anfrage versendet",
					type: "success",
				});
				this.$router.push("/helfer/uebersicht");
			} catch (e) {
				console.log(e);
				this.$store.dispatch("alert", {
					title: "Fehler",
					message: "Die Anfrage ist inkorrekt und konnte nicht verarbeitet werden.",
					type: "error",
				});
			} finally {
				this.$store.commit("setAppLoadingStatus", false);
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
