import url from "url";
import Vue from "vue";
import Vuex from "vuex";
import config from "@/config/config";

import { bus } from "@/bus";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		user: null,
		loggedIn: false,
		userLoaded: false,
		appLoadingStatus: false,
		appLoadingText: "Lädt...",
	},
	getters: {
		sessionActive: (state) => state.user != null,
		sessionUser: (state) => (state.user ? state.user.username : null),
		sessionUserId: (state) => (state.user ? state.user.id : -1),
		apiUrl: () => config.apiConfig.apiRoot,
		usersUrl: () => url.resolve(config.apiConfig.apiRoot, config.apiConfig.usersTable) + "/",
		groupsUrl: () => url.resolve(config.apiConfig.apiRoot, config.apiConfig.groupsTable) + "/",
		userUrl: (state, getters) => url.resolve(getters.usersUrl, getters.sessionUserId),
		usersTable: () => config.apiConfig.usersTable,
		loginHashCookieName: () => "login_hash",
		sessionUserCookieName: () => "session_user",
		features: () => config.features,
	},
	mutations: {
		setUserLoaded(state, loaded) {
			state.userLoaded = loaded;
		},
		setSession: (state, user) => {
			state.user = user;
			state.loggedIn = true;
			bus.$emit("onLogin", user);
			// console.log("Set user to: ", user.username, user);
		},
		unsetSession: (state) => {
			bus.$emit("onLogout", state.user);
			state.user = null;
			state.loggedIn = false;
			// console.log("Logged out user");
		},
		setAppLoadingStatus: (state, loadingState) => {
			bus.$emit("appStatusChanged", loadingState);
			state.appLoadingStatus = loadingState;
		},
		setAppLoadingText: (state, text) => {
			state.appLoadingText = text;
		},
	},
	actions: {
		alert(_context, payload) {
			bus.$emit("alert", payload);
		},
		httpError(_context, payload) {
			const message =
				payload?.toString() ||
				"Es ist ein Fehler im System aufgetreten. Bitte den Administrator kontaktieren";
			bus.$emit("alert", {
				title: "Fehler",
				message: message,
				type: "error",
			});

			console.error(payload);
		},
		async updateUser({ commit, dispatch, getters }) {
			try {
				let user = (await Vue.http.get(getters.userUrl)).body;
				commit("setSession", user);
			} catch (err) {
				console.error(err);
				dispatch("httpError");
			}
		},
	},
});
