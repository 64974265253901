<template>
	<div
		class="text-container"
		:style="`background-color: ${backgroundColor}; padding: ${padding}; font-size: ${fontSize}`"
	>
		<p :style="'margin: 0; padding: 0; color: ' + textColor">
			<slot></slot>
		</p>
	</div>
</template>
<script>
export default {
	props: {
		backgroundColor: { type: String, default: "#e9e9e9" },
		textColor: { type: String, default: "#000" },
		padding: { type: String, default: "$d-el-padding" },
		fontSize: { type: String, default: "1em" },
	},
};
</script>
<style lang="scss" scoped>
@import "../styles/vars";

.text-container {
	padding: $d-el-padding;
	background-color: #e9e9e9;
	border-radius: $border-radius-large;
	margin: $d-el-padding 0;
	white-space: pre-line;
}
@media (max-width: $m-width) {
	.borderless {
		border-radius: 0;
		margin-right: -$m-page-padding;
		margin-left: -$m-page-padding;
		padding-top: 20px;
		padding-bottom: 20px;
	}
}
</style>
