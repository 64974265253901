<template>
	<div>
		<input
			type="file"
			:name="fileName"
			accept="image/png, image/jpeg"
			:required="required"
			:files="files"
			:multiple="multiple"
			@change="onChange"
		/>
	</div>
</template>

<script>
export default {
	props: {
		fileName: { type: String, default: "file-upload" },
		required: { type: Boolean, default: false },
		multiple: { type: Boolean, default: false },
		value: { type: FileList, default: null },
	},

	data() {
		return {
			files: null,
		};
	},

	methods: {
		onChange(event) {
			let files = event.target.files;
			this.files = files;
			this.$emit("change", this.multiple ? files : files[0]);
			this.$emit("input", this.multiple ? files : files[0]);
		},
		upload() {
			if (!this.files || this.files.length == 0) {
				console.error("[ImageUpload] Error: Can't upload with no files selected");
				return null;
			}
			if (this.multiple) {
				let uploads = [];
				for (let file of this.files) {
					uploads.push(this.uploadFile(file));
				}
				return uploads;
			} else {
				return this.uploadFile(this.files[0]);
			}
		},
		uploadFile(file) {
			let data = new FormData();
			data.append("file_0", file);
			return this.$http.post(this.$store.getters.apiUrl + "fileupload/image", data);
		},
	},
};
</script>

<style scoped></style>
