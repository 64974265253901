//Vue core
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//Vue plugins
import VueResource from "vue-resource";
import VueCookie from "vue-cookie";

//Auth
import md5 from "md5";

import config from "./config/config";

import titleMixin from "./mixins/titleMixin";

import "./registerServiceWorker";

require("./extensions");

Vue.prototype.$config = config;

Vue.config.productionTip = false;
Vue.use(VueResource);
Vue.use(VueCookie);
Vue.use(md5);

Vue.mixin(titleMixin);

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
