<template>
	<div>
		<h1>Aktuelles-Bilder verwalten</h1>
		<p>Hier kanns Du alle Aktuelles-Bilder, die man auf der Homepage sehen kann, verwalten.</p>
		<table v-if="items" class="item-list">
			<thead>
				<tr>
					<th>ID</th>
					<th>Titel</th>
					<th>URL</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="item of items" :key="item.id">
					<td>{{ item.id }}</td>
					<td>{{ item.title }}</td>
					<td>{{ item.img_url }}</td>
					<td class="actions">
						<!-- <router-link :to="'/admin/aktuelles/' + item.id + '/edit'">
              <i class="fas fa-edit edit-icon"></i>
            </router-link>-->
						<button class="delete-button" @click="deleteItem(item)">
							<i class="fas fa-trash-alt delete-icon"></i>
						</button>
					</td>
				</tr>
				<tr class="add-item-row">
					<td colspan="4" style="text-align: center" class="add-item-row">
						<router-link
							to="/admin/aktuelles/create"
							class="styled button mint"
							style="width: 250px"
						>
							<i class="fas fa-plus"></i>
						</router-link>
					</td>
				</tr>
			</tbody>
		</table>
		<div v-else>
			<loading-spinner />
		</div>
		<popup-view ref="popupConfirmDelete">
			<span>
				Bist du dir sicher, dass du das "Aktuelles"-Bild
				<b>"{{ deletionCandidate ? deletionCandidate.title : "undefinded" }}"</b> löschen willst?
				Diese Aktion kann nicht rückgängig gemacht werden!
			</span>
		</popup-view>
	</div>
</template>

<script>
import PopupView from "@/views/Popup";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
	components: {
		PopupView,
		LoadingSpinner,
	},

	data() {
		return {
			items: null,
			deletionCandidate: null,
		};
	},

	created() {
		this.loadItems();
	},

	methods: {
		deleteItem(item) {
			this.deletionCandidate = item;
			this.$refs.popupConfirmDelete.open();
			this.$refs.popupConfirmDelete.onConfirm = () => {
				let filename = item.img_url.replace(/^.*[\\/]/, "");
				this.$http
					.delete(this.$store.getters.apiUrl + "fileupload/image/" + filename)
					.then((res) => {
						console.log(res.body);
					})
					.catch((err) => {
						this.$store.dispatch("httpError");
						console.error(err);
					});
				this.$http
					.delete(this.$store.getters.apiUrl + "topical_images/" + item.id)
					.then((_res) => {
						this.$store.dispatch("alert", {
							message: "Artikel wurde erfolgreich gelöscht",
							type: "success",
						});
						this.loadItems();
					})
					.catch((err) => {
						this.$store.dispatch("httpError");
						console.error(err);
					});
			};
		},
		loadItems() {
			this.$http
				.get(this.$store.getters.apiUrl + "topical_images")
				.then((res) => {
					let body = res.body;
					if (!Array.isArray(body)) {
						body = [body];
					}
					this.items = body;
				})
				.catch((_err) => this.$store.dispatch("httpError"));
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

table.item-list {
	width: 100%;
	border-collapse: collapse;
	border: 1px solid #efefef;

	th,
	td {
		padding: 10px;
	}

	thead tr {
		text-align: left;
		border-bottom: 1px solid #efefef;
	}

	tbody tr:not(.add-item-row) {
		transition: all $fade-time;

		&:nth-child(even) {
			background-color: rgba($light, 0.3);
		}

		&:hover {
			background-color: rgba($mint, 0.1);

			td.actions * {
				visibility: visible;
				opacity: 1;
			}
		}

		td.actions {
			text-align: right;
			display: flex;

			* {
				visibility: hidden;
				opacity: 0;
				transition: all $fade-time;
			}
		}
	}
	tbody tr.add-item-row {
		border-top: 1px solid #efefef;
	}
	tbody tr.add-item-row td {
		padding: 0;
	}
}

.edit-icon {
	color: #555;
	margin-right: 10px;
	font-size: 20px;
}
.delete-icon {
	color: $red;
	font-size: 20px;
}

.edit-icon,
.delete-icon {
	display: block;
	&:hover {
		cursor: pointer;
		color: #fff;
		text-shadow: 0 0 10px $mint;
	}
}

.delete-button {
	background: transparent;
	border: none;
}
</style>
