const dateOptions = { year: "2-digit", month: "numeric", day: "numeric", weekday: "short" };
const timeOptions = { hour: "2-digit", minute: "2-digit" };
const fullOptions = { ...dateOptions, ...timeOptions };

export default {
	computed: {
		json() {
			return JSON.stringify(this.request, null, 2);
		},

		start() {
			return new Date(this.request.datetime_from);
		},

		end() {
			return new Date(this.request.datetime_to);
		},

		briefing() {
			return new Date(this.request.briefing_time);
		},

		singleDay() {
			return (
				this.start.getFullYear() == this.end.getFullYear() &&
				this.start.getMonth() == this.end.getMonth() &&
				this.start.getDate() == this.end.getDate()
			);
		},

		briefingSameDay() {
			return (
				this.singleDay &&
				this.briefing.getFullYear() == this.start.getFullYear() &&
				this.briefing.getMonth() == this.start.getMonth() &&
				this.briefing.getDate() == this.start.getDate()
			);
		},

		datetimeFrom() {
			return this.start.toLocaleString("de-CH", fullOptions);
		},

		datetimeFromNoDate() {
			if (this.singleDay) return this.start.toLocaleTimeString("de-CH", timeOptions);
			else return this.datetimeFrom;
		},

		datetimeTo() {
			if (this.singleDay) {
				return this.end.toLocaleTimeString("de-CH", timeOptions);
			} else {
				return this.end.toLocaleString("de-CH", fullOptions);
			}
		},

		briefingTime() {
			if (this.briefingSameDay) return this.briefing.toLocaleTimeString("de-CH", timeOptions);
			else return this.briefing.toLocaleString("de-CH", fullOptions);
		},

		datetimeFull() {
			return `${this.datetimeFrom} - ${this.datetimeTo}`;
		},

		timeFull() {
			return `${this.start.toLocaleTimeString(
				"de-CH",
				timeOptions
			)} - ${this.end.toLocaleTimeString("de-CH", timeOptions)}`;
		},

		groupName() {
			const local = this.request.group_name;
			if (local && local.length > 0) return local;
			if (this.group) return this.group.name;
			return "Unbekannte Stufe";
		},

		running() {
			return this.end >= new Date();
		},

		past() {
			return this.end < new Date();
		},

		nonzero() {
			return this.request.helpers_assigned > 0;
		},

		one() {
			return this.request.helpers_assigned == 1;
		},

		pending() {
			return !this.request.confirmed;
		},

		status() {
			if (this.past) return "past";
			return this.pending ? "pending" : this.nonzero ? "accepted" : "rejected";
		},
	},
};
