<template>
	<div id="nav-login-container">
		<div v-if="!sessionActive" id="login-buttons-container">
			<router-link to="/login" class="nostyle" @click.native="handleOnClicked()">
				<input type="submit" value="Login" class="styled white" />
			</router-link>
			<span> oder </span>
			<router-link to="/register" class="nostyle" @click.native="handleOnClicked()">
				<input type="submit" value="Registrieren" class="styled white" />
			</router-link>
		</div>
		<div v-else class="nav-profile-container">
			<profile-box @clicked="handleOnClicked()" />
		</div>
	</div>
</template>

<script>
import { bus } from "../bus";
import ProfileBox from "@/views/ProfileBox";

export default {
	components: {
		ProfileBox,
	},
	props: {
		mobile: {
			type: Boolean,
			required: true,
			default: true,
		},
	},

	data() {
		return {
			sessionActive: false,
		};
	},

	created() {
		this.sessionActive = this.$store.getters.sessionActive;
		bus.$on("onLogin", (_) => {
			this.sessionActive = true;
		});
		bus.$on("onLogout", () => {
			this.sessionActive = false;
		});
	},

	methods: {
		handleOnClicked() {
			this.$emit("clicked");
		},
	},
};
</script>

<style lang="scss" scoped>
@import url("https://cdn.jsdelivr.net/npm/animate.css@3.5.2/animate.min.css");
@import "../styles/vars";

.nav-profile-container {
	padding-right: 20px;
}

@media screen and (max-width: $m-width) {
	div#nav-login-container {
		padding: $m-page-padding;

		span {
			color: rgba($white, 0.5);
		}

		input {
			display: inline;
			background-color: transparent;
			color: $white;
			font-size: medium;
			padding: 0;
		}

		:nth-child(2) {
			margin-left: 0;
		}
	}
}

.box {
	position: absolute;
	left: 0;
	right: 0;
	animation-duration: $fade-time * 2;
	text-align: center;
}

@media screen and (min-width: $m-width) {
	div#nav-login-container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: max-content;
	}
	div#login-buttons-container a {
		width: max-content;

		&:first-of-type {
			margin-right: 10px;
		}
		&:last-of-type {
			margin-left: 10px;
			margin-right: 20px;
		}
	}
}
</style>
