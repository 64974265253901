export function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export function sortByProp(propName, a, b) {
	let valA = a[propName];
	let valB = b[propName];
	if (valA < valB) return -1;
	if (valA > valB) return 1;
	return 0;
}

/**
 * Gets the next Saturday after (including) today
 *
 * @param {number} hours Hour of date to set (default 0)
 * @param {number} minutes Minute of hour to set (default 0)
 */
export function nextSaturday(hours = 0, minutes = 0, dateOnly = false) {
	const today = new Date();
	const daysToSat = 6 - today.getDay();
	const nextSat = new Date(today);
	nextSat.setHours(hours, minutes, 0, 0);
	nextSat.setDate(nextSat.getDate() + daysToSat);
	return toHTMLDateValue(nextSat, dateOnly);
}

/**
 * Formats a date for use in an HTML datetime-local input
 *
 * @param {Date} date Date object to format
 *
 * @returns {string} Formatted string
 */
export function toHTMLDateValue(date, dateOnly = false) {
	const pad = (num) => num.toString().padStart(2, "0");
	const year = date.getFullYear();
	const month = pad(date.getMonth() + 1);
	const day = pad(date.getDate());
	const hours = pad(date.getHours());
	const minutes = pad(date.getMinutes());
	if (dateOnly) return `${year}-${month}-${day}`;
	else return `${year}-${month}-${day}T${hours}:${minutes}`;
}

/**
 * Checks if two date ranges defined by [start1, end1] and [start2, end2] overlap
 *
 * @export
 * @param {Date} start1
 * @param {Date} end1
 * @param {Date} start2
 * @param {Date} end2
 */
export function dateRangesOverlap(start1, end1, start2, end2) {
	return start2 < end1 && end2 > start1;
}

/**
 * Gets the date with same date but time 00:00:00
 *
 * @export
 * @param {Date} date
 */
export function dayStart(date) {
	const res = new Date(date);
	res.setHours(0, 0, 0, 0);
	return res;
}

/**
 * Gets the date with time 00:00:00 of the day after
 *
 * @export
 * @param {Date} date
 */
export function dayEnd(date) {
	const res = new Date(date);
	res.setDate(res.getDate() + 1);
	res.setHours(0, 0, 0, 0);
	return res;
}

/**
 * Adds days to a date
 *
 * @export
 * @param {Date} date
 * @param {number} amount
 */
export function addDays(date, amount = 1) {
	const res = new Date(date);
	res.setDate(res.getDate() + amount);
	return res;
}

/**
 * Adds hours to a date
 *
 * @export
 * @param {Date} date
 * @param {number} amount
 */
export function addHours(date, amount = 1) {
	const res = new Date(date);
	res.setHours(res.getHours() + amount);
	return res;
}

/**
 * Adds minutes to a date
 *
 * @export
 * @param {Date} date
 * @param {number} amount
 */
export function addMinutes(date, amount = 1) {
	const res = new Date(date);
	res.setMinutes(res.getMinutes() + amount);
	return res;
}

/**
 * Returns an array of days with time 00:00:00 that overlap with [start, end]
 *
 * @export
 * @param {Date} start
 * @param {Date} end
 */
export function daysWithin(start, end) {
	const cur = new Date(start);
	const days = [new Date(start)];
	while (dayStart(cur) < dayStart(end)) {
		days.push(cur);
		cur.setDate(cur.getDate() + 1);
	}
	return days;
}

/**
 * @param {Date} date1
 * @param {Date} date2
 */
export function minDate(date1, date2) {
	if (date1.getTime() <= date2.getTime()) return date1;
	return new Date(date2);
}

/**
 * @param {Date} date1
 * @param {Date} date2
 */
export function maxDate(date1, date2) {
	if (date1.getTime() >= date2) return date1;
	return new Date(date2);
}
