<template>
	<div ref="container" class="container" @click="toggleIcon($event)">
		<div class="bar1"></div>
		<div class="bar2"></div>
		<div class="bar3"></div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			state: false,
		};
	},

	methods: {
		toggleIconOverride() {
			this.$refs.container.click();
		},
		toggleIcon(event) {
			var el = event.target;
			if (!el.classList.contains("container")) {
				el = el.parentNode;
			}
			el.classList.toggle("change");
			var state = el.classList.contains("change");
			this.state = state;
			this.$emit("toggled", state);
		},
	},
};
</script>

<style>
.container {
	display: inline-block;
	cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
	width: 35px;
	height: 5px;
	background-color: #fff;
	margin: 6px 0;
	transition: 0.4s;
}

.change .bar1 {
	-webkit-transform: rotate(-45deg) translate(-9px, 6px);
	transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
	opacity: 0;
}

.change .bar3 {
	-webkit-transform: rotate(45deg) translate(-8px, -8px);
	transform: rotate(45deg) translate(-8px, -8px);
}
</style>
