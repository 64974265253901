<template>
	<div>
		<directus-page page-id="hoernlihaus"></directus-page>
	</div>
</template>

<script>
import DirectusPage from "@/components/DirectusPage";
export default {
	components: {
		DirectusPage,
	},
};
</script>

<style lang="scss" scoped></style>
