<template>
	<div>
		<h1>{{ title }}</h1>
		<p>
			Es sieht aus, als seist du nicht eingeloggt oder du bist kein Admin von dieser Stufe. Logge
			dich ein und versuche es erneut. Wenn du in dieser Stufe bist aber noch kein Admin bist,
			kontaktiere den Administrator der Seite (Siehe Fusszeile)
		</p>
		<router-link to="/home" class="styled">Homepage</router-link>
	</div>
</template>

<script>
export default {
	props: {
		title: { type: String, default: "Stufen-Funktion" },
	},
};
</script>
