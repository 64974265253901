<template>
	<div>
		<h1>Pizzamobil reservieren</h1>
		<auto-form
			ref="pizzamobilReservationForm"
			v-model="model"
			class="wide columns bold"
			:submit-disabled="sending"
			@submit="submitForm"
		></auto-form>
	</div>
</template>

<script>
import axios from "axios";
import url from "url";
import AutoForm from "@/components/AutoForm";

import formModel from "@/resources/forms/pizzamobil-reservation-form-model";

export default {
	components: {
		AutoForm,
	},
	data() {
		return {
			model: formModel,
			sending: false,
		};
	},
	methods: {
		async submitForm(data) {
			console.log("Submitting form...");
			this.sending = true;
			this.$store.commit("setAppLoadingText", "Reservation wird versendet...");
			this.$store.commit("setAppLoadingStatus", true);
			let endpoint = url.resolve(this.$store.getters.apiUrl, "sendmail/pizzamobil-order");
			try {
				let response = await axios.post(endpoint, data);
				console.log("Form submitted!");
				let successMessage = response.data;
				this.$store.dispatch("alert", {
					type: "success",
					message: successMessage,
				});
			} catch (error) {
				let errorMessage = error.response.data;
				console.error(error.response);
				this.$store.dispatch("alert", { type: "error", message: errorMessage });
			} finally {
				this.$refs.pizzamobilReservationForm.clearForm();
				this.sending = false;
				this.$store.commit("setAppLoadingStatus", false);
				this.$store.commit("setAppLoadingText", null);
				this.$router.push("/material/pizzamobil");
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
