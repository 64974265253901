<template>
	<div>
		<h1>ZoBack</h1>
		<p>
			Alle zwei Jahre - alternierend zum
			<router-link to="/anlaesse/cevi-flohmi" class="styled">Cevi-Flohmi</router-link> - führen wir
			unsere sogenannte Zopfback-Aktion (ZoBack) durch. Dabei klappern die Cevianerinnen und
			Cevianer die drei Gemeinden Wiesendangen, Elsau und Hegi ab und nehmen Bestellungen für
			Butterzöpfe auf. Diese werden einige Wochen später von unseren Mitgliedern gebacken und
			traditionellerweise am Sonntagmorgen von uns persönlich an die Haustüre geliefert.
		</p>

		<div>
			<h2>ZoBack 2023</h2>
			<p>
				Dieses Jahr wird es etwas anders, wir verschieben nämlich die ZoBack aufs Frühjahr. Wir
				kommen <b>{{ orderDates }}</b> bei Ihnen zuhause vorbei und nehmen Ihre Bestellung auf. Sie
				können neuerdings aber auch online Ihren Zopf bestellen. Die Online-Bestellung kann
				<b>{{ onlineOrderDates }}</b> getätigt werden. Die Zöpfe werden anschliessend
				{{ deliveryDate }} bei Ihnen direkt an die Haustüre geliefert.
			</p>
			<router-link v-if="ordersEnabled" to="/anlaesse/zoback/bestellen">
				<button class="styled">Zum Online-Bestellformular</button>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		ordersEnabled() {
			// Only enable the formulary if we're past the start date
			return (
				Date.now() >= new Date(this.$store.getters.features.zoback.online_order_dates[0]) &&
				this.$store.getters.features.zoback.online_order_available
			);
		},
		orderDates() {
			return (
				"am " +
				this.$store.getters.features.zoback.order_dates
					.map((x) => new Date(x).toLocaleDateString("de-CH"))
					.join(" und am ")
			);
		},
		onlineOrderDates() {
			return (
				"vom " +
				this.$store.getters.features.zoback.online_order_dates
					.map((x) => new Date(x).toLocaleDateString("de-CH"))
					.join(" bis am ")
			);
		},
		deliveryDate() {
			const dateString = new Date(
				this.$store.getters.features.zoback.delivery_date
			).toLocaleDateString("de-CH");
			return `am ${dateString}`;
		},
	},
};
</script>
