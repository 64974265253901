<template>
	<transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
		<div v-if="shown" id="popup-background" key="background">
			<transition
				enter-active-class="animated zoomIn"
				leave-active-class="animated bounceOut"
				appear
			>
				<div v-if="shown" id="popup-container" key="container">
					<div id="topbar">
						<span id="title">
							<h3>{{ title }}</h3>
						</span>
						<button id="close-button" class="styled" @click="close()">
							<i class="fas fa-times close-button" />
						</button>
					</div>
					<div id="content">
						<slot />
					</div>
					<div v-if="!nobuttons" id="buttons-container">
						<button class="styled padded" @click="onPopupCancel()">
							{{ cancelText }}
						</button>
						<button
							class="styled padded"
							:disabled="!buttonConfirmEnabled"
							@click="onPopupConfirm()"
						>
							{{ confirmText }}
						</button>
					</div>
				</div>
			</transition>
		</div>
	</transition>
</template>

<script>
export default {
	name: "Popup",
	props: {
		title: { type: String, default: null },
		nobuttons: { type: Boolean, default: false },
		confirmText: { type: String, default: "OK" },
		cancelText: { type: String, default: "Abbrechen" },
		buttonConfirmEnabled: { type: Boolean, default: true },
	},

	data() {
		return {
			shown: false,
			onOpen: null,
			onConfirm: null,
			onCancel: null,
			onClose: null,
		};
	},

	methods: {
		onPopupOpen() {
			this.$emit("open");
			if (this.onOpen) this.onOpen();
			this.shown = true;
		},
		onPopupClose() {
			this.$emit("close");
			if (this.onClose) this.onClose();
			this.shown = false;
		},
		onPopupConfirm() {
			this.$emit("confirm");
			this.$emit("close");
			if (this.onConfirm) this.onConfirm();
			this.shown = false;
		},
		onPopupCancel() {
			this.$emit("cancel");
			this.$emit("close");
			if (this.onCancel) this.onCancel();
			this.shown = false;
		},
		//Public methods
		open() {
			this.onPopupOpen();
		},
		close() {
			this.onPopupClose();
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/vars";

#popup-background {
	animation-duration: 300ms;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba($dark, 0.6);
	display: flex;
	align-items: flex-start;
	justify-content: center;
	padding-top: 80px;

	#popup-container {
		background-color: $white;
		padding: $m-el-padding;
		border-radius: $border-radius-large;
		flex-basis: calc(100% - 4 * #{$m-el-padding});
		min-height: 10vh;
		animation-duration: $fade-time;
		max-width: 100%;
		box-sizing: border-box;

		#topbar {
			#title {
				display: inline-block;
				margin-right: $d-el-padding;
				min-height: 2em;
			}

			#close-button {
				float: right;
			}

			.close-button {
				display: block;
				height: 16px;
				width: 16px;
				margin: -3px;
			}
		}

		#content {
			margin: $m-el-padding 0;
		}

		#buttons-container {
			text-align: right;
		}
	}
}

@media (min-width: $m-width) {
	#popup-background {
		align-items: center;

		#popup-container {
			flex-basis: auto;
			padding: $d-el-padding 2 * $d-el-padding;
			min-height: auto;
			max-width: 60vw;
			min-width: 300px;

			#content {
				display: block;
				margin: $d-el-padding 0;
			}
		}
	}
}
</style>
