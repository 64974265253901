<template>
	<div id="kontakt">
		<h1>Kontakt</h1>
		<p>
			Wir bemühen uns, stets im Kontakt mit Eltern, Anwohnern und Behörden zu stehen. Wir freuen uns
			auf Ihre Meinungen, Kritiken, Anregungen und Fragen.
		</p>
		<h2>Kontaktdaten der Abteilung:</h2>
		<p>Telefon: <a class="styled" href="tel:+41774234568">077 423 45 68</a></p>
		<p>E-Mail: <a class="styled" href="mailto:info@ceviwie.ch">info@ceviwie.ch</a></p>
		<h2>Adresse:</h2>
		<p>Cevi Wiesendangen-Elsau-Hegi</p>
		<p>Trottenstrasse 5</p>
		<p>8542 Wiesendangen</p>
		<h2>Organigramm</h2>
		<organigramm />
		<h3>Stufen</h3>
		<groups />
		<h3>Legende</h3>
		<diagram-legend />
	</div>
</template>
<script>
import Organigramm from "@/pages/Kontakt/Organigramm";
import DiagramLegend from "@/pages/Kontakt/Legend";
import Groups from "@/pages/Kontakt/Groups";

export default {
	title: ({ shortAppend }) => shortAppend("Kontakt"),
	components: {
		Organigramm,
		DiagramLegend,
		Groups,
	},
};
</script>

<style lang="scss" scoped></style>
