<template>
	<div class="schedule-base">
		<div class="topbar">
			<span class="date-controls">
				<span>Programmplan für:</span>
				<span class="date-control" @click="date = prevDate()"
					><i class="fa-solid fa-angle-left"></i
				></span>
				<input id="date" v-model="date" type="date" name="date" />
				<span class="date-control" @click="date = nextDate()"
					><i class="fa-solid fa-angle-right"></i
				></span>
			</span>
			<label for="active-days-only">
				<input
					id="active-days-only"
					v-model="activeDaysOnly"
					type="checkbox"
					name="active-days-only"
				/>
				Nur aktive Tage
			</label>
			<label for="sort" style="display: flex; align-items: center; gap: 5px">
				<span>Sortierung</span>
				<select id="sort" v-model="sort" name="sort">
					<option value="group-id">Stufe (ID)</option>
					<option value="group-name">Stufe (Name)</option>
					<option value="start-time">Startzeit</option>
					<option value="end-time">Endzeit</option>
					<option value="duration">Dauer</option>
				</select>
			</label>
		</div>
		<div v-if="filteredRequests.length" class="schedule-main">
			<div class="schedule">
				<div class="head" :style="gridStyle">
					<span class="header-item"><i class="fa-solid fa-clock"></i></span>
					<!-- Stufen -->
					<span
						v-for="(group, idx) of groups"
						:key="group.id"
						class="head-item"
						:style="place(1, idx + 2)"
						>{{ group.name }}</span
					>
				</div>
				<div class="body" :style="gridStyle">
					<!-- Time steps / lines -->
					<template v-for="(step, row) of timeSteps">
						<!-- Time indicator -->
						<span
							:key="key(row, 0)"
							class="timestep-item body-item"
							:style="{ ...place(row + 1, 1), ...(isHour(step) ? { borderTopWidth: '3px' } : {}) }"
						>
							<span class="timestep" :class="{ hour: isHour(step) }">{{ formatTime(step) }}</span>
						</span>
						<span
							:key="key(row, 1)"
							class="body-item timestep-line"
							:style="{ ...place(row + 1, 2), ...(isHour(step) ? { borderTopWidth: '3px' } : {}) }"
						></span>
					</template>
					<helper-schedule-card
						v-for="req in filteredRequests"
						:key="req.id"
						class="event"
						:style="placeEvent(req)"
						:request="req"
						:selection-key="req.id"
						:overflow="req.overflow"
						:underflow="req.underflow"
						:layout="layout"
						@select="$emit('select', $event)"
						@deselect="$emit('deselect', $event)"
						@created="$emit('created', $event)"
					></helper-schedule-card>
					<!-- Time steps background -->
					<div
						class="timesteps-background"
						:style="{ gridColumn: '1', gridRow: `1 / ${timeSteps.length}` }"
					></div>
				</div>
			</div>
		</div>
		<div v-else class="schedule-main no-events">
			<span v-if="new Date(date).getTime()"
				>Am {{ new Date(date).toLocaleDateString("de-CH") }} sind keine Helfereinsätze
				geplant.</span
			>
			<span v-else>Ungültiges Datum. Überprüfe deine Eingabe und versuche es erneut.</span>
		</div>
	</div>
</template>

<script>
import {
	nextSaturday,
	toHTMLDateValue,
	dateRangesOverlap,
	dayStart,
	dayEnd,
	addDays,
	daysWithin,
	minDate,
	maxDate,
} from "@/functions";
import HelperScheduleCard from "./HelperScheduleCard.vue";

const timeIncrement = 15;

const compare = (get, lt = null) => {
	return (a, b) => {
		const va = get(a),
			vb = get(b);
		lt ||= (a, b) => a < b;
		if (lt(va, vb)) return -1;
		if (lt(vb, va)) return 1;
		return 0;
	};
};
const key = (key) => (x) => x[key];
const sortFunctions = {
	"group-id": { cmp: compare(key("group_id")), mode: "min" },
	"group-name": { cmp: compare(key("group_name")), mode: "min" },
	"start-time": { cmp: compare((x) => new Date(x.datetime_from)), mode: "min" },
	"end-time": { cmp: compare((x) => new Date(x.datetime_to)), mode: "max" },
	duration: {
		cmp: compare(
			(x) => new Date(x.datetime_to) - new Date(x.datetime_from),
			(a, b) => a > b
		),
		mode: "min",
	},
};

export default {
	name: "HelperSchedule",
	components: { HelperScheduleCard },
	props: {
		requests: {
			type: Array,
			default: () => [],
		},

		layout: {
			type: String,
			default: "desktop",
		},
	},
	data() {
		return {
			date: nextSaturday(0, 0, true),
			activeDaysOnly: true,
			sort: "start-time",
		};
	},
	computed: {
		filteredRequests() {
			const filtered = this.requests.filter((x) => {
				const startDate = new Date(x.datetime_from);
				const endDate = new Date(x.datetime_to);
				return dateRangesOverlap(startDate, endDate, this.startOfDay, this.endOfDay);
			});
			const sort = sortFunctions[this.sort];
			filtered.sort(sort.cmp);
			return filtered;
		},
		groups() {
			// filteredRequests is already sorted, order must be maintained
			const groups = [];

			const makeGroup = (req) => ({
				id: req.group_id,
				name: req.group_name || `Stufe ${req.group_id}`,
				req,
			});

			for (const req of this.filteredRequests) {
				const prevGroupIdx = groups.findIndex((x) => x.id == req.group_id);
				if (prevGroupIdx >= 0) {
					const prevGroup = groups[prevGroupIdx];
					// Replace name if necessary
					if ((!prevGroup.name || prevGroup.name.startsWith("Stufe")) && req.group_name) {
						prevGroup.name = req.group_name;
					}
					// Replace entirely if necessary
					const compare = sortFunctions[this.sort];
					const cmpResult = compare.cmp(prevGroup.req, req);
					if (compare.mode == "max" && cmpResult < 0) {
						groups.splice(prevGroupIdx, 1);
						groups.push(makeGroup(req));
					}
				} else {
					// First of group, push
					groups.push(makeGroup(req));
				}
			}

			return groups;
		},
		filteredGroupNames() {
			return this.filteredRequests.map((x) => x.group_name);
		},
		groupNames() {
			return this.groups.map((x) => x.name);
		},
		minDateTime() {
			if (this.filteredRequests.length == 0) return new Date();
			return this.filteredRequests.map((x) => this.scheduleStartTime(x)).reduce(minDate);
		},
		maxDateTime() {
			if (this.filteredRequests.length == 0) return new Date();
			return this.filteredRequests.map((x) => this.scheduleEndTime(x)).reduce(maxDate);
		},
		startOfDay() {
			return dayStart(new Date(this.date));
		},
		endOfDay() {
			return dayEnd(new Date(this.date));
		},
		timeSteps() {
			const steps = [];
			const cur = maxDate(this.minDateTime, this.startOfDay);
			cur.setMinutes(cur.getMinutes() - timeIncrement);
			const end = minDate(this.maxDateTime, this.endOfDay);
			end.setMinutes(end.getMinutes() + timeIncrement);
			while (cur <= end) {
				steps.push(new Date(cur));
				cur.setMinutes(cur.getMinutes() + timeIncrement);
			}
			return steps;
		},
		gridStyle() {
			const minWidth = this.layout == "desktop" ? "12em" : this.layout == "mobile" ? "8em" : "auto";
			return {
				gridTemplateColumns: `4em repeat(${this.groups.length}, minmax(${minWidth}, 1fr))`,
			};
		},
	},
	methods: {
		/**
		 * @param {Date} date
		 **/
		formatTime(date) {
			return date.toLocaleTimeString("de-CH", { hour: "2-digit", minute: "2-digit" });
		},
		scheduleStartTime(request) {
			const res = new Date(request.datetime_from);
			res.setMinutes(res.getMinutes() - (res.getMinutes() % timeIncrement));
			return res;
		},
		scheduleEndTime(request) {
			const res = new Date(request.datetime_to);
			if (res.getMinutes() % timeIncrement == 0) return res;
			else res.setMinutes(res.getMinutes() + timeIncrement - (res.getMinutes() % timeIncrement));
			return res;
		},
		isHour(date) {
			return date.getMinutes() % 60 == 0;
		},
		key(row, col) {
			return `${row}:${col}`;
		},
		place(row, col) {
			return { gridRow: row.toString(), gridColumn: col.toString() };
		},
		placeEvent(event) {
			// Logging
			// console.table({
			// 	Date: [this.date],
			// 	"Start of Day": [this.startOfDay.toISOString()],
			// 	"End of Day": [this.endOfDay.toISOString()],
			// 	Event: [event],
			// 	"Schedule Start Time": [this.scheduleStartTime(event).toISOString()],
			// 	"Schedule End Time": [this.scheduleEndTime(event).toISOString()],
			// });
			// Column: Find correct index
			const colStart = this.groups.findIndex((x) => x.id == event.group_id) + 2;
			if (colStart < 2)
				throw `Cannot place ${event.group_name || event.group_id}: Can't find column`;
			const colSpan = 1;

			// Row / span: Find start and determine span based on length
			const scheduleStart = this.scheduleStartTime(event);
			const scheduleEnd = this.scheduleEndTime(event);
			const start = maxDate(this.startOfDay, scheduleStart);
			const end = minDate(this.endOfDay, scheduleEnd);
			const rowStart =
				this.timeSteps.findIndex((x) => Math.abs(x.getTime() - start.getTime()) <= 1000) + 1;
			if (rowStart < 1)
				throw `Cannot place ${
					event.group_name || event.group_id
				}: Can't find ${start.toISOString()} in timesteps`;
			const rowSpan = (end - start) / (1000 * 60 * timeIncrement);

			// Control overflow/underflow tags
			delete event.underflow;
			delete event.overflow;
			if (scheduleStart < this.startOfDay) event.underflow = true;
			if (scheduleEnd > this.endOfDay) event.overflow = true;

			return this.place(`${rowStart} / span ${rowSpan}`, `${colStart} / span ${colSpan}`);
		},
		nextDate() {
			if (this.activeDaysOnly) {
				const today = new Date(this.date);
				const tomorrow = dayStart(addDays(today, 1));
				let min = null;

				for (const req of this.requests) {
					const start = new Date(req.datetime_from);
					const end = new Date(req.datetime_to);

					for (const day of daysWithin(start, end)) {
						if (day >= tomorrow && (!min || day < min)) {
							console.log("Match!");
							min = day;
						}
					}
				}
				return toHTMLDateValue(min || today, true);
			} else {
				const next = new Date(this.date);
				next.setDate(next.getDate() + 1);
				return toHTMLDateValue(next, true);
			}
		},
		prevDate() {
			if (this.activeDaysOnly) {
				const today = new Date(this.date);
				const yesterday = dayStart(today);
				let max = null;
				for (const req of this.requests) {
					const start = new Date(req.datetime_from);
					const end = new Date(req.datetime_to);
					for (const day of daysWithin(start, end)) {
						if (day < yesterday && (!max || day > max)) {
							max = day;
						}
					}
				}
				return toHTMLDateValue(max || today, true);
			} else {
				const prev = new Date(this.date);
				prev.setDate(prev.getDate() - 1);
				return toHTMLDateValue(prev, true);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";

.schedule-base {
	border: 1px solid #0002;
	border-radius: 10px;
	overflow: auto;

	.topbar {
		padding: 10px;
		display: flex;
		gap: 15px;
		justify-content: space-between;
		align-items: center;

		span,
		label {
			color: rgba($blue, 0.6);
		}

		input[type="date"] {
			font-size: 13pt;
			border: 2px solid $blue;
			box-shadow: 0 0 10px #0001;
		}
	}

	::-webkit-scrollbar {
		width: 5px;
	}
	::-webkit-scrollbar-track {
		background-color: rgba($blue, 0.1);
	}
	::-webkit-scrollbar-thumb {
		background-color: rgba($blue, 0.4);
	}

	* {
		scrollbar-width: thin;
		scrollbar-color: rgba($blue, 0.4) rgba($blue, 0.1);
	}

	.schedule-main {
		padding: 10px;
		padding-bottom: 0;
		max-height: 35em;
		overflow: auto;

		.schedule {
			.head,
			.body {
				display: grid;
				padding: 10px;
				box-sizing: border-box;
				min-width: min-content;
			}

			.head {
				border: 2px solid $blue;
				box-shadow: 0 0 10px #0002;
				border-radius: 8px;
				font-weight: bold;
				// position: sticky;
				// top: 0;
				// z-index: 1;
				background-color: white;

				.head-item {
					margin: 0 25px;
				}
			}

			.body {
				padding-bottom: 0;
				margin-top: 20px;
				grid-auto-flow: column;
				grid-auto-rows: 2.5em;

				.body-item {
					height: 2em;
					border-top: 1px solid #01003c33;
				}

				.timestep-line {
					grid-column: 2 / -1 !important;
				}

				.timesteps-background {
					position: sticky;
					left: -3em;
					background-color: white;
					border-radius: 10px;
					z-index: 1;
					margin: -1em 0.5em -1em -3em;
					box-shadow: 0 0 10px #0003;
				}

				.timestep-item {
					position: sticky;
					left: 0;
					z-index: 2;
					border: none;
				}

				.timestep-item .timestep {
					position: absolute;
					top: -30%;
					color: rgba($blue, 0.5);
					font-size: small;
					font-family: "Roboto Mono", "Courier Prime", monospace;

					&.hour {
						font-size: medium;
						color: $blue;
					}
				}
			}
		}
	}

	.no-events {
		width: 100%;
		min-height: 35em;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $blue;
	}

	.date-controls {
		margin-left: 10px;
		display: inline-flex;
		gap: 10px;
		align-items: center;

		.date-control {
			display: inline-block;
			text-align: center;
			vertical-align: middle;
			width: 1em;
			height: 1em;
			padding: 5px;
			padding-top: 3px;
			border-radius: 100em;

			&:hover {
				cursor: pointer;
				background-color: #0003;
			}
		}
	}
}

input[type="date"],
select,
option {
	border: 1px solid #0002;
	padding: 5px 7px;
	border-radius: 5px;
	font-family: $app-sans-font;
	text-align: center;
	background-color: white;
}

@media screen and (max-width: $m-width) {
	.topbar {
		flex-direction: column;

		.date-controls {
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;

			& > *:first-child {
				flex-basis: 100%;
				text-align: center;
			}
		}
	}

	.schedule .body {
	}
}
</style>
