<template>
	<div>
		<input
			v-model="m_content"
			class="styled"
			:name="name"
			:type="type"
			:placeholder="placeholder"
			:disabled="disabled"
			:autofocus="autofocus"
			:class="nomargin ? 'nopadding' : ''"
			@input="handleInput"
			@keypress.enter="onEnterPressed"
		/>
		<p v-if="m_hint && !m_error" class="hint">{{ m_hint }}</p>
		<p v-if="m_error" class="error">{{ m_error }}</p>
	</div>
</template>

<script>
export default {
	props: {
		value: { type: String, default: "" },
		type: { type: String, default: "text" },
		placeholder: { type: String, default: "" },
		disabled: { type: Boolean, default: false },
		name: { type: String, default: "" },
		hint: { type: String, default: null },
		autofocus: { type: Boolean, default: false },
		validation: {
			type: Array,
			default: () => [{ func: (x) => x, error: "Kann nicht leer sein" }],
		},
		nomargin: { type: Boolean, default: false },
	},
	data() {
		return {
			m_content: this.value,
			m_hint: this.hint,
			m_error: "",
			validates: false,
		};
	},
	watch: {
		value: function () {
			this.m_content = this.value;
		},
	},
	methods: {
		handleInput(_event) {
			this.validate();
			this.$emit("input", this.m_content);
		},
		validate() {
			var errorValidation = this.validation.find((val) => !val.func(this.m_content));
			this.m_error = errorValidation ? errorValidation.error : "";
			this.validates = !errorValidation;
			this.$emit("validate", errorValidation == null, this.name);
		},
		onEnterPressed() {
			this.$emit("enter", this.value);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

input.styled {
	display: block;
	margin: $d-el-padding 0;
	width: calc(100% - 20px);
	background-color: #fff;
	font-size: 1em;

	&[disabled] {
		opacity: 0.7;
	}

	&::placeholder {
		font-style: normal;
	}
}

.hint,
.error {
	text-align: left;
	padding: 0 10px;
	font-size: 0.8em;
	line-height: 1em;
	margin-top: -10px;
	text-shadow: 0 0 5px #0002;
}
.hint {
	color: #aaa;
}
.error {
	color: $red-vibrant;
}
.nopadding {
	margin: 0 !important;
}
</style>
