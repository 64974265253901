<template>
	<div id="toast-container" :style="containerCssStyle">
		<div v-if="toasts.length > 0" class="clear-button-container">
			<button @click="clearAll()">
				<i class="fas fa-stream fa-lg"></i>
			</button>
		</div>
		<transition-group
			name="toast-list"
			enter-active-class="animate__animated animate__fadeInUp"
			leave-active-class="animate__animated animate__fadeOutRight toast-list-leave-active"
			move-class="toast-list-move"
		>
			<div
				v-for="toast in toasts"
				:key="toast.id"
				class="toast"
				:class="[toastCssClass, toast.type]"
			>
				<div class="icon-container">
					<i v-if="toast.type == 'success'" class="fas fa-check-circle fa-lg"></i>
					<i v-if="toast.type == 'info'" class="fas fa-info-circle fa-lg"></i>
					<i v-if="toast.type == 'warn'" class="fas fa-exclamation-circle fa-lg"></i>
					<i v-if="toast.type == 'error'" class="fas fa-times-circle fa-lg"></i>
				</div>
				<div class="text-container">
					<h3>{{ toast.title }}</h3>
					<p v-html="toast.message"></p>
				</div>
				<div class="close-button-container">
					<button @click="removeToast(toast.id)">
						<i class="fas fa-times fa-lg"></i>
					</button>
				</div>
			</div>
		</transition-group>
	</div>
</template>

<script>
import { bus } from "@/bus";
export default {
	data() {
		return {
			toastCssClass: null,
			containerCssStyle: null,
			toasts: [],
		};
	},
	mounted() {
		this.setTopOffset();
		bus.$on("alert", this.handleIncomingAlert);
	},
	methods: {
		setTopOffset() {
			let navbar = document.getElementById("navbar");
			if (navbar) {
				this.containerCssStyle = "top: " + navbar.clientHeight + "px";
			}
		},
		handleIncomingAlert(alert) {
			let id = this.addToast(alert);
			if (alert.duration && alert.duration == -1) {
				return;
			}
			if (!alert.duration) {
				alert.duration = 5;
			}
			setTimeout(() => this.removeToast(id), alert.duration * 1000);
		},
		addToast(toast) {
			let time = Date.now();
			let len = this.toasts.length;
			let id = `toast:${len}${time}`;
			toast.id = id;
			this.toasts.push(toast);
			if (toast.type == "error") console.log(toast.message);
			return id;
		},
		removeToast(id) {
			this.toasts = this.toasts.filter((x) => x.id != id);
		},
		clearAll() {
			this.toasts = [];
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars.scss";

$dark-mode-text-color: #fffd;
$transition-duration: 250ms;

.toast-list-leave-active {
	position: relative;
}

#toast-container {
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	bottom: 0;
	right: 0;
	width: 600px;
	z-index: 1000;
	padding: 5px;
	pointer-events: none;

	.clear-button-container {
		text-align: right;
		padding: 10px;
		padding-bottom: 0px;
		pointer-events: all;

		button {
			border: none;
			color: $dark;
			width: 32px;
			height: 32px;
			border-radius: $border-radius-small;
			box-shadow: 1px 2px 15px #0004;

			&:focus {
				outline: none;
			}

			&:hover {
				background-color: lighten($dark, 15%);
				color: white;
				cursor: pointer;
			}
		}
	}

	.toast {
		position: relative;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #fff;
		margin: 10px;
		padding: 10px;
		box-shadow: 5px 2px 20px #0007;
		border-radius: $border-radius-small;
		animation-duration: $transition-duration;
		transition: all $transition-duration;
		pointer-events: all;

		&::before {
			content: "";
			background-color: magenta;
			position: absolute;
			width: 7px;
			top: 0;
			bottom: 0;
			left: 0;
		}

		&.info,
		&.success,
		&.error {
			color: $dark-mode-text-color;

			i {
				color: $dark-mode-text-color;
			}
		}

		&.info {
			background: lighten($dark, 15%);
			&::before {
				background-color: $dark;
			}
		}

		&.success {
			background: darken($mint, 10%);
			&::before {
				background-color: darken($mint, 25%);
			}
		}

		&.warn {
			background: $yellow;
			&::before {
				background-color: desaturate(darken($yellow, 30%), 5%);
			}
		}

		&.error {
			background: $red;
			&::before {
				background-color: darken($red, 25%);
			}
		}

		&:hover {
			.close-button-container {
				opacity: 1;
			}
		}

		.icon-container {
			margin: 5px 10px;
		}

		.text-container {
			flex-basis: 100%;
			margin: 0 10px;

			h3,
			p {
				margin: 3px;
				font-family: $app-sans-font;
			}
		}

		.close-button-container {
			opacity: 0;
			button {
				background: transparent;
				border: none;
				height: 32px;
				width: 32px;
				border-radius: $border-radius-small;

				&:hover {
					background: #fff3;
					cursor: pointer;
				}

				&:focus {
					outline: none;
				}
			}
		}
	}
}

@media screen and (max-width: $m-width) {
	#toast-container {
		left: 0;
		right: 0;
		width: auto;

		.toast .close-button-container {
			opacity: 1;
		}
	}
}
</style>
