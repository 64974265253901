<template>
	<div id="backdrop">
		<div>
			<loading-spinner
				:info-text="this.$store.state.appLoadingText"
				theme="inverted-transparent"
			></loading-spinner>
		</div>
	</div>
</template>

<script>
import LoadingSpinner from "../components/LoadingSpinner";

export default {
	components: {
		LoadingSpinner,
	},
};
</script>

<style lang="scss" scoped>
#backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: #0008;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}
</style>
