export default {
	title: "Helfer anfragen",
	description:
		"Hier kannst du Helfer für deine Einsätze anfragen. Du erhältst eine E-Mail, sobald die " +
		"Helferstufe euren Einsatz bestätigt.",
	items: {
		hdr1: {
			type: "header",
			class: "wide colspan-12",
			content: "Persönliche Angaben",
		},
		name: {
			type: "text",
			description: "Name",
			required: true,
		},
		ceviName: {
			type: "text",
			description: "Ceviname",
			required: true,
		},
		group: {
			type: "dropdown",
			description: "Stufe",
			options: [],
			required: true,
		},
		space: {
			type: "space",
		},
		// tel: {
		// 	type: "text",
		// 	description: "Handynummer",
		// },
		// email: {
		// 	type: "email",
		// 	description: "Email",
		// 	required: true,
		// },
		hdr2: {
			type: "header",
			class: "wide colspan-12",
			content: "Angaben zum Helfereinsatz",
		},
		dateTimeFrom: {
			type: "datetime-local",
			description: "Datum/Zeit vom",
			required: true,
		},
		dateTimeTo: {
			type: "datetime-local",
			description: "Datum/Zeit bis",
			required: true,
		},
		operationLocation: {
			type: "text",
			description: "Einsatzort",
			required: true,
			class: "wide colspan-12",
		},
		briefingLocation: {
			type: "text",
			description: "Besprechungsort",
			required: true,
		},
		briefingTime: {
			type: "datetime-local",
			description: "Besprechungszeit",
			required: true,
		},
		helpersMin: {
			type: "number",
			description: "Minimale Helferzahl",
			required: true,
			min: 0,
			max: 30,
		},
		helpersDesired: {
			type: "number",
			description: "Erwünschte Helferzahl",
			required: true,
			min: 0,
			max: 30,
		},
		theme: {
			type: "text",
			description: "Thema",
		},
		costume: {
			type: "text",
			description: "Verkleidung",
		},
		remarks: {
			type: "textarea",
			description: "Bemerkungen/Beschreibung",
			class: "wide colspan-12",
			rows: 6,
		},
		submit: {
			type: "submit",
			value: "Anfrage abschicken",
			class: "mobile-span",
		},
	},
};
