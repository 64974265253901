<template>
	<div>
		<directus-page page-id="laedeli"></directus-page>
	</div>
</template>
<script>
import DirectusPage from "@/components/DirectusPage";

export default {
	components: { DirectusPage },
};
</script>
