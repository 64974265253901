function append(title) {
	return `${process.env.VUE_APP_TITLE} - ${title}`;
}

function shortAppend(title) {
	return `${process.env.VUE_APP_SHORT_TITLE} - ${title}`;
}

function getTitle(vm) {
	const { title } = vm.$options;
	if (title) {
		return typeof title === "function"
			? title.call(vm, {
					title: process.env.VUE_APP_TITLE,
					append: append,
					shortAppend: shortAppend,
			  })
			: title;
	}
}
export default {
	data() {
		return {
			shortAppTitle: process.env.VUE_APP_SHORT_TITLE,
		};
	},
	created() {
		this.updateTitle();
	},
	methods: {
		updateTitle() {
			const title = getTitle(this);
			if (title) {
				document.title = title;
			}
		},
	},
};
