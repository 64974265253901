<template>
	<div id="form-base" :class="'mobile-borderless ' + layout">
		<form id="form" @submit.prevent="handleOnSubmit()">
			<h3>{{ model.title }}</h3>
			<p v-if="model.description">{{ model.description }}</p>
			<div id="form-content">
				<div
					v-for="(field, index) in model.items"
					:key="index"
					:class="'field-box ' + field.class"
					@input="checkFieldValid(field)"
					@focusout="checkFieldInvalid(field)"
				>
					<div v-if="field.invalid" class="error-popup">
						<span class="text">{{ field.errorMessage }}</span>
					</div>
					<span v-if="field.description" class="field-title">
						{{ field.description }}
						<span v-if="field.required" class="field-required">*</span>
					</span>
					<input
						v-if="isField(field)"
						v-model="field.value"
						:type="field.type"
						:placeholder="field.placeholder"
						:required="field.required"
						:autocomplete="field.autocomplete"
						:min="field.min"
						:max="field.max"
						class="styled white"
					/>
					<textarea
						v-if="isTextArea(field)"
						v-model="field.value"
						:placeholder="field.placeholder"
						class="styled white"
						:required="field.required"
						:cols="field.cols"
						:rows="field.rows"
					></textarea>
					<check-box
						v-if="isCheckBox(field)"
						v-model="field.value"
						color="white"
						style="margin-top: 10px"
						:required="field.required"
						>{{ field.placeholder }}</check-box
					>
					<toggle-switch
						v-if="isToggleSwitch(field)"
						v-model="field.value"
						:default-state="field.value"
						style="margin-top: 10px"
						:required="field.required"
						>{{ field.placeholder }}</toggle-switch
					>
					<div v-if="isTextEditor(field)" class="text-editor-container">
						<text-editor v-model="field.value" :required="field.required" />
					</div>
					<select
						v-if="isDropdown(field)"
						v-model="field.value"
						:name="field.value"
						:placeholder="field.placeholder"
						form="form"
						class="styled white fullwidth"
					>
						<option
							v-for="(option, optionIndex) of field.options"
							:key="optionIndex"
							:value="option.id"
						>
							{{ option.name }}
						</option>
					</select>
					<div v-if="isSpace(field)" class="space"></div>
					<input
						v-if="isSubmit(field)"
						type="submit"
						:value="field.value"
						:disabled="submitDisabled"
						class="styled"
						:class="field.class || 'align-left'"
					/>
					<div v-if="isHeader(field)">
						<h2>{{ field.content }}</h2>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import CheckBox from "@/components/CheckBox";
import TextEditor from "@/components/TextEditor";
import ToggleSwitch from "@/components/ToggleSwitch";

export default {
	components: {
		CheckBox,
		ToggleSwitch,
		TextEditor,
	},
	props: {
		value: { type: Object, default: null },
		layout: { type: String, default: null },
		validator: { type: Object, default: null },
		submitDisabled: { type: Boolean, default: false },
	},
	computed: {
		/**
		 * @returns {{title: string, description: string, items: any[]}}
		 */
		model() {
			return this.value;
		},
	},

	methods: {
		checkFieldValid(field) {
			if (field.validator && field.invalid && field.validator.validate(field.value)) {
				// Field was invalid and has been corrected, set to valid and remove error message
				field.invalid = false;
				field.errorMessage = "";
				this.$forceUpdate();
				return true;
			}
			return false;
		},
		checkFieldInvalid(field) {
			if (field.validator && !field.validator.validate(field.value)) {
				// Invalid input on blur, set to invalid (and display message)
				field.invalid = true;
				field.errorMessage = field.validator.errorMessage;
				this.$forceUpdate();
				return true;
			}
			return false;
		},
		handleOnSubmit() {
			let ignoredTypes = ["submit", "space", "header"];

			let data = {};
			for (let key in this.model.items) {
				let x = this.model.items[key];
				if (ignoredTypes.includes(x.type)) {
					continue;
				}
				if (x.value === undefined) x.value = null;
				data[key] = x.value;
			}

			// Validate with custom global validator
			if (this.validator != null) {
				if (!this.validator.func(data)) {
					this.$store.dispatch("alert", {
						type: "error",
						message: this.validator.errorMessage,
					});
					return;
				}
			}

			this.$emit("submit", data);
		},
		clearForm() {
			// Reset all values to null
			for (let item in this.model.items) {
				this.model.items[item].value = null;
			}
		},
		setFieldValues(values) {
			for (const key in values) {
				// eslint-disable-next-line vue/no-mutating-props
				this.value.items[key].value = values[key];
				this.$forceUpdate();
			}
		},
		isField(field) {
			let fieldTypes = ["text", "email", "password", "tel", "date", "datetime-local", "number"];
			return fieldTypes.includes(field.type);
		},
		isTextArea(field) {
			return field.type == "textarea";
		},
		isCheckBox(field) {
			return field.type == "checkbox";
		},
		isToggleSwitch(field) {
			return field.type == "toggleswitch";
		},
		isSubmit(field) {
			return field.type == "submit";
		},
		isTextEditor(field) {
			return field.type == "editor";
		},
		isSpace(field) {
			return field.type == "space";
		},
		isDropdown(field) {
			return field.type == "dropdown";
		},
		isHeader(field) {
			return field.type == "header";
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

.error-popup {
	background-color: $red;
	color: #fff;
	display: block;
	padding: 10px;
	border-radius: $border-radius-small;
	position: absolute;
	bottom: -45px;
	left: 0;
	margin: 0 auto;
	width: max-content;
	box-sizing: border-box;
	z-index: 1;
	box-shadow: 3px 5px 20px #0004;

	&::before {
		content: "";
		background-color: $red;
		position: absolute;
		left: 40px;
		top: -7.5px;
		width: 15px;
		height: 15px;
		margin: 0 auto;
		transform: rotate(45deg);
	}

	@media screen and (max-width: $m-width) {
		width: 100%;
	}
}

#form-base {
	width: 100%;

	#form {
		max-width: 600px;
		background-color: #eee;
		box-sizing: border-box;
		padding: $d-el-padding $d-el-padding * 2;
		border-radius: $border-radius-large;
		box-shadow: 3px 3px 20px #0001;

		.field-box {
			display: flex;
			flex-wrap: wrap;
			position: relative;
			.field-title {
				display: block;
				margin-top: 10px;

				.field-required {
					color: $red;
					font-weight: bold;
					margin-left: 5px;
				}
			}

			.space {
				height: 30px;
			}

			input {
				width: 100%;
				box-sizing: border-box;

				&[type="submit"] {
					width: max-content;
					height: max-content;
					margin-top: 20px;
					&.align-right {
						display: block;
						margin-left: auto;
						margin-right: 0;
					}
					&.span {
						width: 100%;
					}

					@media (max-width: $m-width) {
						&.mobile-span {
							width: 100%;
						}
					}
				}
			}

			.text-editor-container {
				background-color: #fff;
				margin-top: 5px;
			}

			&.wide {
				grid-column: 1 / -1;
			}
		}
	}

	&.bold .field-box .field-title {
		font-weight: bold;
	}

	&.wide #form {
		max-width: 100%;
	}

	@media (min-width: $m-width) {
		&.columns #form #form-content {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			grid-column-gap: 20px;

			.field-box {
				grid-column: span 6;

				&.colspan-12 {
					grid-column: span 12;
				}

				&.colspan-5 {
					grid-column: span 5;
				}

				&.colspan-4 {
					grid-column: span 4;
				}

				&.colspan-3 {
					grid-column: span 3;
				}

				&.colspan-2 {
					grid-column: span 2;
				}

				&.colspan-1 {
					grid-column: span 1;
				}
			}
		}
	}

	@media (max-width: $m-width) {
		#form {
			width: 100%;
			box-sizing: border-box;
			border-radius: 0;
		}
	}
}
</style>
