<template>
	<div>
		<!--
    <Vueditor class="editor" ref="editor"></Vueditor>
    -->
		<vue-editor
			v-model="editorContent"
			placeholder="This is a placeholder"
			class="editor"
			@input="handleOnInput"
		></vue-editor>
	</div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
	components: {
		VueEditor,
	},

	props: { value: { type: String, default: "" } },

	data() {
		return {
			editorContent: this.value,
		};
	},

	mounted() {
		document
			.getElementById("quill-container")
			.setAttribute("style", "max-height: 500px; overflow-y: scroll");
	},

	methods: {
		getEditorContent() {
			//return this.$refs.editor.getContent();
			return this.editorContent;
		},
		setEditorContent(newContent) {
			//this.$refs.editor.setContent(newContent);
			this.editorContent = newContent;
		},
		handleOnInput(event) {
			this.$emit("input", event);
		},
	},
};
</script>

<style lang="scss" scoped></style>
