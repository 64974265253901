<template>
	<settings-view title="Abonnierte Stufen">
		<p>Wähle die Stufen aus, von denen Du Updates und Nachrichten erhalten willst.</p>
		<div v-if="groups" id="groups-toggle-container">
			<check-box
				v-for="(group, index) of groups"
				:key="index"
				:checked="checked(group)"
				@input="updateSubscribedGroupsLocal($event, group)"
				>{{ group.name }}<br /><span style="color: #aaa; font-size: 11pt">
					({{ group.year }})</span
				></check-box
			>
		</div>
		<loading-spinner v-else info-text="Stufen werden geladen, bitte warten" theme="mint" />
		<button class="styled mint" :disabled="processing" @click="saveSubscribedGroups()">
			Stufen speichern
		</button>
		<loading-spinner
			v-if="processing"
			info-text="Einstellungen werden gespeichert..."
			theme="mint"
		/>
	</settings-view>
</template>

<script>
import SettingsView from "@/views/Settings.vue";
import CheckBox from "@/components/CheckBox";
import LoadingSpinner from "@/components/LoadingSpinner";
import { bus } from "@/bus";

export default {
	components: {
		SettingsView,
		CheckBox,
		LoadingSpinner,
	},

	data() {
		return {
			groups: null,
			prevSubscribedGroups: [],
			subscribedGroups: [],
			processing: false,
		};
	},

	created() {
		if (this.$store.state.user) {
			this.onLoad();
		} else {
			bus.$on("onLogin", (_user) => this.onLoad());
		}
	},

	methods: {
		onLoad() {
			this.loadGroups().then(() => {
				this.loadSubscribedGroups();
			});
		},
		checked(group) {
			if (!this.subscribedGroups) {
				return false;
			}
			return this.subscribedGroups && this.subscribedGroups.includes(parseInt(group.id));
		},
		loadGroups() {
			return new Promise((resolve, reject) => {
				this.$http
					.get(this.$store.getters.groupsUrl + "?order[]=year_desc&order[]=gender_asc")
					.then((res) => {
						this.groups = res.body;
						resolve();
					})
					.catch((err) => {
						console.error(err);
						this.$store.dispatch("httpError");
						reject();
					});
			});
		},
		loadSubscribedGroups() {
			let json = this.$store.state.user.subscribed_groups;
			if (json.length > 0) {
				this.subscribedGroups = JSON.parse(this.$store.state.user.subscribed_groups);
				this.prevSubscribedGroups = this.subscribedGroups.slice();
			} else {
				this.subscribedGroups = [];
				this.prevSubscribedGroups = [];
			}
		},
		saveSubscribedGroups() {
			this.processing = true;
			let promises = [];
			//Save on user
			promises.push(this.updateSubscribedGroupsServer());
			//Save on group
			let added = this.subscribedGroups.filter((x) => !this.prevSubscribedGroups.includes(x));
			let removed = this.prevSubscribedGroups.filter((x) => !this.subscribedGroups.includes(x));
			for (let groupId of added) {
				promises.push(this.updateGroupSubscribersServer(groupId, true));
			}
			for (let groupId of removed) {
				promises.push(this.updateGroupSubscribersServer(groupId, false));
			}
			//Wait for all operations to finish
			Promise.all(promises)
				.then(() => {
					this.processing = false;
					this.prevSubscribedGroups = this.subscribedGroups.slice();
					this.$store.dispatch("alert", {
						title: "Einstellungen gespeichert",
						message: "Deine abonnierten Stufen wurden erfolgreich gespeichert.",
						type: "success",
						duration: 5,
					});
				})
				.catch((err0) => {
					console.error(err0);
					this.$store.dispatch("httpError");
				});
		},
		updateGroupSubscribersServer(groupId, add) {
			let userId = parseInt(this.$store.getters.sessionUserId);
			let groupUrl = this.$store.getters.groupsUrl + "/" + groupId;
			return this.$http.get(groupUrl).then((res0) => {
				let subscribers = JSON.parse(res0.body.subscribers);
				if (add) {
					if (!subscribers.includes(userId)) {
						subscribers.push(userId);
					}
				} else {
					let index = subscribers.indexOf(userId);
					if (index > -1) {
						subscribers.splice(subscribers.indexOf(userId), 1);
					}
				}
				this.$http
					.put(groupUrl, {
						subscribers: JSON.stringify(subscribers),
					})
					.then((_res1) => {});
			});
		},
		updateSubscribedGroupsServer() {
			return this.$http
				.put(this.$store.getters.userUrl, {
					subscribed_groups: JSON.stringify(this.subscribedGroups),
				})
				.then((_res) => {});
		},
		updateSubscribedGroupsLocal(event, group) {
			if (!this.subscribedGroups) {
				return;
			}
			if (event === true) {
				this.subscribedGroups.push(parseInt(group.id));
			} else if (event === false) {
				this.subscribedGroups.splice(this.subscribedGroups.indexOf(parseInt(group.id)), 1);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";
#groups-toggle-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: $m-el-padding;
}

@media (min-width: $m-width) {
	#groups-toggle-container {
		grid-template-columns: repeat(5, 1fr);
	}
}
</style>
