<template>
	<transition enter-active-class="animate__animated animate__fadeIn">
		<div v-if="page" class="directus-page-content">
			<h1>{{ pageTitle }}</h1>
			<div v-html="pageBody"></div>
		</div>
	</transition>
</template>

<script>
import { bus } from "@/bus";
import cache from "@/cache";
import { pages } from "@/cms";
import config from "@/config/config";

const CACHE_KEY_PREFIX = "directus-pagecache";
const ENABLE_CACHING = config.directus.enableCaching;

console.log("Directus caching set to", ENABLE_CACHING);

export default {
	title({ shortAppend }) {
		return this.page
			? shortAppend(this.page.document_title || this.page.title)
			: this.shortAppTitle;
	},
	props: {
		pageId: { type: String, default: null },
	},
	data() {
		return {
			page: null,
		};
	},
	computed: {
		pageTitle() {
			if (this.page) return this.page.title;
			return "";
		},
		pageBody() {
			if (this.page) return this.page.body;
			return "Lädt...";
		},
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		async fetchData() {
			// Query cache first
			const cacheEntryKey = `${CACHE_KEY_PREFIX}-${this.pageId}`;
			const cacheEntryValue = cache.getJsonObject(cacheEntryKey);
			if (cacheEntryValue.valid && ENABLE_CACHING) {
				this.page = cacheEntryValue.data;
			} else {
				// Then get from cms
				try {
					this.page = await pages.getByField("name_id", this.pageId, {
						fields: "title,document_title,body,header_image.data.full_url",
					});
					// Cache page
					cache.storeJsonObject(cacheEntryKey, this.page);
					// Set document title
				} catch (exception) {
					console.error(exception);
					this.page = {
						title: "Nicht gefunden",
						body:
							"<p>Diese Seite wurde nicht gefunden. Bitte <a href='#/feedback'>Administrator " +
							"kontaktieren</a></p>",
					};
				}
			}
			if (this.page?.header_image?.data) {
				this.updateTitle();
				bus.$emit("override.header-image.img-src", this.page.header_image.data.full_url);
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
