<template>
	<div>
		<h1>Fallbalken Reservieren</h1>
		<auto-form v-model="formModel" class="wide columns bold" />
	</div>
</template>

<script>
import AutoForm from "@/components/AutoForm";
import formModel from "@/resources/forms/fallbalken-reservation-form-model";

export default {
	components: {
		AutoForm,
	},

	data() {
		return {
			formModel: formModel,
		};
	},
};
</script>

<style scoped></style>
