<template>
	<div>
		<h1>Neues Passwort erstellen</h1>
		<div v-show="authenticated">
			<form class="form-box mobile-borderless" @submit.prevent="changePassword()">
				<h4>Benutzername</h4>
				<form-field v-model="username" type="text" disabled />
				<h4>Neues Passwort eingeben</h4>
				<form-field
					ref="newPw"
					v-model="newPassword"
					type="password"
					:validation="passwordValidation"
					required
				></form-field>
				<h4>Neues Passwort wiederholen</h4>
				<form-field
					ref="newPwRepeat"
					v-model="newPasswordRepeat"
					type="password"
					:validation="passwordRepeatValidation"
					required
				></form-field>
				<input type="submit" value="Passwort ändern" class="styled" :disabled="!validates" />
			</form>
		</div>
		<div v-show="!authenticated">
			<p>
				Es sieht leider so aus, als wäre dein Link inkorrekt. Lade die Seite neu und schaue, ob es
				dann funktioniert.
			</p>
			<p>
				<router-link to="/home" class="styled">Zurück zur Homepage</router-link>
			</p>
		</div>
	</div>
</template>

<script>
import FormField from "@/components/FormField";

import Auth from "@/mixins/auth";

export default {
	components: { FormField },
	mixins: [Auth],

	data() {
		return {
			authenticated: false,
			username: null,
			mounted: false,
			newPassword: null,
			newPasswordRepeat: null,
			passwordValidation: [
				{
					func: (x) => x.length >= 4,
					error: "Muss mindestens 4 Zeichen lang sein",
				},
				{
					func: (x) => x.length <= 32,
					error: "Kann maximal 32 Zeichen lang sein",
				},
				{
					func: (x) => !x.includes(" "),
					error: "Kann keine Leerzeichen enthalten",
				},
			],
			passwordRepeatValidation: [
				{
					func: (x) => x == this.newPassword,
					error: "Passwörter stimmen nicht ueberein",
				},
			],
		};
	},

	computed: {
		userId() {
			return this.$route.params.userId;
		},
		resetToken() {
			return this.$route.params.resetToken;
		},
		validates() {
			return this.mounted && this.$refs.newPw.validates && this.$refs.newPwRepeat.validates;
		},
	},

	created() {
		this.authenticate();
	},

	mounted() {
		this.mounted = true;
	},

	methods: {
		async authenticate() {
			try {
				let user = (await this.$http.get(this.$store.getters.usersUrl + "/" + this.userId)).body;
				this.username = user.username;
				if (user.reset_token === this.resetToken) {
					this.authenticated = true;
				} else {
					this.authenticated = false;
					this.$store.dispatch("alert", {
						type: "error",
						message: "Das Token in deinem Link stimmt nicht mit dem Account überein",
					});
				}
			} catch (err) {
				console.error(err);
				this.$store.dispatch("httpError");
			}
		},
		async changePassword() {
			if (!this.authenticated) {
				return;
			}
			let hashedPassword = this.hash(this.newPassword, "md5");
			try {
				let url = this.$store.getters.usersUrl + "/" + this.userId;
				await this.$http.put(url, {
					password: hashedPassword,
				});
				await this.$http.put(url, {
					reset_token: null,
				});
				this.$store.dispatch("alert", {
					type: "success",
					message: "Passwort wurde erfolgreich geändert.",
				});
				this.$router.push("/login");
			} catch (err) {
				this.$store.dispatch("httpError");
				console.log(err);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";
h4 {
	margin: 10px 0;
}
.form-box {
	display: block;
	background-color: #0001;
	padding: 20px;
	box-sizing: border-box;
}

@media (min-width: $m-width) {
	.form-box {
		border-radius: $border-radius-large;
	}
}
</style>
