<template>
	<div class="container" :class="[selected ? 'selected' : 'deselected', status]" @click="onClick">
		<strong>{{ request.group_name || "Stufe " + request.group_id }}</strong>
		<div class="line">{{ datetimeFull }}</div>
		<div class="line">{{ request.helpers_min }} - {{ request.helpers_desired }} Helfer</div>
		<div class="badge" :class="status"></div>
	</div>
</template>

<script>
import HelperRequestObject from "@/mixins/helperRequestObject";
import Selectable from "@/mixins/selectable";

export default {
	mixins: [Selectable, HelperRequestObject],

	props: {
		request: {
			type: Object,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";

.container {
	display: block;
	padding: 10px 15px;
	border-radius: 10px;
	outline: 1px solid #0003;
	box-shadow: 0 0 15px #0001;
	transition: all 100ms;
	position: relative;

	&.past {
		color: #000b;
	}

	&.selected {
		outline-color: $blue;
		outline-width: 3px;

		&.accepted {
			outline-color: $mint;
		}

		&.rejected {
			outline-color: $red;
		}

		&.pending {
			outline-color: $yellow;
		}

		&.past {
			outline-color: $blue;
		}
	}

	.line {
		margin: 5px 0;
	}

	.badge {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 12px;
		height: 12px;
		background-color: $light-blue;
		border-radius: 100px;

		&.past {
			background-color: $blue;
		}

		&.accepted {
			background-color: $mint;
		}

		&.rejected {
			background-color: $red;
		}

		&.pending {
			background-color: $yellow;
		}
	}
}
</style>
