<template>
	<div class="cookie-base">
		<Transition
			enter-active-class="animate__animated animate__fadeIn"
			leave-active-class="animate__animated animate__fadeOut"
		>
			<div class="cookie-banner" v-if="!cookieConsent">
				<span class="info-text">
					<i class="fa-solid fa-cookie-bite" style="margin-right: 1em"></i>
					<strong>Diese Seite verwendet Guezli.</strong> Es werden ausschliesslich zwingend
					notwendige Cookies benutzt. Für weitere Infos, siehe unsere
					<a :href="privacyStatementLink" target="_blank">Datenschutzrichtlinien</a>.
				</span>
				<span class="buttons">
					<button @click="acceptCookies" class="styled">OK</button>
				</span>
			</div>
		</Transition>
	</div>
</template>

<script>
import url from "url";
const cookieConsentLSKey = "cookie-consent";
export default {
	name: "CookieBanner",
	data() {
		return {
			cookieConsent: false,
		};
	},
	created() {
		this.cookieConsent = this.getCookieConsent();
	},
	computed: {
		privacyStatementLink() {
			return url.resolve(
				this.$store.getters.apiUrl,
				"upload/doc/24-01-15_Datenschutzerklärung.pdf"
			);
		},
	},
	methods: {
		/**
		 * Accepts the use of cookies and stores the consent in local storage
		 *
		 * @returns {void}
		 */
		acceptCookies() {
			localStorage.setItem(cookieConsentLSKey, JSON.stringify(true));
			this.cookieConsent = true;
		},

		/**
		 * Returns true if the user has given consent to use cookies
		 *
		 * @returns {boolean}
		 */
		getCookieConsent() {
			const consent = JSON.parse(localStorage.getItem(cookieConsentLSKey));
			return consent === true;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";

.cookie-base {
	position: fixed;
	inset: 0;
	pointer-events: none;
}

.cookie-banner {
	pointer-events: all;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: $yellow;
	padding: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid rgba($blue, 0.2);
	border-bottom: none;
	box-shadow: 0 0 50px rgba($dark, 0.35);
}

button.styled {
	display: inline-block;
	margin: 0;
}
</style>
