<template>
	<div class="pwa-update-base">
		<div class="container" v-if="updateExists">
			<strong>Updates verfügbar. Bitte Seite neu laden.</strong
			><button class="styled" @click="update()">Jetzt neu laden</button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			/**
			 * @type {ServiceWorkerRegistration}
			 */
			registration: null,
			updateExists: false,
			refreshing: false,
		};
	},

	created() {
		document.addEventListener("swUpdated", this.onUpdated, { once: true });

		if ("serviceWorker" in navigator) {
			navigator.serviceWorker.addEventListener("controllerchange", () => {
				if (this.refreshing) return;
				this.refreshing = true;
				window.location.reload();
			});
		}
	},

	methods: {
		onUpdated(event) {
			this.registration = event.detail;
			this.updateExists = true;
		},

		update() {
			this.updateExists = false;
			if (!this.registration || !this.registration.waiting) return;
			this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";

.pwa-update-base {
	position: fixed;
	pointer-events: none;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: center;

	.container {
		pointer-events: all;
		position: absolute;
		background: white;
		border: 1px solid lighten($blue, 40%);
		bottom: 0;
		border-radius: 10px;
		display: flex;
		gap: 20px;
		align-items: center;
		padding: 0 10px;
		margin-bottom: 15px;
		box-shadow: 1px 2px 15px #0002;
	}
}
</style>
