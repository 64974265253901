<template>
	<directus-page pageId="anlaesse/cevi-flohmi"></directus-page>
</template>

<script>
import DirectusPage from "@/components/DirectusPage";

export default {
	components: { DirectusPage },
};
</script>

<style lang="scss" scoped>
.timetable {
	td {
		padding: 3px 7px;

		&:first-of-type {
			padding-left: 0;
		}

		&:last-of-type {
			padding-right: 0;
		}
	}
}
</style>
