<template>
	<div id="diagram">
		<div v-for="(column, columnIndex) of legend" :key="columnIndex" class="column">
			<div
				v-for="(item, itemIndex) of column"
				:key="itemIndex"
				:class="'item-box ' + item.type + (item.span ? ' span-' + item.span : '')"
			>
				<div v-if="item.tag" :class="'tag' + (item.tag ? ' ' + item.tag : '')"></div>
				<img v-if="item.src" :src="require('../../assets/img/organigramm-thumbnail/' + item.src)" />
				<div v-if="item.title" class="title">{{ item.title }}</div>
				<div v-if="item.name" class="name">{{ item.name }}</div>
				<div v-if="item.since" class="since">seit {{ item.since }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import legend from "@/resources/organigramm/legend.json";

export default {
	data() {
		return {
			legend: legend,
		};
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/kontakt";
</style>
