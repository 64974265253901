import { collection, file } from "./directus";

export const pages = collection("pages");
export const topicalImages = collection("topical_images");
export const newsArticles = collection("news_articles");
export const matCategories = collection("mat_categories");
export const matItems = collection("mat_items");
export const adventCalendar = collection("advent_calendar");
export const ceviNews = collection("cevi_news");

export { file };
