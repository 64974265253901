import Vue from "vue";
import store from "@/store";

export const bus = new Vue();

export function onUserLoaded(callback) {
	if (store.state.userLoaded) {
		// User already loaded and cached in store
		callback(store.state.loggedIn && store.state.user != null, store.state.user);
	} else {
		// Not loaded yet. Need to wait for event
		bus.$on("loadedUser", callback);
	}
}
