<template>
	<div v-if="headerImgSrc" id="img-container">
		<img :src="fadeImgSrc" :style="overrideImgStyles" class="bottom" :class="fadeImgClass" />
		<img :src="headerImgSrc" :style="overrideImgStyles" class="top" :class="headerImgClass" />
		<div class="shadow"></div>
	</div>
</template>

<script>
import { bus } from "../bus.js";
import { sleep } from "../functions";

export default {
	data() {
		return {
			headerImgSrc: null,
			headerImgClass: "shown",
			fadeImgSrc: null,
			fadeImgClass: "transparent",
			overrideImgStyles: null,
		};
	},
	watch: {
		$route(_to, _from) {
			let autoSource = this.getImageSourceAuto();
			// console.log("Changing header source to: " + autoSource);
			this.setImageSourceNoAnim(autoSource, true);
		},
	},
	created() {
		bus.$on("override.header-image.img-src", (newSrc) => this.overrideSource(newSrc));
		bus.$on(
			"override.header-image.img-width",
			(newWidth) => (this.overrideImgStyles = `width: ${newWidth}%;`)
		);
		this.setImageSourceNoAnim(this.getImageSourceAuto(), true);
	},
	methods: {
		getImageSourceAuto() {
			let path = this.$route.path;
			if (path == "/") path = "/home";
			const pattern = /([^/]+)$/;
			const replace = "min/$1.jpg";
			return path.replace(pattern, replace);
		},
		overrideSource(src) {
			if (src == null) {
				this.setImageSourceNoAnim(this.getImageSourceAuto(), true);
			} else {
				this.setImageSourceNoAnim(src, false);
			}
		},
		setImageSourceNoAnim(src, loadAsset) {
			if (src == null) {
				this.headerImgSrc = null;
				return;
			}
			try {
				if (loadAsset) this.headerImgSrc = require("../assets/img/header" + src);
				else this.headerImgSrc = src;
			} catch (e) {
				this.headerImgSrc = null;
			}
		},
		async setImageSource(src, loadAsset) {
			this.fadeOutImage(this.headerImgSrc);
			await sleep(10);
			this.headerImgClass = "transparent";

			try {
				if (loadAsset) this.headerImgSrc = require("../assets/img/header" + src);
				else this.headerImgSrc = src;
			} catch (e) {
				// File does not exist, do nothing
				this.headerImgSrc = null;
				return;
			}

			await sleep(10);
			this.headerImgClass = "shown";
		},
		async fadeOutImage(src) {
			this.fadeImgClass = "shown";
			await sleep(10);
			this.fadeImgSrc = src;
			this.fadeImgClass = "transparent";
			await sleep(250);
			this.fadeImgSrc = null;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/vars";

#img-container {
	background-color: $light;
	overflow: hidden;
	display: flex;
	align-items: baseline;
	justify-content: center;
	max-height: 200px;
	position: relative;

	img {
		display: block;
		width: 130%;
		left: 0;
	}

	.top {
		position: relative;
	}

	.bottom {
		position: absolute;
		left: 0;
	}

	.shadow {
		position: absolute;
		width: 200%;
		height: 100%;
		box-shadow: inset 0 0 20px 5px #000a;
		top: 0;
		left: -50%;
		pointer-events: none;
	}

	// .top.transparent {
	// 	opacity: 0;
	// }

	// .top.shown {
	// 	opacity: 1;
	// 	transition: opacity 250ms ease-in-out;
	// }

	// .bottom.transparent {
	// 	opacity: 0;
	// 	transition: opacity 250ms ease-in-out;
	// }

	// .bottom.shown {
	// 	opacity: 1;
	// }
}

@media (min-width: $m-width) {
	#img-container {
		max-height: 500px;

		img {
			width: 100%;
		}
	}
}
</style>
