import { bus } from "@/bus";

export default {
	methods: {
		authenticateGroupUser() {
			let allowed = (user) => {
				let groups = JSON.parse(user.administered_groups);
				return groups.includes(parseInt(this.$route.params.id)) || groups.includes("*");
			};
			return new Promise((resolve) => {
				if (this.$store.state.user != null) {
					resolve(allowed(this.$store.state.user));
				} else {
					bus.$on("onLogin", (user) => {
						resolve(allowed(user));
					});
				}
			});
		},
	},
};
