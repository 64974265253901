<template>
	<div>
		<directus-page page-id="taetigkeit"></directus-page>
	</div>
</template>
<script>
import DirectusPage from "@/components/DirectusPage";

export default {
	components: {
		DirectusPage,
	},
};
</script>
<style lang="scss">
@import "src/styles/vars";

.player {
	width: 100%;
	height: 55vw;
}

@media (min-width: $m-width) {
	.player {
		width: 30vw;
		height: 20vw;
	}
}
</style>
