<template>
	<div>
		<div
			style="
				font-size: 100px;
				font-weight: bold;
				display: block;
				color: #ee7777;
				margin-bottom: 35px;
			"
		>
			:(
		</div>
		<h1>404 Not Found</h1>
		<p>
			Die Seite, die Du aufgerufen hast, existiert nicht. Kontrolliere den Link oder versuche es mit
			einer anderen Seite.
		</p>
		<p>
			<router-link to="/" class="styled">Zurück zur Startseite</router-link>
		</p>
	</div>
</template>

<script>
export default {};
</script>

<style scoped></style>
