import { sortByProp } from "./functions";

String.prototype.capitalize = function () {
	return this.charAt(0).toUpperCase() + this.slice(1);
};

Array.prototype.sortByProp = function (propName) {
	return this.sort((a, b) => sortByProp(propName, a, b));
};

Array.prototype.random = function () {
	return this[Math.floor(Math.random() * this.length)];
};

Array.prototype.unique = function () {
	return [...new Set(this)];
};

Array.prototype.sorted = function () {
	return [...this].sort();
};

Array.prototype.notNull = function () {
	return this.filter((x) => x != null);
};
