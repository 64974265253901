<template>
	<div>
		<h1>News-Artikel erstellen</h1>
		<div v-if="idValid">
			<span class="field-caption">ID-Name</span>
			<input v-model="article.name" type="text" class="styled block input" />
			<span class="field-caption">Titel</span>
			<input v-model="article.title" type="text" class="styled block input" />
			<span class="field-caption">Autor</span>
			<input v-model="article.author" type="text" class="styled block input" />
			<span class="field-caption">Datum erstellt</span>
			<input v-model="article.date_created" type="date" class="styled block input" />
			<span class="field-caption">Inhalt</span>
			<text-editor ref="editor" />
			<button class="styled mint padded" @click="saveArticle()">Artikel erstellen</button>
			<button class="styled red padded" @click="$refs.popupConfirmCancel.open()">Abbrechen</button>
		</div>
		<div v-else>
			<p>Den Artikel, den du bearbeiten willst gibt es nicht, oder der Link ist falsch.</p>
			<router-link to="/admin/articles/create" class="styled button mint"
				>Neuen Artikel erstellen</router-link
			>
		</div>
		<popup-view ref="popupConfirmCancel" @confirm="$router.push('/admin/articles/manage')">
			<span
				>Bist du dir sicher, dass du das Bearbeiten abbrechen willst? Jegliche Änderungen werden
				zurückgestzt.</span
			>
		</popup-view>
	</div>
</template>

<script>
import TextEditor from "@/components/TextEditor";
import PopupView from "@/views/Popup";

export default {
	components: {
		TextEditor,
		PopupView,
	},

	data() {
		return {
			article: null,
			idValid: true,
		};
	},

	computed: {
		emptyArticle() {
			return {
				author: null,
				content: null,
				date_created: new Date().toISOString().slice(0, 10),
				name: null,
				title: null,
			};
		},
	},

	created() {
		this.article = this.emptyArticle;
	},

	methods: {
		saveArticle() {
			this.article.content = this.$refs.editor.getEditorContent();
			this.$http
				.post(this.$store.getters.apiUrl + "news_articles", this.article)
				.then((_res) => {
					this.$store.dispatch("alert", {
						message: "Artikel wurde erfolgreich gespeichert.",
						type: "success",
					});
					this.$router.go(-1);
				})
				.catch((err) => {
					console.log(err);
					this.$store.dispatch("httpError");
				});
		},
	},
};
</script>

<style lang="scss" scoped>
span.field-caption {
	font-weight: bold;
	font-size: 18px;
}
input.input {
	width: 400px;
	margin-bottom: 20px;
}
</style>
