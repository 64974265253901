<template>
	<div id="container">
		<router-link :to="profileUrl" class="avatar" @click.native="handleOnClicked()">
			<img
				:src="
					require(['group_user', 'helper_user'].includes(user.role)
						? '@/assets/img/users.png'
						: '@/assets/img/user.png')
				"
				alt="users"
				class="placeholder-icon"
			/>
		</router-link>
		<div class="username">
			Eingeloggt als:
			<router-link :to="profileUrl" class="username-text" @click.native="handleOnClicked()">{{
				username
			}}</router-link>
		</div>
		<div class="actions">
			<router-link
				v-for="(group, index) of adminGroups"
				:key="index"
				:to="getGroupUrl(group.id)"
				class="action-button"
				@click.native="handleOnClicked()"
				><i class="fas fa-users"></i> {{ group.name }}</router-link
			>
			<button class="action-button" @click="submitLogout">
				<i class="fas fa-sign-out-alt"></i> Ausloggen
			</button>
		</div>
	</div>
</template>

<script>
import Auth from "@/mixins/auth";

export default {
	mixins: [Auth],

	data() {
		return {
			cachedUsername: null,
			adminGroups: null,
		};
	},

	computed: {
		user() {
			return this.$store.state.user;
		},
		username() {
			return this.$store.getters.sessionUser;
		},
		profileUrl() {
			return "/profile";
		},
	},

	created() {
		this.cachedUsername = this.$store.state.user.username;
		this.loadAdminGroups();
	},

	methods: {
		submitLogout() {
			this.logoutUser(this.$store.state.user, (action) => {
				this.$emit("onLogout");
				if (action == "logout") {
					this.$store.dispatch("alert", {
						message: "Bye bye, " + this.cachedUsername + "! Du bist jetzt ausgeloggt.",
						type: "success",
						duration: 4,
					});
				} else if (action == "logout_all") {
					this.$store.dispatch("alert", {
						title: "Erfolg",
						message: "Du hast dich erfolgreich aus allen Geraeten ausgeloggt",
						type: "success",
					});
				}
				this.$router.push("/home");
			});
			this.handleOnClicked();
		},
		getGroupUrl(id) {
			return `/stufen/${id}/feed`;
		},
		loadAdminGroups() {
			//Get first 2 administered groups
			let adminGroupIds = JSON.parse(this.$store.state.user.administered_groups)
				.slice(0, 3)
				.filter((x) => !isNaN(x))
				.map((x) => parseInt(x));
			let promises = [];
			for (let id of adminGroupIds) {
				promises.push(
					this.$http.get(this.$store.getters.groupsUrl + id + "?select[]=name&select[]=id")
				);
			}
			Promise.all(promises).then((response) => {
				this.adminGroups = response.map((x) => x.body);
			});
		},

		handleOnClicked() {
			this.$emit("clicked");
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

#container {
	display: grid;
	grid-template-areas:
		"avatar user user"
		"avatar actions actions";
	grid-column-gap: 10px;
	grid-template-columns: min-content 1fr 1fr;

	.username {
		grid-area: user;

		.username-text {
			color: $mint;
			font-weight: bold;
		}
	}

	.avatar {
		grid-area: avatar;
		background-color: #fffa;
		height: 35px;
		width: 35px;
		clip-path: circle(50%);

		.placeholder-icon {
			color: #000;
			width: 100%;
			height: 100%;
		}
	}
	.actions {
		grid-area: actions;
		display: flex;

		.action-button {
			color: #fff8;
			display: block;
			width: max-content;
			background-color: transparent;
			border: none;
			font-size: 14px;
			font-family: $app-sans-font;
			align-self: center;
			outline: none;
			padding: 0;
			margin-right: 15px;

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}
}
</style>
