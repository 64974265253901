<template>
	<div>
		<div id="logo">
			<img src="@/assets/img/fallbalken/logo-fallbalken.png" />
		</div>
		<directus-page page-id="fallbalken"></directus-page>
	</div>
</template>

<script>
import DirectusPage from "@/components/DirectusPage";

export default {
	components: { DirectusPage },
	computed: {
		legacyForm() {
			return this.$store.getters.features.fallbalken.legacy_order_form;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

.calendar {
	width: 100%;
	height: 400px;
}

#logo {
	width: 100%;
	margin-bottom: 50px;

	img {
		width: 100%;
	}
}

@media (min-width: $m-width) {
	.calendar {
		width: 50%;
		height: 450px;
	}

	#logo {
		width: 50%;
	}
}
</style>
