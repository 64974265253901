import url from "url";
import axios from "axios";
import store from "@/store";
import CollectionClient from "./CollectionClient";

export const directusProjectKey = "ceviwie";
export const cmsBase = url.resolve(store.getters.apiUrl, "directus/public/");
export const cmsProj = url.resolve(cmsBase, directusProjectKey);
export const cmsFiles = `${cmsProj}/files`;

/**
 * Gets the api url for a given collection
 * @param {string} collection Directus name of collection
 */
function getCollectionUrl(collection) {
	return `${cmsProj}/items/${collection}`;
}

/**
 * Gets the API URL for a given item in a collection, identified by its ID
 *
 * @param {string} collection
 * @param {number} id
 * @return {string}
 */
function getItemUrl(collection, id) {
	return `${getCollectionUrl(collection)}/${id}`;
}

/**
 * Gets all items in a collection
 * @param {string} collection Name of collection
 * @param {object} query Additional query items
 */
export function getCollection(collection, query) {
	return axios.get(getCollectionUrl(collection), { params: query });
}

/**
 * Gets a single item from a collection
 * @param {string} collection What collection to be fetched from
 * @param {number} id ID of the item to be fetched
 * @param {object} query Additional query parameters to pass to axios
 */
export function getCollectionItem(collection, id, query) {
	return axios.get(`${getCollectionUrl(collection)}/${id}`, { params: query });
}

/**
 * Creates a single item in a collection (omitted fields may be filled
 * automatically)
 *
 * @export
 * @param {string} collection Collection this item belongs to
 * @param {object} item Object with item properties
 * @param {object} query Optional query string
 * @return {Promise<AxiosResponse<any>>} Http response
 */
export function createItem(collection, item, query) {
	return axios.post(getCollectionUrl(collection), item, { params: query });
}

/**
 * Updates an item in a collection, identified by ID
 *
 * @export
 * @param {string} collection
 * @param {number} id
 * @param {object} item
 * @param {object} query
 */
export function updateItem(collection, id, item, query) {
	return axios.patch(getItemUrl(collection, id), item, { params: query });
}

/**
 * Creates a collection client for given collection
 * @param {string} collection Name of collection
 * @returns {CollectionClient} New collection client object
 */
export function collection(collection) {
	// Create new collection client
	// Client can be customized before being returned
	return new CollectionClient(collection);
}

/**
 * Gets a file with given ID
 * @param {number} fileId Id of the file
 * @param {object} query Additional axios query object
 */
export function file(fileId, query) {
	return axios.get(`${cmsFiles}/${fileId}`, { params: query });
}

/**
 * Retrieves a list of files from ids
 * @param {number[]} fileIds Ids of the files to retrieve
 * @param {object} query Additional query parameters
 */
export async function files(fileIds, query) {
	// Create a request for all files
	const filePromises = fileIds.map((id) => file(id, query));
	// Await all
	const files = await Promise.all(filePromises);
	// Assign to id => file object
	const res = {};
	for (const file of files) {
		res[file.data.data.id] = file.data.data;
	}
	return res;
}

/**
 * Formats a date for use in directus
 *
 * @export
 * @param {Date} date Date object
 */
export function formatDirectusDate(date) {
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const day = date.getDate().toString().padStart(2, "0");
	const year = date.getFullYear();
	const hours = date.getHours().toString().padStart(2, "0");
	const minutes = date.getMinutes().toString().padStart(2, "0");
	const seconds = "00";
	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

/**
 * Same as formatDirectusDate but first parses the date from a string
 *
 * @export
 * @param {string} dateStr
 */
export function formatDirectusDateFromString(dateStr) {
	return formatDirectusDate(new Date(dateStr));
}
