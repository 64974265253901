import { getCollection, getCollectionItem, files, createItem, updateItem } from "./directus";

export default class CollectionClient {
	#collection;
	constructor(collection) {
		this.#collection = collection;
	}

	/**
	 * Gets all items in this collection
	 * @param {object} query Additional query parameters
	 * @param {string[]} includeFields Array of field names of files to be included
	 * @returns {Promise<any[]|{items:any[],files:any[]}>} Array of collection items
	 */
	async getAll(query = {}, includeFields = []) {
		return getCollection(this.#collection, query).then((x) => {
			const items = x.data.data;
			if (includeFields?.length > 0) {
				// It is assumed that all objects in items contain the fields specified in includeFiles
				const fileIds = items
					// Get ids from file fields and flatmap into big file id array
					.flatMap((item) => includeFields.map((field) => item[field]))
					// Discard null entries
					.notNull()
					// Remove duplicates (by copy)
					.unique()
					// Sort (by copy)
					.sorted();
				// Return a promise that returns the items and files as separate keys in object
				return files(fileIds).then((files) => ({ items, files }));
			} else {
				// No file includes, just return the items w/out wrapping object
				return items;
			}
		});
	}

	/**
	 * Gets a single item by its ID
	 * @param {number} id Item ID
	 * @param {object} query Additional query parameters
	 * @param {string[]} includeFiles Array of field names of files to be included
	 * @returns {Promise<any|{item:any,files:any[]}}
	 */
	getById(id, query, includeFiles = []) {
		return getCollectionItem(this.#collection, id, query).then((x) => {
			const item = x.data.data;
			if (includeFiles?.length > 0) {
				const fileIds = includeFiles
					.map((id) => item[id])
					.notNull()
					.unique()
					.sorted();
				return files(fileIds).then((files) => ({ item, files }));
			} else {
				return item;
			}
		});
	}

	getByField(fieldName, fieldValue, query) {
		query[`filter[${fieldName}][eq]`] = fieldValue;
		query["single"] = 1;
		return this.getAll(query);
	}

	getManyByField(fieldName, fieldValue, operator = "eq", query = {}) {
		query[`filter[${fieldName}][${operator}]`] = fieldValue;
		return this.getAll(query);
	}

	create(item, query) {
		return createItem(this.#collection, item, query);
	}

	update(id, data, query) {
		return updateItem(this.#collection, id, data, query);
	}
}
