<template>
	<div id="container" class="mobile-borderless">
		<h3>Stufen-Admin Funktionen</h3>
		<p class="desc">
			Du bist ein Admin von dieser Stufe. Hier kannst du verschiedene Sachen machen, wie den
			Anschlag bearbeiten oder eine Anschlags-Mail verschicken.
			<span style="color: #f008">
				Achtung! Was hier geändert und bestätigt wird, können alle sehen!
			</span>
		</p>
		<div id="buttons-container">
			<router-link to="edit-news-feed" class="styled button blue">
				Anschlag bearbeiten
			</router-link>
			<router-link to="edit-bio" class="styled button blue"> Stufen-Bio bearbeiten </router-link>
			<router-link to="send-news" class="styled button blue">
				Anschlags-Email versenden
			</router-link>
			<button class="styled blue" @click="$refs.popupGroupImage.open()">Stufenfoto ändern</button>
		</div>
		<popup
			ref="popupGroupImage"
			title="Stufen-Foto ändern"
			confirm-text="Foto ändern"
			:button-confirm-enabled="selectedFile !== null"
			@confirm="updateGroupImage()"
		>
			<p>
				Foto auswählen. Unterstützte Formate sind ".png", ".jpg" und ".jpeg". Maximale Grösse ist
				1MB.
			</p>
			<input id="filepicker" type="file" accept="image/*" @change="onGroupImageFileChange" />
		</popup>
	</div>
</template>

<script>
import url from "url";
import Popup from "../views/Popup";
import { bus } from "../bus";
export default {
	components: {
		Popup,
	},

	data() {
		return {
			selectedFile: null,
			group: null,
			groupUrl: null,
		};
	},

	created() {
		this.groupUrl = url.resolve(this.$store.getters.groupsUrl, this.$route.params.id);
		this.$http
			.get(this.groupUrl)
			.then((result) => {
				this.group = result.body;
			})
			.catch((err) => {
				console.error(err);
				this.$store.dispatch("httpError");
			});
	},

	methods: {
		onGroupImageFileChange(event) {
			const file = event.target.files[0];
			this.selectedFile = file;
		},
		updateGroupImage() {
			// Sanity check
			if (this.group == null) {
				console.error("Can't update picture: no group selected");
				return;
			}
			// Set app busy
			this.$store.commit("setAppLoadingText", "Bild wird hochgeladen...");
			this.$store.commit("setAppLoadingStatus", true);
			// Create form for image (FormData)
			const data = new FormData();
			data.append("new_image", this.selectedFile);
			// Get endpoint, send request
			const endpoint = url.resolve(
				this.$store.getters.apiUrl,
				`group-functions/update-group-picture/${this.group.id}`
			);
			this.$http
				.post(endpoint, data)
				.then((res) => {
					this.$store.dispatch("alert", { type: "success", message: res.body.message });
					bus.$emit("override.header-image.img-src", res.body.new_image_url);
					this.selectedFile = null;
				})
				.catch((error) => {
					this.$store.dispatch("alert", { type: "error", message: error.body });
				})
				.finally(() => this.$store.commit("setAppLoadingStatus", false));
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/vars";

#container {
	width: 100%;
	box-sizing: border-box;
	background-color: desaturate($light, 5%);
	padding: 20px;
	margin-bottom: $d-el-padding;

	#buttons-container {
		button,
		a {
			width: 100%;
		}
	}
}

.desc {
	font-size: 14px;
	color: #0008;
}

#filepicker {
	font-family: $app-sans-font;
	display: block;
	margin: auto;
	padding: $m-el-padding 0;
}

@media (min-width: $m-width) {
	#container {
		border-radius: $border-radius-large;
		margin: 0 0 $d-el-padding 0;

		#buttons-container {
			display: flex;
			flex-wrap: wrap;
			margin: -5px;

			button,
			a {
				flex-basis: calc(25% - 10px);
				margin: 5px;
			}
		}
	}

	#filepicker {
		margin: $d-el-padding 0;
	}

	#btn-file-upload {
		width: calc(100% - 50px);
	}
}
</style>
