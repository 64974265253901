<template>
	<div v-if="enabled">
		<h3>ZoBack {{ year }} - Jetzt online bestellen!</h3>
		<p>
			Dieses Jahr findet wieder eine Zopfback-aktion statt! Wir werden an den Wochenenden des
			<strong>{{ orderDates }}</strong> an den Haustüren von Wiesendangen, Elsau und Hegi
			Zopfbestellungen aufnehmen. Die Zöpfe werden wir am Sonntagmorgen, dem
			<strong>{{ deliveryDate }}</strong
			>, bei Ihnen zuhause vorbeibringen. Hier gelangen Sie zum Onlinebestellformular.
		</p>
		<div class="logo-container">
			<img src="../../assets/img/zoback/ceviwie_zoback_logo_color_b.png" class="zoback-logo" />
			<router-link to="/anlaesse/zoback/bestellen" style="display: block">
				<button class="styled">Zur Online-Bestellung</button>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		enabled() {
			return this.$store.getters.features.zoback.home_news_enabled;
		},
		year() {
			return new Date().getFullYear().toString();
		},
		orderDates() {
			const dates = this.$store.getters.features.zoback.order_dates;
			const date1 = `${new Date(dates[0]).getDate()}.${new Date(dates[0]).getMonth() + 1}.`;
			const date2 = new Date(dates[1]).toLocaleDateString("de-CH");
			return `${date1} und ${date2}`;
		},
		onlineOrderDates() {
			return (
				"vom " +
				this.$store.getters.features.zoback.online_order_dates
					.map((x) => new Date(x).toLocaleDateString("de-CH"))
					.join(" bis am ")
			);
		},
		deliveryDate() {
			return new Date(this.$store.getters.features.zoback.delivery_date).toLocaleDateString(
				"de-CH"
			);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../../styles/vars.scss";

.logo-container {
	display: flex;
	align-items: center;
	justify-content: center;
	.zoback-logo {
		width: 150px;

		@media screen and (min-width: $m-width) {
			width: 25%;
		}
	}
}
</style>
