export default {
	emits: ["select", "deselect", "created"],

	props: {
		selectionKey: {
			type: undefined,
			default: undefined,
		},
	},

	data() {
		return {
			selected: false,
		};
	},

	created() {
		this.$emit("created", this);
	},

	methods: {
		onClick() {
			this.selected = !this.selected;
			this.$emit(this.selected ? "select" : "deselect", this.selectionKey);
		},

		select() {
			if (!this.selected) {
				this.selected = true;
				this.$emit("select", this.selectionKey);
			}
		},

		deselect() {
			if (this.selected) {
				this.selected = false;
				this.$emit("deselect", this.selectionKey);
			}
		},
	},
};
