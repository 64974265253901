<template>
	<div id="home-page">
		<div id="home-extra">
			<!-- <HomeAlerts id="home-alerts"></HomeAlerts> <CoronaInfo
			v-if="features.corona.home_news_enabled === true" id="corona-info"></CoronaInfo> -->
			<h1>News</h1>
			<new-groups-news
				v-if="features.new_groups_news.enabled === true"
				id="new-groups-news"
			></new-groups-news>
			<zoback-news v-if="features.zoback.home_news_enabled === true" id="zoback-news"></zoback-news>
			<news-feed id="news-feed"></news-feed>
		</div>
		<div id="home-container"></div>
	</div>
</template>
<script>
import NewGroupsNews from "@/views/Home/NewGroupsNews";
import NewsFeed from "@/views/Home/NewsFeed";
import ZobackNews from "@/views/Home/ZobackNews";
// import CoronaInfo from "@/views/Home/CoronaInfo";
// import HomeAlerts from "@/views/Home/HomeAlerts";

import { bus } from "@/bus";

export default {
	title: ({ title }) => title,
	components: {
		NewsFeed,
		NewGroupsNews,
		ZobackNews,
		// CoronaInfo,
		// HomeAlerts,
	},

	computed: {
		features() {
			return this.$store.getters.features;
		},
	},

	created() {
		// Enable aside
		bus.$emit("page.aside.enabled", true);
		bus.$emit("page.content.width", 800);
	},

	destroyed() {
		bus.$emit("page.aside.enabled", false);
		bus.$emit("page.content.width", null);
	},

	methods: {},
};
</script>
<style lang="scss" scoped></style>
