<template>
	<div ref="itemContainer">
		<div v-for="(item, index) of items" :key="index" class="input-field-container">
			<form-field
				v-model="items[index]"
				type="email"
				placeholder="Email eingeben..."
				:disabled="disabled"
				class="input"
				nomargin
				@input="onFieldInput"
			></form-field>
			<button
				type="button"
				class="styled red remove-button"
				:disabled="disabled"
				@click="removeInputField(index)"
			>
				<i class="far fa-trash-alt square"></i>
			</button>
		</div>
		<div v-if="items.length === 0">
			<span style="color: #aaa">{{ listEmptyText }}</span>
		</div>
		<button
			type="button"
			class="styled mint add-button"
			:disabled="disabled"
			@click="addInputField()"
		>
			<i class="fas fa-plus square"></i>
		</button>
	</div>
</template>

<script>
import FormField from "@/components/FormField";

export default {
	components: { FormField },
	props: {
		value: { type: Array, default: null },
		disabled: { type: Boolean, default: false },
		listEmptyText: { type: String, default: 'Auf "+" klicken um hinzuzufügen' },
	},

	data() {
		return {
			items: this.value,
		};
	},

	computed: {
		itemsFinal: function () {
			return this.items.filter((x) => x);
		},
	},

	methods: {
		addInputField() {
			this.items.push(null);
		},
		removeInputField(index) {
			this.items.splice(index, 1);
			this.$emit("input", this.itemsFinal);
		},
		onFieldInput(_event) {
			this.$emit("input", this.itemsFinal);
		},
		onFieldEnterPressed() {
			console.log("hello");
			this.addInputField();
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";
.input-field-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-top: 10px;
	.input {
		flex: 1;
	}
	button {
		margin: 0;
		margin-left: $m-el-padding;
		align-self: flex-start;
	}
}

.add-button {
	width: 100%;
	box-sizing: border-box;
}

@media (min-width: $m-width) {
	.add-button {
		width: unset;
	}
}
</style>
