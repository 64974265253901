<template>
	<div>
		<h2>{{ title }}</h2>
		<p v-if="description">{{ description }}</p>
		<hr />
		<slot />
		<hr />
	</div>
</template>

<script>
export default {
	props: {
		title: { type: String, required: true, default: "Einstellungen" },
		description: {
			type: String,
			required: false,
			default: "",
		},
	},
};
</script>
