<template>
	<div id="container">
		<p v-if="serverError" class="error-message">{{ serverError }}</p>
		<div class="text-box">
			<h1>Neuen account erstellen</h1>
			<br />
			<form @submit.prevent="submit" @input="validates = validateForm()">
				<form-field
					ref="username"
					v-model="username"
					type="text"
					placeholder="Benutzername"
					:validation="usernameValidation"
				/>
				<form-field
					ref="email"
					v-model="email"
					type="email"
					placeholder="Email"
					:validation="emailValidation"
				/>
				<form-field
					ref="pass"
					v-model="password"
					type="password"
					placeholder="Passwort"
					:validation="passwordValidation"
				/>
				<form-field
					ref="repeatpass"
					v-model="repeatPassword"
					type="password"
					placeholder="Passwort wiederholen"
					:validation="passwordRepeatValidation"
				/>
				<toggle-switch
					v-model="stayLoggedIn"
					name="stay-logged-in"
					:default-state="true"
					color-class="vibrant"
					>Eingeloggt bleiben</toggle-switch
				>
				<toggle-switch
					v-model="emailNotifications"
					name="email-notifications"
					:default-state="true"
					color-class="vibrant"
					>Email-Benachrichtigungen erhalten</toggle-switch
				>
				<p v-if="formError" class="error-message">{{ formError }}</p>
				<input type="submit" value="Profil erstellen" class="styled blue" :disabled="!validates" />
			</form>
		</div>
	</div>
</template>

<script>
import FormField from "../components/FormField.vue";
import ToggleSwitch from "../components/ToggleSwitch.vue";

import auth from "../mixins/auth";

export default {
	components: {
		ToggleSwitch,
		FormField,
	},
	mixins: [auth],
	data() {
		return {
			//Form fields
			username: null,
			email: null,
			password: null,
			repeatPassword: null,
			stayLoggedIn: false,
			emailNotifications: false,

			//Form validation
			commonValidation: [
				{
					func: (x) => x.length >= 4,
					error: "Muss mindestens 4 Zeichen lang sein",
				},
				{
					func: (x) => x.length <= 32,
					error: "Kann maximal 32 Zeichen lang sein",
				},
				{
					func: (x) => !x.includes(" "),
					error: "Kann keine Leerzeichen enthalten",
				},
			],
			usernameValidation: Array,
			emailValidation: Array,
			passwordValidation: Array,
			passwordRepeatValidation: Array,
			validates: false,

			//User feedback
			formError: null,
			serverError: null,

			//Database interaction
			allUsers: [],

			//Prevent duplicate creation
			locked: false,
		};
	},
	created() {
		this.initializeValidation();
		this.getAllUsernames();
	},
	methods: {
		validateForm() {
			if (
				this.$refs.username.validates &&
				this.$refs.email.validates &&
				this.$refs.pass.validates &&
				this.$refs.repeatpass.validates
			) {
				return true;
			} else {
				return false;
			}
		},
		submit() {
			if (this.locked == true) {
				return;
			}
			this.locked = true;
			if (!this.validates) {
				this.$store.dispatch(
					"alert",
					"Etwas stimmt noch nicht ganz! Bitte schaue es dir genau an und versuche es nochmals."
				);
				return;
			}
			var user = {
				username: this.username,
				email: this.email,
				password: this.password,
				email_notifications: this.emailNotifications ? 1 : 0,
				stayLoggedIn: this.stayLoggedIn,
				role: "standard_user",
				subscribed_groups: [],
			};
			this.registerUser(user, (user) => {
				this.$store.dispatch("alert", {
					title: "Willkommen <strong>" + user.username + "</strong>!",
					message: "Du hast dich erfolgreich registriert und bist jetzt eingeloggt.",
					type: "success",
					duration: 5,
				});
				this.$router.push("/home");
				this.locked = false;
			});
		},
		initializeValidation() {
			this.usernameValidation = this.commonValidation.concat(
				{
					func: (x) => /^[\w_-]*$/.test(x),
					error: "Kann keine Sonderzeichen enthalten",
				},
				{
					func: (x) => this.allUsers.map((user) => user.username).indexOf(x) < 0,
					error: "Benutzername bereits besetzt",
				}
			);
			this.emailValidation = this.commonValidation.concat(
				{
					func: (x) => /^(\w+\.)*\w+@(\w+\.)+[A-Za-z]{2,}$/.test(x),
					error: "Die eingegebene Email ist nicht gültig",
				},
				{
					func: (x) => this.allUsers.map((user) => user.email).indexOf(x) < 0,
					error: "Diese Email ist bereits registriert",
				}
			);
			this.passwordValidation = this.commonValidation;
			this.passwordRepeatValidation = [
				{
					func: (x) => x == this.password,
					error: "Passwörter stimmen nicht ueberein",
				},
			];
		},
		getAllUsernames() {
			this.$http.get(this.$store.getters.usersUrl).then(
				(response) => {
					this.allUsers = response.body.map((x) => ({
						username: x.username,
						email: x.email,
					}));
				},
				(error) => {
					console.error(error);
					this.serverError =
						"Etwas ist mit dem Server schiefgelaufen. " +
						"Bitte kontaktiere die Administratoren wenn moeglich.";
				}
			);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/vars";

.text-box {
	background-color: #eee;
	max-width: 500px;
	margin: auto;
	padding: 20px;
	border-radius: $border-radius-large;
}
.error {
	position: relative;
	top: 10px;
}
.error-message {
	color: $red-vibrant;
	font-size: 1.2em;
	font-weight: bold;
}
label {
	position: relative;
}
h1 {
	color: $blue;
	margin-top: 20px;
}
form {
	text-align: center;
}
input {
	display: block;
	margin: $d-el-padding 0;
	width: calc(100% - 20px);
	background-color: #fff;
	font-size: 1em;

	&::placeholder {
		font-style: normal;
	}

	&[type="submit"] {
		max-width: 250px;
		margin: auto;
	}
}
#container {
	text-align: center;
	margin-bottom: 100px;
}
</style>
