<template>
	<div>
		<h1>News-Artikel verwalten</h1>
		<p>Hier kanns Du alle News-Artikel, die man auf der Homepage sehen kann, verwalten.</p>
		<table class="article-list">
			<thead>
				<tr>
					<th>ID</th>
					<th>Name</th>
					<th>Titel</th>
					<th>Erstellt am</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="article of articles" :key="article.id">
					<td>{{ article.id }}</td>
					<td>{{ article.name }}</td>
					<td>{{ article.title }}</td>
					<td>{{ toDateString(new Date(article.date_created)) }}</td>
					<td class="actions">
						<router-link :to="'/admin/articles/' + article.id + '/edit'">
							<i class="fas fa-edit edit-icon"></i>
						</router-link>
						<button class="delete-button" @click="deleteArticle(article)">
							<i class="fas fa-trash-alt delete-icon"></i>
						</button>
					</td>
				</tr>
				<tr class="add-article-row">
					<td colspan="5" style="text-align: center">
						<router-link
							to="/admin/articles/create"
							class="styled button mint"
							style="width: 250px"
						>
							<i class="fas fa-plus"></i>
						</router-link>
					</td>
				</tr>
			</tbody>
		</table>
		<popup-view ref="popupConfirmDelete">
			<span v-if="deletionCandidate">
				Bist du dir sicher, dass du den Artikel
				<b>"{{ deletionCandidate ? deletionCandidate.name : "undefined" }}"</b> löschen willst?
				Diese Aktion kann nicht rückgängig gemacht werden!
			</span>
		</popup-view>
	</div>
</template>

<script>
import PopupView from "@/views/Popup";
import DateTimeFormatter from "@/mixins/datetimeformatter";

export default {
	components: {
		PopupView,
	},
	mixins: [DateTimeFormatter],

	data() {
		return {
			articles: [],
			deletionCandidate: null,
		};
	},

	created() {
		this.loadArticles();
	},

	methods: {
		deleteArticle(article) {
			this.deletionCandidate = article;
			this.$refs.popupConfirmDelete.open();
			this.$refs.popupConfirmDelete.onConfirm = () => {
				this.$http
					.delete(this.$store.getters.apiUrl + "news_articles/" + article.id)
					.then((_res) => {
						this.$store.dispatch("alert", {
							message: "Artikel wurde erfolgreich gelöscht",
							type: "success",
						});
						this.loadArticles();
					})
					.catch((err) => {
						this.$store.dispatch("httpError");
						console.log(err);
					});
			};
		},
		loadArticles() {
			this.$http
				.get(this.$store.getters.apiUrl + "news_articles?order=date_created_desc")
				.then((res) => {
					if (Array.isArray(res.body)) {
						this.articles = res.body;
					} else if (res.body.empty) {
						this.articles = [];
					} else {
						this.articles = [res.body];
					}
				})
				.catch((_err) => this.$store.dispatch("httpError"));
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

table.article-list {
	width: 100%;
	border-collapse: collapse;
	border: 1px solid #efefef;

	th,
	td {
		padding: 10px;
	}

	thead tr {
		text-align: left;
		border-bottom: 1px solid #efefef;
	}

	tbody tr:not(.add-article-row) {
		transition: all $fade-time;

		&:nth-child(even) {
			background-color: rgba($light, 0.3);
		}

		&:hover {
			background-color: rgba($mint, 0.1);

			td.actions * {
				visibility: visible;
				opacity: 1;
			}
		}

		td.actions {
			text-align: right;

			* {
				visibility: hidden;
				opacity: 0;
				transition: all $fade-time;
			}
		}
	}
	tbody tr.add-article-row {
		border-top: 1px solid #efefef;
	}
	tbody tr.add-article-row td {
		padding: 0;
	}
}

.edit-icon {
	color: #555;
	margin-right: 20px;
	font-size: 20px;
}
.delete-icon {
	color: $red;
	font-size: 20px;
}

.edit-icon,
.delete-icon {
	&:hover {
		cursor: pointer;
		color: #fff;
		text-shadow: 0 0 10px $mint;
	}
}

.delete-button {
	background: transparent;
	border: none;
}
</style>
