<template>
	<div>
		<aside>
			<section id="topical-news">
				<h2>Aktuelles</h2>
				<a
					v-for="(item, index) of topicalNews"
					:key="index"
					:href="item.link == '' ? null : item.link"
					class="nostyle"
					:title="item.description"
				>
					<img :src="item.image.data.full_url" :alt="item.title" class="thumbnail borderless" />
				</a>
			</section>

			<section id="agenda">
				<h2>Agenda</h2>
				<calendar class="calendar-widget borderless" />
			</section>
			<section id="announcements">
				<h2>Zu vermieten</h2>
				<router-link to="/material/pizzamobil" class="nostyle">
					<img
						src="http://ceviwie.ch/legacy/Ablage/Seite/Startseite_Bilder/anzeige_1.jpg"
						alt="aktuell"
						class="thumbnail borderless"
					/>
				</router-link>
				<router-link to="/material/fallbalken" class="nostyle">
					<img
						src="http://ceviwie.ch/legacy/Ablage/Seite/Startseite_Bilder/anzeige_2.jpg"
						alt="aktuell"
						class="thumbnail borderless"
					/>
				</router-link>
				<router-link to="/material/hoernlihaus">
					<img
						src="@/assets/img/topical/HoernliHaus_Logo_2x.jpg"
						alt="HörnliHaus Logo"
						class="thumbnail borderless"
					/>
				</router-link>
			</section>
		</aside>
	</div>
</template>

<script>
import Calendar from "../components/Home/Calendar";
import { topicalImages } from "@/cms";

export default {
	title: ({ title }) => title,
	components: {
		Calendar,
	},
	data() {
		return {
			topicalNews: [],
		};
	},

	created() {
		this.fetchData();
	},

	methods: {
		async fetchData() {
			this.topicalNews = await topicalImages.getAll({
				fields: "title,link,description,image.data.full_url",
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

.thumbnail {
	width: 100%;
	margin-bottom: $m-el-padding;
}

@media screen and (max-width: $m-width) {
	.borderless {
		width: calc(100% + #{$m-page-padding * 2}) !important;
		margin-right: -$m-page-padding;
		margin-left: -$m-page-padding;
	}
}
</style>
