<template>
	<div>
		<div class="titlebar">
			<h1 style="flex: 1">Helfer-Anfragen verwalten</h1>
			<subscription-buttons
				:user-id="user ? user.id : null"
				:subject="pushSubject"
			></subscription-buttons>
		</div>
		<div class="panels">
			<div class="schedule-panel">
				<h3 class="title">Kalender-Ansicht</h3>
				<helper-schedule
					:requests="requests"
					:layout="layout"
					@select="onRequestSelect"
					@deselect="onRequestDeselect"
					@created="registerSelectionProvider"
				></helper-schedule>
			</div>
			<div class="list">
				<h3 class="title">Listen-Ansicht</h3>
				<div class="tabs">
					<input
						id="list-tab-upcoming"
						v-model="listTab"
						type="radio"
						name="list-tab"
						value="upcoming"
					/>
					<label for="list-tab-upcoming">Kommend</label>
					<input id="list-tab-past" v-model="listTab" type="radio" name="list-tab" value="past" />
					<label for="list-tab-past">Vergangen</label>
				</div>
				<button class="styled blue" @click="reloadHelperRequests">
					<i class="fa-solid fa-arrow-rotate-right"></i> Helfer-Anfragen neu laden
				</button>
				<div class="list-items">
					<template v-if="filteredRequests.length">
						<helper-request-thumb
							v-for="request of filteredRequests"
							:key="request.id"
							:selection-key="request.id"
							:request="request"
							class="list-item"
							@select="onRequestSelect"
							@deselect="onRequestDeselect"
							@created="registerSelectionProvider"
						></helper-request-thumb>
					</template>
					<div v-else>Keine Resultate</div>
				</div>
			</div>
			<div class="details" style="grid-column: span 2">
				<h3 class="title">Details zur Auswahl</h3>
				<helper-request-card
					v-if="layout == 'desktop' && selectedRequest"
					:request="selectedRequest"
					mode="readwrite"
					@submit="onHelpersAssign(selectedRequest.id, $event)"
				></helper-request-card>
				<div v-else>Keine Auswahl</div>
			</div>
		</div>
		<transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
			<div v-if="layout == 'mobile' && selectedRequest" class="details-modal-base">
				<div class="details-modal">
					<button class="styled red close" @click="onRequestDeselect(selectedRequest.id)">
						<i class="fa-solid fa-xmark"></i>
					</button>
					<h2>Details zur Auswahl</h2>
					<helper-request-card
						v-if="selectedRequest"
						:request="selectedRequest"
						mode="readwrite"
						@submit="onHelpersAssign(selectedRequest.id, $event)"
					></helper-request-card>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import { bus, onUserLoaded } from "@/bus";
import { collection } from "@/cms/directus";
import HelperRequestCard from "@/components/Helfer/HelperRequestCard.vue";
import HelperRequestThumb from "@/components/Helfer/HelperRequestThumb.vue";
import SubscriptionButtons from "@/components/SubscriptionButtons.vue";
import HelperSchedule from "../../components/Helfer/HelperSchedule.vue";

export default {
	name: "HelpersAdminView",

	components: {
		HelperRequestThumb,
		HelperRequestCard,
		HelperSchedule,
		SubscriptionButtons,
	},

	data() {
		return {
			requests: [],
			listTab: "upcoming",
			selectedRequest: null,
			selectionProviders: new Set(),
			layout: "desktop",
			user: null,
		};
	},

	computed: {
		upcomingRequests() {
			if (!this.requests) return [];
			const now = new Date();
			return this.requests.filter((x) => {
				const end = new Date(x.datetime_to);
				return end >= now;
			});
		},

		pastRequests() {
			if (!this.requests) return [];
			const now = new Date();
			return this.requests.filter((x) => {
				const end = new Date(x.datetime_to);
				return end < now;
			});
		},

		filteredRequests() {
			switch (this.listTab) {
				case "upcoming":
					return this.upcomingRequests;
				case "past":
					return this.pastRequests;
				default:
					return [];
			}
		},

		pushSubject() {
			return "groups/helper-created";
		},
	},

	watch: {
		listTab(_val) {
			this.onRequestDeselect(-1);
		},
	},

	created() {
		this.$store.commit("setAppLoadingStatus", true);

		Promise.all([this.fetchHelperRequests()]).finally(() => {
			this.$store.commit("setAppLoadingStatus", false);
		});

		// Set page width
		bus.$emit("page.content.width", "2000");

		const isMobileQuery = window.matchMedia("screen and (max-width: 720pt)");
		this.layout = isMobileQuery.matches ? "mobile" : "desktop";
		isMobileQuery.addEventListener("change", (ev) => {
			this.layout = ev.matches ? "mobile" : "desktop";
		});

		onUserLoaded((loggedIn, user) => {
			if (loggedIn) {
				this.user = user;
			}
		});
	},

	unmounted() {
		bus.$emit("page.content.width", null);
	},

	methods: {
		async reloadHelperRequests() {
			this.$store.commit("setAppLoadingStatus", true);
			try {
				await this.fetchHelperRequests();
			} finally {
				this.$store.commit("setAppLoadingStatus", false);
			}
		},

		async fetchHelperRequests() {
			const client = collection("helper_requests");
			this.requests = await client.getAll();
		},

		registerSelectionProvider(provider) {
			this.selectionProviders.add(provider);
		},

		onRequestSelect(key) {
			for (const provider of this.selectionProviders) {
				if (provider.selectionKey === key) provider.select();
				else provider.deselect();
			}
			const request = this.requests.find((x) => x.id == key);
			if (!request) {
				this.selectedRequest = null;
			}

			this.selectedRequest = request;
		},

		onRequestDeselect(key) {
			for (const provider of this.selectionProviders) {
				if (provider.selectionKey === key) provider.deselect();
			}
			this.selectedRequest = null;
		},

		async onHelpersAssign(requestId, data) {
			this.$store.commit("setAppLoadingStatus", true);
			const client = collection("helper_requests");

			try {
				await client.update(requestId, {
					confirmed: true,
					helpers_assigned: data.action === "reject" ? 0 : data.amount,
					assignment_comment: data.reason,
				});
				await this.reloadHelperRequests();
				this.$store.dispatch("alert", {
					type: "success",
					duration: 5,
					title: "Helfer zugeteilt",
					message: "Die beteiligte Stufe wird benachrichtigt.",
				});
			} catch (e) {
				this.$store.dispatch("httpError");
			} finally {
				this.$store.commit("setAppLoadingStatus", false);
				this.onRequestDeselect(requestId);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";

.panels {
	display: grid;
	gap: 20px;
	grid-template-columns: minmax(20em, 5fr) minmax(7em, 20em);
	grid-auto-rows: minmax(30em, 43em) max-content;
	width: 100%;

	.title {
		font-weight: 400;
		color: $blue;
		text-transform: uppercase;
	}

	.list {
		display: flex;
		flex-direction: column;

		.list-items {
			margin: 10px 0;
			overflow-y: auto;
			padding: 10px;
			flex: 1;
			scrollbar-width: thin;

			.list-item:not(:last-child) {
				margin-bottom: 15px;
			}
		}

		.tabs {
			margin: 0 10px;
			border: 1px solid #0002;
			border-radius: 10px;
			display: flex;
			overflow: hidden;
			input[type="radio"] {
				display: none;
			}
			input[type="radio"] + label {
				flex: 1;
				text-align: center;
				padding: 5px 10px;
				word-wrap: wrap;
				word-break: break-all;
				&:hover {
					cursor: pointer;
				}
			}
			input[type="radio"]:checked + label {
				background-color: $mint;
				color: white;
				font-weight: bold;
			}
		}

		& > button {
			margin: 10px 10px 0 10px;
		}
	}
}

.details-modal-base {
	display: none;
	position: fixed;
	inset: 0;
	background-color: #0003;
	z-index: 200;
	backdrop-filter: blur(5px);
	padding: $m-page-padding;
	animation-duration: 200ms;

	.details-modal {
		background-color: white;
		width: 100%;
		min-height: 50%;
		max-height: 100%;
		padding: $m-page-padding;
		border-radius: 10px;
		box-sizing: border-box;
		overflow: auto;
		position: relative;

		.close {
			width: 1em;
			height: 1em;
			line-height: 1em;
			box-sizing: content-box;
			font-size: 16pt;
			padding: 5px;
			position: fixed;
			top: 0;
			right: 0;
			margin: 35px;
		}
	}
}

@media screen and (max-width: $m-width) {
	.panels {
		width: 100%;
		display: block;
	}

	.details {
		display: none;
	}

	.details-modal-base {
		display: block;
	}
}

.titlebar {
	display: flex;
	align-items: center;
	gap: 10px;

	h1 {
		margin: 0;
	}
}
</style>
