<template>
	<div class="container">
		<div v-if="debug" class="debug">
			<h1>Users</h1>
			<text-box style="display: block">
				<h3>Server response</h3>
				<div class="response-test-container">{{ responseText }}</div>
			</text-box>
			Username: <input v-model="username" type="text" /> Email:
			<input v-model="email" type="text" /> Password: <input v-model="password" type="text" />
			<button @click="performAction">Execute query</button>
		</div>
		<div v-else class="prod">
			<h3>Users</h3>
			<div v-if="error">
				{{ response }}
			</div>
			<div v-else>
				<p v-for="(user, index) in response" :key="index">
					{{ `${index}. ${user.username} (${user.email}` }}
				</p>
			</div>
			<input v-model="username" type="text" placeholder="Enter username..." />
			<input v-model="email" type="email" placeholder="Enter email..." />
			<input v-model="password" type="password" placeholder="Enter password..." />
			<input type="submit" class="blue" @click="buttonSubmit" />
		</div>
	</div>
</template>
<script>
import TextBox from "../components/TextBox.vue";

export default {
	components: {
		TextBox,
	},
	data() {
		return {
			debug: false,
			response: null,
			responseText: null,
			error: false,
			username: null,
			email: null,
			password: null,
		};
	},
	created() {
		this.getUsers();
	},
	methods: {
		getUsers() {
			this.$http.get("http://api.ceviwie.ch/index.php/test_users").then(
				(response) => {
					this.responseText = response.body;
					this.response = response.body.content;
					console.log(response.body);
				},
				(response) => {
					console.log(response);
					this.error = true;
					this.responseText = "Error: " + JSON.stringify(response.body);
					this.response = "Oops! An error occurred!";
				}
			);
		},
		buttonSubmit() {},
	},
};
</script>
<style lang="scss" scoped>
@import "../styles/vars";

.response-test-container {
	background-color: $dark;
	color: #eee;
	font-family: Consolas;
	white-space: pre-wrap;
	padding: 10px;
	border-radius: 10px;
}
</style>
