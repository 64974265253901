<template>
	<div id="main">
		<div v-if="authenticated">
			<h1>Anschlag verschicken</h1>
			<p>
				Hier kannst Du den Anschlag an alle verschicken, die Deine Stufe abonniert haben, oder auch
				zusätzliche E-Mail Adressen eingeben (es wird dafür gesorgt, dass jeder nur eine E-Mail
				bekommt). Der Anschlag wird genau so gesendet, wie er auf deiner
				<router-link class="styled" to="feed">Stufen-Homepage</router-link> ist.
			</p>
			<br />
			<text-box class="textbox borderless">
				<form autocomplete="off" @submit.prevent="submitForm()">
					<h3>Empfänger</h3>
					<toggle-switch v-model="sendToSubscribers" :default-state="true">
						An alle Abonnierten schicken
						<i style="font-size: 0.8em">(empfohlen)</i>
					</toggle-switch>
					<hr style="border-color: #ddd" />
					<toggle-switch v-model="sendToAdditionalEmails" :default-state="false"
						>An zusätzliche E-Mail Adressen schicken</toggle-switch
					>
					<input-field-list
						v-model="additionalEmails"
						:disabled="!sendToAdditionalEmails"
					></input-field-list>
					<hr style="border-color: #ddd" />
					<!--
          <h3>Versender</h3>
          <form-field placeholder="Antwort-E-Mail" :validation="[]" v-model="replyTo" type="email"/>
          <p style="font-size: 13px; color: #888; line-height: 1.4em">
            <i>Antworten auf die Anschlags-E-Mails gehen an diese Antwort-E-Mail (Empfohlen. 
							Falls keine angegeben ist, wird eine Fussnote hinzugefügt, dass keine 
							Antwortenerhalten werden).</i>
          </p>
          <hr style="border-color: #ddd">
					-->
					<h3>Inhalt</h3>
					<span style="color: #888">Datum für Programm (für Betreff)</span>
					<br />
					<input v-model="date" type="date" class="styled" />
					<textarea
						v-model="footnote"
						class="textarea"
						placeholder="Fusszeile hinzufügen (Name, Grüsse, etc)"
						autocomplete="off"
					></textarea>
					<hr style="border-color: #ddd" />
					<button
						type="button"
						class="styled red mobile-fullwidth btn-send"
						:disabled="!group"
						@click="$refs.confirmSendPopup.open()"
					>
						E-Mail versenden
					</button>
				</form>
			</text-box>
			<popup ref="confirmSendPopup" title="Anschlags-Email" @confirm="sendNewsFeed()"
				>Anschlag versenden?</popup
			>
		</div>
		<div v-else>
			<no-permission title="Anschlag versenden" />
		</div>
	</div>
</template>

<script>
import InputFieldList from "@/components/InputFieldList";
import TextBox from "@/components/TextBox";
import ToggleSwitch from "@/components/ToggleSwitch";
import GroupAuth from "@/mixins/groupAuth";
import Popup from "@/views/Popup";
import url from "url";
import NoPermission from "./NoPermission";

export default {
	components: {
		TextBox,
		ToggleSwitch,
		InputFieldList,
		Popup,
		NoPermission,
	},
	mixins: [GroupAuth],

	data() {
		return {
			authenticated: false,
			additionalEmails: [null],
			sendToAdditionalEmails: false,
			sendToSubscribers: true,
			group: null,
			date: this.getNextSaturday(),
			footnote: "",
			replyTo: "",
		};
	},

	computed: {
		groupUrl() {
			return url.resolve(this.$store.getters.groupsUrl, this.$route.params.id);
		},
		dateString() {
			let date = new Date(this.date);
			let now = Date.now();
			if (date > now) {
				return ` für den ${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
			} else {
				return "";
			}
		},
	},

	created() {
		this.authenticate();
	},

	methods: {
		authenticate() {
			this.authenticateGroupUser().then((authenticated) => {
				this.authenticated = authenticated;
				this.replyTo = this.$store.state.user.email;
			});
			this.$http
				.get(this.groupUrl)
				.then((res) => {
					this.group = res.body;
					console.log("Set group to: " + this.group.name, this.group);
				})
				.catch((err) => {
					console.error(err);
					this.$store.dispatch("httpError");
				});
		},
		sendNewsFeed() {
			this.getSubscriberEmailAddresses()
				.then((emails) => {
					//Get subscriber emails if enabled
					let subscriberEmails = this.sendToSubscribers ? emails.slice() : [];
					//Get additional emails if enabled
					let additionalEmails = this.sendToAdditionalEmails ? this.additionalEmails.slice() : [];
					//Merge arrays and remove duplicates
					let allEmails = [...new Set(subscriberEmails.concat(additionalEmails))];
					let newsFeed = this.group.news_feed;
					//Send POST to server to send emails
					let endpoint = url.resolve(this.$config.apiConfig.apiRoot, "sendmail/anschlag");
					this.$http
						.post(endpoint, {
							addresses: allEmails,
							content: newsFeed + `<p style="white-space: pre-wrap">${this.footnote}</p>`,
							subject: `Anschlag ${this.group.name}${this.dateString}`,
							group_name: this.group.name,
							reply_to: this.replyTo,
						})
						.then((_res) => {
							this.$store.dispatch("alert", {
								title: "Anschlag versendet",
								message: "Dein Anschlag wurde an alle angegebenen Adressen gesendet.",
								type: "success",
								duration: 3,
							});
							this.$router.push("/stufen/" + this.$route.params.id + "/feed");
						})
						.catch((err) => {
							console.log(err);
							this.$store.dispatch("httpError");
						});
				})
				.catch((err) => {
					console.error(err);
					this.$store.dispatch("httpError");
				});
		},
		getSubscriberEmailAddresses() {
			return new Promise((resolve, reject) => {
				let subscribersEndpoint = url.resolve(this.groupUrl, "?select=subscribers");
				this.$http
					.get(subscribersEndpoint)
					.then((group) => {
						let subscriberIds = JSON.parse(group.body.subscribers);
						let userPromises = [];
						for (let subscriberId of subscriberIds) {
							let subscriberEndpoint = url.resolve(
								this.$store.getters.usersUrl,
								subscriberId.toString()
							);
							userPromises.push(this.$http.get(subscriberEndpoint));
						}
						Promise.all(userPromises)
							.then((users) => {
								let emails = users.map((x) => x.body.email);
								resolve(emails);
							})
							.catch((err) => {
								reject(err);
							});
					})
					.catch((err) => {
						reject(err);
					});
			});
		},
		getNextSaturday() {
			var d = new Date();
			d.setDate(d.getDate() + ((6 + 7 - d.getDay()) % 7));
			return d.toISOString().split("T")[0];
		},
		submitForm() {
			console.log("submitting form");
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

.textarea {
	background-color: #fff;
	font-family: $app-sans-font;
	font-size: 16px;
	height: unset;
	min-height: 120px;

	&::placeholder {
		color: #aaa;
	}
}

input[type="date"] {
	background-color: #fff;
	border-radius: $border-radius-small;
	outline: none;
	border: none;
	padding: 8px;
	margin-bottom: $d-el-padding;
	color: #555;
	font-size: 16px;
	width: 100%;
	box-sizing: border-box;
}
@media (min-width: $m-width) {
	.textbox {
		max-width: 600px;
		margin: auto;
		overflow: auto;
	}
	.btn-send {
		float: right;
	}
}
</style>
