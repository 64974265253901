<template>
	<div>
		<h1 class="textbox" style="margin-bottom: 30px">Matbestellung</h1>
		<text-box id="form" class="textbox borderless">
			<form @submit.prevent="sendMatOrder()" @keypress="onFormKeyPress">
				<div id="data">
					<div>
						<h3>Für</h3>
						<!-- <input type="text" placeholder="Stufe" class="styled white fullwidth" required
						v-model="group" /> -->
						<select v-model="group" class="styled white group-list" required>
							<option :value="null" selected disabled>Stufe</option>
							<option v-for="(currGroup, index) of groups" :key="index" :value="currGroup.id">
								{{ currGroup.name }}
							</option>
						</select>
						<input
							v-model="sender"
							type="text"
							placeholder="Name"
							class="styled white fullwidth"
							required
						/>
						<input
							v-model="email"
							type="email"
							placeholder="Email"
							class="styled white fullwidth"
							required
						/>
					</div>
					<div>
						<h3>Wann</h3>
						<span class="tag">Datum</span>
						<input v-model="date" type="date" class="styled white fullwidth" required />
						<span class="tag">Zeit</span>
						<input v-model="time" type="time" class="styled white fullwidth" />
					</div>
				</div>
				<hr style="border-color: #ddd; margin: 20px 0" />
				<h3>Mat</h3>
				<mat-order-list ref="matList" v-model="selectedItems" :categories="categories" />
				<hr style="border-color: #ddd; margin: 20px 0" />
				<textarea
					id="textarea"
					v-model="remark"
					class="textarea"
					placeholder="Bemerkungen, nicht aufgelistetes Mat"
				></textarea>
				<input
					type="submit"
					class="styled mobile-fullwidth"
					value="Matbestellung senden"
					:disabled="sending"
				/>
			</form>
		</text-box>
	</div>
</template>

<script>
import axios from "axios";
import url from "url";

import { matCategories, matItems } from "@/cms";
import MatOrderList from "@/components/MatOrderList";
import TextBox from "@/components/TextBox";

const othersGroup = {
	id: -1,
	name: "Andere",
	admins: [],
	members: [],
	subscribers: [],
	group_bio: "",
	news_feed: "",
	img_url: "",
	year: Number.POSITIVE_INFINITY,
	gender: "m",
	group_password: "",
};

export default {
	title: ({ shortAppend }) => shortAppend("Matbestellung"),
	components: {
		TextBox,
		MatOrderList,
	},

	data() {
		return {
			realGroups: [],
			categories: [],
			selectedItems: [],
			sender: null,
			group: null,
			email: null,
			date: this.getNextSaturday(),
			time: "13:00",
			remark: null,
			sending: false,
		};
	},

	computed: {
		groups() {
			return [...this.realGroups, othersGroup];
		},
	},

	mounted() {
		this.fetchGroups();
		this.fetchItems();
	},

	methods: {
		getOrder() {
			return this.selectedItems;
		},
		sendMatOrder() {
			// Disable the send button
			this.sending = true;

			// Create order object
			let order = {
				sender: this.sender,
				group: parseInt(this.group),
				email: this.email,
				date: this.date,
				time: this.time,
				remark: this.remark,
				items: this.selectedItems.map((x) => {
					return { id: parseInt(x.id), amount: x.amount };
				}),
			};

			//Send order to server
			let endpoint = url.resolve(this.$config.apiConfig.apiRoot, "sendmail/mat-order");
			this.$http
				.post(endpoint, order)
				.then(async (res) => {
					// Sent successfully, show message and go back
					console.log(res.body);
					this.$store.dispatch("alert", {
						title: "Erfolg",
						message: "Deine Mat-Bestellung wurde erfolgreich versendet.",
						type: "success",
					});
					this.$router.go(-1);
				})
				.catch((err) => {
					console.error(err);
					this.$store.dispatch("httpError");
				})
				.finally(() => {
					this.sending = false;
				});
		},
		getNextSaturday() {
			var d = new Date();
			d.setDate(d.getDate() + ((6 + 7 - d.getDay()) % 7));
			let year = d.getFullYear().toString().padStart(2, "0");
			let month = (d.getMonth() + 1).toString().padStart(2, "0");
			let day = d.getDate().toString().padStart(2, "0");
			return `${year}-${month}-${day}`;
		},
		onFormKeyPress(event) {
			if (event.keyCode == 13 && event.target != document.getElementById("textarea")) {
				event.preventDefault();
			}
		},
		async fetchGroups() {
			// Get groups
			let endpoint = this.$store.getters.groupsUrl;
			let queryParams = {
				select: ["name", "id"],
				order: ["year_DESC", "gender_ASC"],
			};
			try {
				this.realGroups = (
					await axios.get(endpoint, {
						params: queryParams,
					})
				).data;
				// Set options automatically if group user
				let user = this.$store.state.user;
				if (user != null && ["group_user", "helper_user"].includes(user.role)) {
					let admin_groups = JSON.parse(user.administered_groups);
					let admin_group = admin_groups[0];
					this.group = admin_group;
					this.email = user.email;
				}
			} catch (error) {
				console.error(error);
				this.$store.dispatch("httpError");
			}
		},
		async fetchItems() {
			try {
				// Goal: Get an array of category objects of format: { name: string, items: Object[] }
				// Fetch all items and categories, then create categories array for MatOrderList
				this.categories = await Promise.all([
					matItems.getAll({ sort: "display_name" }),
					matCategories.getAll({ sort: "display_name" }),
				])
					.then(([items, categories]) => [
						items.map((x) => ({
							id: x.id,
							name: x.display_name,
							category: x.mat_category,
							amount: 1,
							description: x.description,
						})),
						categories,
					])
					.then(([items, categories]) =>
						categories.map((cat) => ({
							name: cat.display_name,
							description: cat.description,
							items: items
								.filter((item) => item.category === cat.id)
								.map((item) => ({ ...item, category: cat.display_name })),
						}))
					);
			} catch (error) {
				console.error(error);
				this.$store.dispatch("httpError");
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

.tag {
	color: #888;
	font-size: 14px;
	display: block;
	margin-bottom: -7px;
	height: 32px;
}
.textarea {
	background-color: #fff;
	font-family: $app-sans-font;
	font-size: 16px;
	height: unset;
	min-height: 120px;

	&::placeholder {
		color: #aaa;
	}
}

.group-list {
	width: 100%;
	box-sizing: border-box;
}

@media (min-width: $m-width) {
	.textbox {
		max-width: 700px;
		margin: auto;
		overflow: auto;
		padding: 20px;
	}
	.btn-send {
		float: right;
	}

	#data {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: $d-el-padding * 2;
	}
}
</style>
