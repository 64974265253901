import { transforms } from "@/resources/newsParser/newsParserConfig";

function findTagEnd(input, tagStart) {
	// Find closing bracket
	for (let k = tagStart + 1; k < input.length; k++) {
		if (input.charAt(k) == "]") {
			return k;
		}
	}
}

function findBlockLimits(input, tagEnd) {
	let blockStart = -1;
	let blockEnd = -1;
	// Indicates the "nestedness level" - current element is at rank 0
	let rank = 0;
	// Look for open/close brace from tagEnd + 1 to string end
	for (let k = tagEnd + 1; k < input.length; k++) {
		let d = input.charAt(k);
		if (d === "{") {
			if (rank === 0) {
				// Open brace found
				blockStart = k;
			}
			rank += 1;
		} else if (d === "}") {
			rank -= 1;
			if (rank === 0) {
				// Close brace found. Don't look further
				blockEnd = k;
				break;
			}
		}
	}

	return [blockStart, blockEnd];
}

function getBlockInfo(input, tagStart, tagEnd, blockStart, blockEnd) {
	let tagString = input.substring(tagStart + 1, tagEnd);
	let tagParts = tagString.split("::");
	// Tag
	let tag = tagParts[0];
	// Args, separate args string by comma and trim. Empty array if no args
	let args = [];
	if (tagParts.length > 1) {
		args = tagParts[1].split(",").map((x) => x.trim());
	}
	// Extract block body
	let body = input.substring(blockStart + 1, blockEnd);

	return [tag, args, body];
}

function replaceRange(s, start, end, substitute) {
	return s.substring(0, start) + substitute + s.substring(end);
}
export default {
	methods: {
		async transformInput(input) {
			// Finds and replaces all tag blocks
			// Running time: O(n^2)
			// Loop over all characters in string
			for (let i = 0; i < input.length; i++) {
				const c = input.charAt(i);
				if (c == "[") {
					// Block started -> find ending bracket
					let tagStart = i;
					let tagEnd = findTagEnd(input, tagStart);
					if (tagEnd == -1) return "Error";
					// Find block limits
					let [blockStart, blockEnd] = findBlockLimits(input, tagEnd);
					if (blockStart == -1 || blockEnd == -1) return "Error";
					// Gather info from tag
					let [tag, args, body] = getBlockInfo(input, tagStart, tagEnd, blockStart, blockEnd);
					// Replace substring from tagStart to blockEnd (incl.) with transformed body
					let transform = transforms.find((x) => x.tag == tag);
					if (!transform) continue;
					let transformedBody = await transform.format(args, body, this);
					input = replaceRange(input, tagStart, blockEnd + 1, transformedBody);
				}
			}
			return input;
		},
	},
};
