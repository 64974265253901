<template>
	<div id="container">
		<h1>Anlässe</h1>
		<div v-if="events" id="event-list">
			<div v-for="(ceviEvent, index) of events" :key="index" class="event-widget mobile-borderless">
				<img :src="getImagePath(ceviEvent.path)" :alt="ceviEvent.title" />
				<router-link :to="ceviEvent.path" class="nostyle event-overlay">
					<span class="event-name">{{ ceviEvent.title }}</span>
				</router-link>
			</div>
		</div>
		<div v-else>
			<loading-spinner info-text="Bitte warten..." theme="mint" />
		</div>
	</div>
</template>
<script>
import LoadingSpinner from "../../components/LoadingSpinner";
import anlaesse from "@/resources/anlaesse.json";

export default {
	title: ({ shortAppend }) => shortAppend("Anlässe"),
	components: {
		LoadingSpinner,
	},
	data() {
		return {
			events: anlaesse,
		};
	},
	methods: {
		getImagePath(route) {
			return require("../../assets/img/header/low-res" + route + ".jpg");
		},
	},
};
</script>
<style lang="scss" scoped>
@import "../../styles/vars";

div#event-list {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 5px;

	div.event-widget {
		position: relative;
		height: 120px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: linear-gradient(
				217deg,
				lighten(desaturate(rgba(255, 30, 50, 0.8), 50), 30),
				rgba(255, 0, 0, 0) 70.71%
			),
			linear-gradient(
				127deg,
				lighten(desaturate(rgba(0, 255, 120, 0.8), 50), 30),
				rgba(0, 255, 0, 0) 70.71%
			),
			linear-gradient(336deg, lighten(rgba(60, 40, 255, 0.8), 15), rgba(0, 0, 255, 0) 70.71%);

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}

		.event-overlay {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 100%;
			color: white;
			display: flex;
			flex-direction: column;
			justify-content: center;
			transition: all $fade-time;

			&:hover {
				background-color: #fff6;
			}

			span {
				display: block;
				text-align: center;
				padding: 5px;
				text-shadow: 0 0 15px #000c;

				&.event-name {
					font-size: 36px;
					font-weight: bold;
				}
			}
		}

		&:hover {
			cursor: pointer;
		}
	}
}

@media (min-width: $m-width) {
	div#event-list {
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 10px;

		div.event-widget {
			height: 150px;
			grid-column: span 2;
			&:last-of-type {
				grid-column: 2 / span 2;
			}
		}
	}
}
</style>
