<template>
	<div>
		<div v-if="accessAllowed">
			<router-view />
		</div>
		<div v-else>
			<h1>Admin-Panel</h1>
			<p>Oops! Sieht so aus, als seist du nicht berechtigt, auf diese Seite zuzugreifen.</p>
			<router-link to="/home">Zurück zur Startseite</router-link>
		</div>
	</div>
</template>

<script>
import { bus } from "@/bus";

export default {
	data() {
		return {
			accessAllowed: false,
		};
	},
	created() {
		if (this.$store.state.user) {
			this.accessAllowed = this.$store.state.user.role == "global_admin";
		}
		bus.$on("loadedUser", (success, user) => {
			this.accessAllowed = user.role == "global_admin";
		});
	},
};
</script>

<style scoped></style>
