<template>
	<div style="overflow: visible">
		<div v-if="rowCount <= 0">
			<span style="color: #888; font-style: italic"
				>Keine Artikel ausgewählt. Klicke auf "+" um hinzuzufügen.</span
			>
		</div>
		<div v-for="index in rowCount" :key="index" class="list-item-container">
			<select
				v-model="rows[index - 1].selectedCategory"
				class="styled white mat-category"
				@change.prevent="onCategorySelected(index)"
			>
				<option :value="null" disabled selected>Kategorie</option>
				<option
					v-for="(category, categoryIndex) of categories"
					:key="categoryIndex"
					:value="category"
				>
					{{ category.name }}
				</option>
			</select>
			<select
				v-model="rows[index - 1].selectedItem"
				class="styled white mat-item"
				:disabled="!rows[index - 1].selectedCategory"
				@change.prevent="onItemSelected(index)"
			>
				<option :value="null" disabled selected>Artikel</option>
				<option
					v-for="item of rows[index - 1].selectedCategory
						? rows[index - 1].selectedCategory.items
						: []"
					:key="item.id"
					:value="item"
				>
					{{ item.name }}
				</option>
			</select>
			<input
				type="number"
				class="styled white mat-amount"
				min="0"
				placeholder="Anzahl"
				:disabled="!rows[index - 1].selectedItem"
				@keypress.enter="addRow()"
				@input.prevent="setItemAmount($event, index)"
			/>
			<div class="buttons">
				<button v-if="hasDescription(index - 1)" type="button" class="styled blue info-button">
					<i class="fas fa-info-circle"></i>
					<div class="description-box">
						<div v-if="categoryDescription(index - 1)" class="desc">
							<h4>
								<i class="fas fa-info-circle fa-sm"></i> {{ categoryDescription(index - 1).title }}
							</h4>
							<p>{{ categoryDescription(index - 1).desc }}</p>
						</div>
						<div v-if="itemDescription(index - 1)" class="desc">
							<h4>
								<i class="fas fa-info-circle fa-sm"></i> {{ itemDescription(index - 1).title }}
							</h4>
							<p>{{ itemDescription(index - 1).desc }}</p>
						</div>
					</div>
				</button>
				<button type="button" class="styled red remove-button" @click="removeRow(index)">
					<i class="far fa-trash-alt square"></i>
				</button>
			</div>
		</div>
		<button type="button" class="styled mint add-button" style="float: right" @click="addRow()">
			<i class="fas fa-plus square"></i>
		</button>
	</div>
</template>

<script>
export default {
	props: {
		value: { type: Array, default: null },
		categories: { type: Array, default: null },
	},

	data() {
		return {
			rowCount: 0,
			rows: [],
		};
	},

	created() {
		this.addRow();
	},

	methods: {
		getRow(index) {
			return this.rows[index - 1];
		},
		addRow() {
			this.rows.push({
				selectedCategory: null,
				selectedItem: null,
			});
			this.rowCount++;
		},
		removeRow(index) {
			this.rows.splice(index - 1, 1);
			this.rowCount--;
		},
		getSelectedItems() {
			let items = this.rows.map((x) => x.selectedItem).filter((x) => x);
			return items;
		},
		onItemSelected(_index) {
			this.$emit(
				"input",
				this.rows.map((x) => x.selectedItem)
			);
		},
		onCategorySelected(index) {
			this.rows[index - 1].selectedItem = null;
		},
		setItemAmount(event, index) {
			let item = this.rows[index - 1].selectedItem;
			if (item) {
				item.amount = parseInt(event.target.value);
			}
		},
		hasDescription(index) {
			let category = this.rows[index].selectedCategory;
			let item = this.rows[index].selectedItem;
			return category?.description != null || item?.description != null;
		},
		categoryDescription(index) {
			let category = this.rows[index].selectedCategory;
			if (category?.description == null) return null;
			return { title: category.name, desc: category.description };
		},
		itemDescription(index) {
			let item = this.rows[index].selectedItem;
			if (item?.description == null) return null;
			return { title: item.name, desc: item.description };
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

select,
option {
	font-size: 14px;
}

.list-item-container {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	align-items: center;

	& > * {
		flex: 1 1 100%;
		margin-right: 10px;

		&:last-child {
			margin-right: 0;
		}
	}

	.buttons {
		max-width: max-content;

		& > button:not(:last-child) {
			margin-right: 10px;
		}
	}

	.mat-amount {
		flex: 0;
		width: 80px;
		margin-right: 10px;
	}
}

.info-button {
	position: relative;

	.description-box {
		display: none;
		opacity: 0;
		position: absolute;
		bottom: calc(100% + 10px);
		right: 0;
		background: $white;
		border: 0.5px solid #0002;
		box-shadow: 2px 4px 15px #0002;
		border-radius: $border-radius-small;
		padding: 15px;
		width: 300px;
		transition: all 250ms;

		.desc h4 {
			text-align: left;
			margin: 0;
			margin-bottom: 7px;
		}

		.desc p {
			font-weight: normal;
			line-height: 1.2rem;
			text-align: left;
			margin: 0;
		}

		.desc:not(:last-of-type) {
			margin-bottom: 15px;
		}
	}

	&:hover .description-box {
		display: block;
		opacity: 1;
	}
}

@media (max-width: $m-width) {
	.list-item-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 7px;
		width: 100%;
		margin-bottom: 20px;
		overflow-y: visible;

		& > * {
			margin: 0 !important;
			width: 100% !important;
		}

		.mat-amount {
			box-sizing: border-box;
		}

		.buttons .description-box {
			right: -130px;
		}
	}
	.add-button {
		width: 100%;
	}
}
</style>
