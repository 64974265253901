<template>
	<div>
		<h1>Passwort ändern</h1>
		<form class="form-box mobile-borderless" @submit.prevent="changePassword()">
			<h4>Benutzername</h4>
			<form-field v-model="username" type="text" disabled name="username" />
			<h4>Altes Passwort</h4>
			<form-field ref="oldPw" v-model="oldPassword" type="password" required></form-field>
			<h4>Neues Passwort eingeben</h4>
			<form-field
				ref="newPw"
				v-model="newPassword"
				type="password"
				:validation="passwordValidation"
				required
			></form-field>
			<h4>Neues Passwort wiederholen</h4>
			<form-field
				ref="newPwRepeat"
				v-model="newPasswordRepeat"
				type="password"
				:validation="passwordRepeatValidation"
				required
			></form-field>
			<input type="submit" value="Passwort ändern" class="styled" :disabled="!validates" />
		</form>
	</div>
</template>

<script>
import FormField from "@/components/FormField";
import Auth from "@/mixins/auth";

export default {
	components: {
		FormField,
	},
	mixins: [Auth],

	data() {
		return {
			mounted: false,
			oldPassword: null,
			newPassword: null,
			newPasswordRepeat: null,
			passwordValidation: [
				{
					func: (x) => x.length >= 4,
					error: "Muss mindestens 4 Zeichen lang sein",
				},
				{
					func: (x) => x.length <= 32,
					error: "Kann maximal 32 Zeichen lang sein",
				},
				{
					func: (x) => !x.includes(" "),
					error: "Kann keine Leerzeichen enthalten",
				},
			],
			passwordRepeatValidation: [
				{
					func: (x) => x == this.newPassword,
					error: "Passwörter stimmen nicht ueberein",
				},
			],
		};
	},

	computed: {
		validates() {
			return (
				this.mounted &&
				this.$refs.oldPw.validates &&
				this.$refs.newPw.validates &&
				this.$refs.newPwRepeat.validates
			);
		},
		username() {
			return this.$store.getters.sessionUser;
		},
	},

	mounted() {
		this.mounted = true;
	},

	methods: {
		changePassword() {
			if (!this.validates) {
				this.$store.dispatch("alert", {
					type: "warning",
					message:
						"Etwas stimmt noch nicht ganz. Schaue Dir Deine Eingaben nochmals an und versuche es " +
						"nochmals.",
				});
				return;
			}
			let hashedCurrentPassword = this.hash(this.oldPassword);
			if (hashedCurrentPassword != this.$store.state.user.password) {
				this.$store.dispatch("alert", {
					type: "error",
					message: "Falsches Passwort",
				});
				return;
			}
			let hashedNewPassword = this.hash(this.newPassword);
			this.$http
				.put(this.$store.getters.usersUrl + "/" + this.$store.state.user.id, {
					password: hashedNewPassword,
				})
				.then((_res) => {
					this.$store.dispatch("alert", {
						type: "success",
						message: "Passwort wurde erfolgreich geändert",
					});
					this.$router.go(-1);
					this.$store.dispatch("updateUser");
				})
				.catch((err) => {
					this.$store.dispatch("httpError");
					console.log(err);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";
h4 {
	margin: 10px 0;
}
.form-box {
	display: block;
	background-color: #0001;
	padding: 20px;
	box-sizing: border-box;
}

@media (min-width: $m-width) {
	.form-box {
		border-radius: $border-radius-large;
		max-width: 700px;
	}
}
</style>
