<template>
	<div>
		<div v-if="group">
			<h1>{{ group.name }}</h1>
			<group-admin-toolbar v-if="authenticated" />
			<article id="group-feed">
				<h2>Anschlag</h2>
				<div v-if="group.news_feed" v-html="group.news_feed"></div>
				<div v-else>Diese Stufe hat momentan keinen Anschlag.</div>
			</article>
			<article id="group-bio">
				<h2>Stufen-Bio</h2>
				<p v-if="group.group_bio.length > 0" v-html="group.group_bio"></p>
				<p v-else>
					<i>Diese Stufe hat noch keine Stufen-Bio.</i>
				</p>
			</article>
		</div>
		<div v-else>
			<h1>Stufen Home</h1>
			<loading-spinner />
		</div>
	</div>
</template>

<script>
import url from "url";
import { bus } from "../../bus.js";
import LoadingSpinner from "../../components/LoadingSpinner";
import GroupAdminToolbar from "../../views/GroupAdminToolbar";
import GroupAuth from "@/mixins/groupAuth";

export default {
	title: ({ shortAppend }) => shortAppend("Stufenseite"),
	components: {
		LoadingSpinner,
		GroupAdminToolbar,
	},
	mixins: [GroupAuth],

	data() {
		return {
			authenticated: false,
			group: null,
		};
	},

	created() {
		this.onCreated();
		this.authenticateGroupUser().then((authenticated) => {
			this.authenticated = authenticated;
		});
	},

	destroyed() {
		this.onDestroyed();
	},

	methods: {
		onCreated() {
			let groupId = this.$route.params.id;
			let endpoint = url.resolve(this.$store.getters.groupsUrl, groupId);
			this.$http
				.get(endpoint)
				.then((response) => {
					this.group = response.body;
					this.setDocumentTitle();
					//Set header img to group img
					bus.$emit("override.header-image.img-src", this.group.img_url);
					if (window.matchMedia("(max-width: 720pt").matches) {
						bus.$emit("override.header-image.img-width", 130);
					}
				})
				.catch((err) => {
					console.log(err);
				});
			this.$on("onLogin", (_user) => {
				this.$forceUpdate();
			});
		},
		setDocumentTitle() {
			let mode = process.env.NODE_ENV;
			if (mode == "production") {
				document.title = `Cevi WIE - Stufe ${this.group.name}`;
			} else {
				let subst = { development: "Dev", staging: "Stag" };
				let modeStr = mode;
				if (mode in subst) modeStr = subst[mode];
				document.title = `[${modeStr}] Cevi WIE - Stufe ${this.group.name}`;
			}
		},
		onDestroyed() {
			bus.$emit("override.header-image.img-src", null);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

#group-feed,
#group-bio {
	width: 100%;
	overflow: hidden;

	::v-deep a {
		text-decoration: none;
		color: $red;
		text-decoration: none;
		transition: all 50ms;
	}
}
</style>
