import config from "@/config/config";

const CACHE_INTERVAL = config.cache.period * 1000;
const SEPARATOR = "//**//";

console.log("Cache interval set to", CACHE_INTERVAL, "milliseconds");

export default {
	storeJsonObject(key, object) {
		const stringVal = JSON.stringify(object);
		this.store(key, stringVal);
	},

	getJsonObject(key) {
		const stringVal = this.get(key);
		if (stringVal.valid) {
			return { valid: true, data: JSON.parse(stringVal.data) };
		}

		return { valid: false };
	},

	store(key, data) {
		if (typeof key !== "string" && typeof data !== "string") {
			throw new Error("Invalid type for argument: key/data");
		}
		const finalData = `${data}${SEPARATOR}${Date.now()}`;
		try {
			localStorage.setItem(key, finalData);
		} catch (exception) {
			// Cache full
			return false;
		}
		return true;
	},

	get(key) {
		if (typeof key !== "string") {
			throw new Error("Invalid type for argument: key");
		}
		const value = localStorage.getItem(key);
		if (!value) {
			return { valid: false };
		}
		let [data, timestamp] = value.split(SEPARATOR);
		timestamp = Number(timestamp);
		const date = new Date(timestamp);
		if (
			Number.isNaN(timestamp) ||
			date == "Invalid Date" ||
			Date.now() - date.getTime() > CACHE_INTERVAL
		) {
			return { valid: false };
		}
		return { valid: true, data: data };
	},

	remove(key) {
		localStorage.removeItem(key);
	},
};
