import md5 from "md5";
import sha256 from "js-sha256";

export default {
	data() {
		return {
			users: null,
		};
	},
	methods: {
		hash(arg, method = "md5") {
			switch (method) {
				case "md5":
					return md5(arg);
				case "sha256":
					return sha256(arg);
			}
		},
		hashDynamic(arg, method = "md5") {
			let pepperedArg = process.env.VUE_APP_AUTH_PEPPER + arg + Date.now();
			return this.hash(pepperedArg, method);
		},
		registerUser(data, onSuccess) {
			var loginHash = this.hashDynamic(data.username);
			var user = {
				username: data.username,
				email: data.email,
				password: this.hash(data.password),
				email_notifications: data.email_notifications ? 1 : 0,
				login_hash: data.stayLoggedIn ? loginHash : null,
				role: data.role,
				subscribed_groups: JSON.stringify(data.subscribed_groups),
			};
			let url = this.$store.getters.usersUrl;
			this.$http
				.post(url, user)
				.then(() => {
					this.$cookie.set("session_user", user.username, {
						expires: data.stayLoggedIn ? "31D" : "1h",
					});
					this.$cookie.set("login_hash", loginHash, {
						expires: data.stayLoggedIn ? "31D" : "1h",
					});
					this.$store.commit("setSession", user);
					onSuccess(user);
				})
				.catch((err) => {
					console.error(err);
					this.alertInternalError();
				});
		},
		loginUser(data, onSuccess, onError) {
			var key = "username";
			if (data.identifier.includes("@")) {
				key = "email";
			}
			let url = this.$store.getters.usersUrl + `/${data.identifier}/?key=${key}`;
			this.$http
				.get(url)
				.then((response) => {
					let user = response.body;
					//Error handling
					if (!user) {
						onError("identifier");
						return null;
					}
					if (this.hash(data.password) != user.password) {
						onError("password");
						return null;
					}
					//Login
					if (!user.login_hash) {
						//Generate new hash if not in database
						user.login_hash = this.hashDynamic(user.username);
						this.$http
							.put(this.$store.getters.usersUrl + "/" + user.id, {
								login_hash: user.login_hash,
							})
							.then(
								() => {},
								() => this.alertInternalError()
							);
					}
					this.$cookie.set("session_user", user.username, {
						expires: data.stayLoggedIn ? "31D" : "1h",
					});
					this.$cookie.set("login_hash", user.login_hash, {
						expires: data.stayLoggedIn ? "31D" : "1h",
					});
					this.$store.commit("setSession", user);
					onSuccess(user);
				})
				.catch((err) => {
					console.error(err);
					this.alertInternalError();
				});
		},
		logoutUser(user, onSuccess, fromAllDevices = false) {
			//Log out locally
			this.$cookie.delete("login_hash");
			this.$cookie.delete("session_user");
			this.$store.commit("unsetSession");
			//Log out on server
			if (fromAllDevices) {
				this.$http.put(this.$store.getters.usersUrl + "/" + user.id, { login_hash: null }).then(
					() => {
						onSuccess("logout_all");
					},
					() => {
						this.alertInternalError();
					}
				);
			} else {
				onSuccess("logout");
			}
		},
		alertInternalError() {
			this.$store.dispatch("alert", {
				title: "Fehler",
				message: "Es ist ein fehler im System aufgetreten. Bitte den Administrator kontaktieren.",
				type: "error",
			});
		},
	},
};
