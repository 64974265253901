<template>
	<div>
		<label class="container">
			<span class="desc">
				<slot />
			</span>
			<input
				ref="input"
				v-model="m_checked"
				type="checkbox"
				:checked="checked"
				@input="handleInput"
			/>
			<span :class="'checkmark ' + color + ' ' + background + ' ' + backgroundHover"></span>
		</label>
	</div>
</template>

<script>
export default {
	props: {
		color: { type: String, default: "mint" },
		background: { type: String, default: "" },
		backgroundHover: { type: String, default: "" },
		checked: { type: Boolean, default: false },
		value: { type: Boolean, default: false },
	},
	data() {
		return {
			m_checked: this.checked,
		};
	},
	watch: {
		checked: function () {
			this.m_checked = this.checked;
		},
		value: function () {
			this.m_checked = this.value;
		},
	},
	created() {
		this.$emit("input", this.checked);
	},
	methods: {
		handleInput(e) {
			this.$emit("input", e.target.checked);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";
/* The container */
.container {
	display: block;
	position: relative;
	padding-left: 35px;
	line-height: 22px;
	margin-bottom: 12px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	transition: all $fade-time;

	.desc {
		padding-top: 3px;
		display: block;
	}
}

/* Hide the browser's default checkbox */
.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	transition: all $fade-time;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
	transition: all $fade-time;
	border-radius: $border-radius-small;

	&.white {
		background-color: #fff;
	}
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
	background-color: #ccc;

	&.light {
		background-color: #f7f7f7;
	}
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
	background-color: $red;

	&.light {
		background-color: $light;
	}
	&.red-vibrant {
		background-color: $red-vibrant;
	}
	&.mint {
		background-color: $mint;
	}
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	opacity: 0;
	transition: all $fade-time;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
	opacity: 1;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
</style>
