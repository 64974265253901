<template>
	<div class="base">
		<h1>Deine Helfer-Anfragen</h1>

		<div class="titlebar">
			<h2 style="flex: 1">Stufe: {{ groupName }}</h2>
			<subscription-buttons
				:subject="pushSubject"
				:user-id="user ? user.id : null"
			></subscription-buttons>
		</div>

		<div class="request-button">
			<router-link to="/helfer/anfrage" class="button styled">Helfer anfragen</router-link>
		</div>

		<h2>Laufende Anfragen</h2>

		<div v-if="runningRequests.length > 0" class="request-cards">
			<helper-request-card v-for="request in runningRequests" :key="request.id" :request="request">
			</helper-request-card>
		</div>
		<div v-else class="request-cards">Im Moment habt ihr keine laufenden Anfragen.</div>

		<h2>Vergangene Anfragen</h2>

		<div class="request-cards past">
			<helper-request-card v-for="request in pastRequests" :key="request.id" :request="request">
			</helper-request-card>
		</div>
	</div>
</template>

<script>
import HelperRequestCard from "@/components/Helfer/HelperRequestCard.vue";
import { collection } from "@/cms/directus";
import { onUserLoaded } from "@/bus.js";
import SubscriptionButtons from "@/components/SubscriptionButtons.vue";

export default {
	name: "HelpersOverview",

	components: {
		HelperRequestCard,
		SubscriptionButtons,
	},

	data() {
		return {
			user: null,
			groups: [],
			helperRequests: [],
		};
	},

	computed: {
		runningRequests() {
			return (this.helperRequests || []).filter((x) => this.directusDateIsInFuture(x.datetime_to));
		},

		pastRequests() {
			return (this.helperRequests || []).filter((x) => !this.directusDateIsInFuture(x.datetime_to));
		},

		groupName() {
			if (this.groups.length == 0) return "Unbekannt";
			return this.groups[0].name;
		},

		pushSubject() {
			if (this.groups.length == 0) return null;
			// TODO: Handle multiple managed groups
			if (this.groups.length > 1) return "groups/all/helper-updates";
			return `groups/${this.groups[0].id}/helper-updates`;
		},
	},

	created() {
		this.$store.commit("setAppLoadingStatus", true);
		this.$store.commit("setAppLoadingText", "Anfragen werden geladen...");
		// sleep(500).then(() => {
		// 	const user = this.$store.state.user;
		// 	const loggedIn = this.$store.state.loggedIn;
		// 	this.onLoad(loggedIn, user);
		// });

		onUserLoaded((loggedIn, user) => {
			if (loggedIn) {
				this.onLoggedIn(user);
			} else {
				this.onLoggedOut();
			}
		});
	},

	methods: {
		async onLoggedIn(user) {
			this.user = user;
			const administeredGroups = JSON.parse(user.administered_groups);
			const requestsPromise = this.fetchHelperRequests(administeredGroups);
			const groupsPromise = this.fetchGroups(administeredGroups);

			await Promise.all([requestsPromise, groupsPromise]);
			this.$store.commit("setAppLoadingStatus", false);
		},

		async onLoggedOut() {
			this.$store.commit("setAppLoadingStatus", false);
			this.$store.dispatch("alert", {
				type: "warn",
				title: "Kein Zugriff",
				message: "Du musst eingeloggt sein, um auf diese Seite zuzugreifen",
			});
			this.$router.push({ name: "login" });
		},

		async fetchHelperRequests(administeredGroups) {
			const client = collection("helper_requests");
			if (administeredGroups.includes("*")) {
				// Fetch all
				this.helperRequests = await client.getAll();
			} else {
				this.helperRequests = await client.getAll({
					"filter[group_id][in]": administeredGroups.join(","),
				});
			}
		},

		async fetchGroups(administeredGroups) {
			const urlBase = this.$store.getters.groupsUrl;
			const params = { select: "id,name" };
			if (administeredGroups.includes("*")) {
				this.groups = await this.$http.get(urlBase, { params }).then((x) => x.data);
			} else {
				this.groups = await Promise.all(
					administeredGroups.map((id) =>
						this.$http.get(`${urlBase}/${id}`, { params }).then((x) => x.data)
					)
				);
			}
		},

		directusDateIsInFuture(directusDate) {
			const date = new Date(directusDate);
			const now = new Date();
			return date > now;
		},
	},
};
</script>

<style lang="scss" scoped>
.base {
	position: relative;
}
.request-button {
	width: 100%;

	a {
		width: 100%;
	}
}

.request-cards {
	position: relative;

	&.past {
		opacity: 0.6;
	}
}

.titlebar {
	display: flex;
	align-items: center;
	gap: 10px;
}
</style>
