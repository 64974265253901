<template>
	<div class="switch-container" :disabled="!enabled">
		<label class="switch">
			<input
				ref="switch"
				name="switch"
				type="checkbox"
				:value="state"
				:checked="state"
				:disabled="!enabled"
				@input="toggleState"
			/>
			<span :class="['slider', colorClass]"></span>
		</label>
		<span v-if="showLabel" class="label" @click="toggleState"><slot /></span>
	</div>
</template>

<script>
export default {
	props: {
		defaultState: { type: Boolean, default: false },
		showLabel: { type: Boolean, default: true },
		enabled: { type: Boolean, default: true },
		colorClass: { type: String, default: "" },
	},
	data() {
		return {
			state: false,
		};
	},
	created() {
		this.state = this.defaultState;
		this.$emit("input", this.state);
	},
	methods: {
		toggleState() {
			this.state = !this.state;
			this.$emit("input", this.state);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/vars";

.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 28px;

	input {
		display: none;

		&:checked {
			& + .slider {
				background-color: $red;

				&.blue {
					background-color: $blue;
				}
				&.vibrant {
					background-color: $red-vibrant;
				}

				&::before {
					transform: translateX(22px);
				}
			}
		}
		&:focus + .slider {
			box-shadow: 0 0 1px $red;

			&.blue {
				box-shadow: 0 0 1px $blue;
			}
			&.vibrant {
				box-shadow: 0 0 1px $red-vibrant;
			}
		}
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		transition: $fade-time;
		border-radius: $border-radius-small;

		&:before {
			position: absolute;
			content: "";
			height: 22px;
			width: 22px;
			left: 3px;
			bottom: 3px;
			background-color: white;
			transition: $fade-time;
			border-radius: $border-radius-small;
		}
	}
}

.label {
	display: inline;
	&:hover {
		cursor: pointer;
		user-select: none;
	}
}

.switch-container {
	display: grid;
	grid-template-columns: min-content auto;
	align-items: center;
	text-align: left;
	margin-bottom: 15px;
	transition: all $fade-time;

	& > span {
		padding-left: 20px;
	}

	&[disabled] {
		opacity: 0.5;
	}
}
</style>
