<template>
	<div>
		<h1>Cevi-News</h1>
		<p>
			Die Cevi-News ist unsere Vereins-Zeitung, welche einmal jährlich – ca. ein halbes Jahr nach
			dem Jahresbericht - erscheint.
		</p>
		<h3>Anschrift für Artikel:</h3>
		<a href="mailto:oeffentlichkeit@ceviwie.ch" class="styled">Florian Baumann v/o Pi</a>
		<h3>Bisherige Ausgaben:</h3>
		<div v-if="editions" class="editions-container">
			<div v-for="(edition, index) of editions" :key="index" class="edition">
				<a :href="getDocumentUrl(edition)" target="_blank">
					<img :src="getThumbnailSrc(edition)" :alt="edition.title" />
				</a>
				<div class="edition-info">
					<a :href="edition.document_url" target="_blank" class="edition-title">{{
						edition.title
					}}</a>
					<div class="edition-about">
						{{ edition.release_year }}
						{{ edition.edition_nr ? ", Ausgabe " + edition.edition_nr : "" }}
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<p v-if="loading">
				<i>Cevi-News werden geladen...</i>
			</p>
			<p v-else>
				<i>Es gibt momentan leider keine Ausgaben der Cevi-News...</i>
			</p>
		</div>
	</div>
</template>
<script>
import { ceviNews } from "@/cms";

export default {
	title: ({ shortAppend }) => shortAppend("Cevi-News"),
	data() {
		return {
			editions: null,
			files: null,
			loading: true,
		};
	},

	created() {
		this.fetchData();
	},

	methods: {
		async fetchData() {
			this.loading = true;
			const { items, files } = await ceviNews.getAll({ sort: "-release_year,-edition_nr" }, [
				"thumbnail",
				"document",
			]);
			this.editions = items;
			this.files = files;
			this.loading = false;
		},
		getDocumentUrl(edition) {
			return this.files[edition.document].data.full_url;
		},
		getThumbnailSrc(edition) {
			return this.files[edition.thumbnail].data.full_url;
		},
	},
};
</script>
<style lang="scss" scoped>
@import "src/styles/vars";

.editions-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
	.edition {
		img {
			width: 100%;
			box-shadow: 0 0 15px #0002;
		}

		.edition-info {
			.edition-title {
				font-size: 20px;
				font-weight: bold;
				color: $blue;
			}

			.edition-about {
				color: #0005;
			}
		}
	}
}

@media (min-width: $m-width) {
	.editions-container {
		grid-template-columns: repeat(5, 1fr);
		grid-gap: 40px;
	}
}
</style>
