import { render, staticRenderFns } from "./ZoBackOrder.vue?vue&type=template&id=23d9b76a&scoped=true"
import script from "./ZoBackOrder.vue?vue&type=script&lang=js"
export * from "./ZoBackOrder.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d9b76a",
  null
  
)

export default component.exports