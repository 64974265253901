<template>
	<div>
		<settings-view title="Account">
			<div v-if="loggedIn" class="settings-list">
				<div class="card">
					Benutzername: <span class="value">{{ user.username }}</span>
				</div>
				<div class="card">
					Email: <span class="value">{{ user.email }}</span>
				</div>
				<div class="card">
					Rolle: <span class="value">{{ getRoleString(user.role) }}</span>
				</div>
				<div v-if="adminGroups && adminGroups.length > 0" class="card">
					Stufen-Admin:
					<span class="value">{{ adminGroups.map((x) => x.name).join(", ") }}</span>
				</div>
				<div>
					<toggle-switch
						v-model="user.email_notifications"
						name="email-notifications"
						:default-state="user.email_notifications == 1"
						color-class="vibrant"
						@input="setEmailNotification"
						>Email-Benachrichtigungen erhalten</toggle-switch
					>
				</div>
				<div>
					<router-link class="styled button red" to="change-password">Passwort ändern</router-link>
				</div>
			</div>
		</settings-view>
	</div>
</template>

<script>
import { bus } from "@/bus";

import SettingsView from "@/views/Settings";
import ToggleSwitch from "@/components/ToggleSwitch";

export default {
	components: {
		SettingsView,
		ToggleSwitch,
	},

	data() {
		return {
			initialized: false,
			adminGroups: null,
		};
	},

	computed: {
		loggedIn() {
			return this.$store.state.user != null;
		},
		user() {
			return this.$store.state.user;
		},
	},

	created() {
		if (this.$store.state.user) {
			this.loadAdminGroups();
		} else {
			bus.$on("onLogin", (_user) => {
				this.loadAdminGroups();
			});
		}
	},

	methods: {
		getRoleString(role) {
			let dictionary = {
				standard_user: "Standard-Account",
				group_user: "Stufen-Account",
				helper_user: "Helferstufen-Account",
				global_admin: "Super-Admin",
				page_admin: "Seiten-Admin",
			};

			return dictionary[role];
		},
		setEmailNotification(_enabled) {
			if (!this.initialized) {
				this.initialized = true;
				return;
			}
			if (this.user == null || this.loggedIn == false) {
				return;
			}
			this.$http
				.put(this.$store.getters.usersUrl + "/" + this.user.id, {
					email_notifications: this.user.email_notifications,
				})
				.then((_res) => {
					this.$store.dispatch("alert", {
						type: "success",
						message: "Einstellungen für Email-Benachrichtigungen gespeichert",
					});
				})
				.catch((err) => {
					this.$store.dispatch("httpError");
					console.log(err);
				});
		},
		loadAdminGroups() {
			//Get first 2 administered groups
			let adminGroupIds = JSON.parse(this.$store.state.user.administered_groups)
				.slice(0, 3)
				.filter((x) => !isNaN(x))
				.map((x) => parseInt(x));
			let promises = [];
			for (let id of adminGroupIds) {
				promises.push(
					this.$http.get(this.$store.getters.groupsUrl + "/" + id + "?select[]=name&select[]=id")
				);
			}
			Promise.all(promises).then((response) => {
				this.adminGroups = response.map((x) => x.body);
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";
.settings-list {
	.card {
		display: block;
		margin: 15px 0;
		padding: 10px;
		border-radius: $border-radius-large;
		box-shadow: 0 0 15px #0001;

		.value {
			color: $mint;
			font-weight: bold;
			float: right;
		}
	}
}

@media (min-width: $m-width) {
	.settings-list .card {
		max-width: 400px;
	}
}
</style>
