<template>
	<div>
		<h1>News-Artikel bearbeiten</h1>
		<div v-if="idValid">
			<span class="field-caption">ID-Name</span>
			<input v-model="article.name" type="text" class="styled block input" />
			<span class="field-caption">Titel</span>
			<input v-model="article.title" type="text" class="styled block input" />
			<span class="field-caption">Autor</span>
			<input v-model="article.author" type="text" class="styled block input" />
			<span class="field-caption">Datum erstellt</span>
			<input v-model="article.date_created" type="date" class="styled block input" />
			<span class="field-caption">Inhalt</span>
			<text-editor ref="editor" />
			<button class="styled mint padded" @click="saveArticle()">Speichern</button>
			<router-link to="/admin/articles/manage" class="styled button red" style="margin-left: 10px"
				>Abbrechen</router-link
			>
		</div>
		<div v-else>
			<p>Den Artikel, den du bearbeiten willst, gibt es nicht, oder der Link ist falsch.</p>
			<router-link to="/admin/articles/create" class="styled button mint"
				>Neuen Artikel erstellen</router-link
			>
		</div>
		<popup ref="popupConfirmCancel">
			<span
				>Bist du dir sicher, dass du das Bearbeiten abbrechen willst? Jegliche Änderungen werden
				zurückgestzt.</span
			>
		</popup>
	</div>
</template>

<script>
import TextEditor from "@/components/TextEditor";
import Popup from "@/views/Popup.vue";
export default {
	components: {
		TextEditor,
		Popup,
	},

	data() {
		return {
			article: null,
			idValid: true,
		};
	},

	computed: {
		emptyArticle() {
			return {
				author: null,
				content: null,
				date_created: new Date().toISOString().slice(0, 10),
				name: null,
				title: null,
			};
		},
	},

	created() {
		this.article = this.emptyArticle;
		this.$http
			.get(this.$store.getters.apiUrl + "news_articles/" + this.$route.params.id)
			.then((res) => {
				if (!res.body.empty) {
					this.loadArticle(res.body);
				} else {
					this.idValid = false;
				}
			})
			.catch((_err) => this.$store.dispatch("httpError"));
	},

	methods: {
		loadArticle(article) {
			this.article = article;
			this.$refs.editor.setEditorContent(this.article.content);
		},
		saveArticle() {
			this.$store.commit("setAppLoadingStatus", true);
			this.$store.commit("setAppLoadingText", "Anfrage wird bearbeitet...");
			this.article.content = this.$refs.editor.getEditorContent();
			this.$http
				.put(this.$store.getters.apiUrl + "news_articles/" + this.article.id, this.article)
				.then((_res) => {
					this.$store.dispatch("alert", {
						message: "Artikel wurde erfolgreich gespeichert.",
						type: "success",
					});
					setTimeout(() => this.$router.go(-1), 1000);
				})
				.catch((err) => {
					console.log(err);
					this.$store.dispatch("httpError");
				})
				.finally(() => this.$store.commit("setAppLoadingStatus", false));
		},
	},
};
</script>

<style lang="scss" scoped>
span.field-caption {
	font-weight: bold;
	font-size: 18px;
}
input.input {
	width: 400px;
	margin-bottom: 20px;
}
</style>
