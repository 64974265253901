<template>
	<div class="base">
		<div class="header">
			<h3 style="margin: 0">
				{{ groupName }}, {{ datetimeFull }}, {{ request.helpers_min }}-{{ request.helpers_desired }}
				Helfer
			</h3>
			<div
				class="status"
				:class="
					(request.confirmed
						? request.helpers_assigned > 0
							? 'accepted'
							: 'rejected'
						: 'unconfirmed') +
					' ' +
					(past ? 'past' : 'running')
				"
			>
				{{
					running
						? request.confirmed
							? request.helpers_assigned > 0
								? "Bestätigt"
								: "Abgelehnt"
							: "Ausstehend"
						: "Vergangen"
				}}
			</div>
		</div>
		<!-- <details>
				<summary>JSON</summary>
				<pre>{{ json }}</pre>
			</details> -->
		<div class="details">
			<template v-if="mode == 'readonly' && request.confirmed">
				<div class="wide">
					<div class="title">Zugeteilte Helfer</div>
				</div>

				<div
					class="confirmation-amount small"
					:class="nonzero ? 'nonzero' : 'zero'"
					style="grid-row: span 2"
				>
					<strong style="font-size: 18pt">{{ request.helpers_assigned }}<br /></strong>
					<span v-if="one">zugeteilter Helfer</span>
					<span v-else>zugeteilte Helfer</span>
					<em v-if="nonzero && pending"><br />(provisorisch)</em>
				</div>

				<div class="confirmation-box wide">
					<div v-if="request.assignment_comment" class="confirmation-text">
						<strong>Kommentar der Helferstufe:</strong> {{ request.assignment_comment }}
					</div>
					<div v-else class="confirmation-text">
						Diese Anfrage wurde von der Helferstufe bestätigt. Es wurde kein Kommentar hinterlassen.
					</div>
				</div>
			</template>

			<div class="fill-row">
				<hr />
			</div>

			<div class="title">Minimale Helferanzahl</div>
			<div class="value">{{ request.helpers_min }}</div>
			<div class="title">{{ singleDay ? "Zeit von" : "Datum/Zeit von" }}</div>
			<div class="value">{{ datetimeFromNoDate }}</div>
			<div class="title">Thema</div>
			<div class="value">{{ request.theme || "Keine Angabe" }}</div>

			<div class="title">Erwünschte Anzahl</div>
			<div class="value">{{ request.helpers_desired }}</div>
			<div class="title">{{ singleDay ? "Zeit bis" : "Datum/Zeit bis" }}</div>
			<div class="value">{{ datetimeTo }}</div>
			<div class="title">Einsatzort</div>
			<div class="value">{{ request.location }}</div>

			<div class="title">Mat/Verkleidung</div>
			<div class="value">{{ request.costume || "Keine Angabe" }}</div>
			<div class="title">Bespr. Zeit</div>
			<div class="value">{{ briefingTime }}</div>
			<div class="title">Bespr. Ort</div>
			<div class="value">{{ request.briefing_location }}</div>

			<div class="fill-row">
				<div class="title">Beschreibung</div>
				<div class="remarks">{{ request.remarks || "Keine Bemerkungen" }}</div>
			</div>

			<form v-if="mode == 'readwrite'" id="admin-form" action="" @submit.prevent="onAssign">
				<div>
					<div class="title">Helfer zuteilen</div>
				</div>

				<div class="block-action-select">
					<div>Aktion</div>
					<div class="admin-toolbar action-select">
						<input
							id="action-accept"
							v-model="action"
							form="admin-form"
							type="radio"
							name="action"
							value="accept"
							required
						/>
						<label class="green" for="action-accept">Zuweisen</label>
						<input
							id="action-reject"
							v-model="action"
							form="admin-form"
							type="radio"
							name="action"
							value="reject"
							required
						/>
						<label class="red" for="action-reject">Ablehnen</label>
					</div>
				</div>

				<div class="block-num-assigned">
					<div>Anz. Helfer</div>
					<input
						id="amount"
						v-model="assign"
						form="admin-form"
						class="admin-toolbar"
						type="number"
						:min="action == 'accept' ? 1 : 0"
						:max="action == 'reject' ? 0 : 30"
						name="amount"
						required
						:disabled="action == 'reject'"
					/>
				</div>

				<div style="flex: 2">
					<div>Begründung/Bemerkung</div>
					<textarea
						id="reason"
						v-model="reason"
						form="admin-form"
						class="admin-toolbar medium"
						name="reason"
						:required="action == 'reject'"
					></textarea>
				</div>

				<div>
					<div>Bestätigung</div>
					<input
						type="submit"
						value="Absenden"
						form="admin-form"
						class="styled blue admin-toolbar"
						required
					/>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import HelperRequestObject from "@/mixins/helperRequestObject";

export default {
	name: "HelperRequestCard",

	mixins: [HelperRequestObject],

	props: {
		request: {
			type: Object,
			default: () => ({}),
		},

		mode: {
			type: String,
			default: "readonly",
		},
	},

	data() {
		return {
			group: null,
			reason: this.request.assignment_comment,
			assign: this.request.helpers_assigned,
			savedAssign: 0,
			action: "accept",
		};
	},

	watch: {
		action(val) {
			if (val == "reject") {
				this.savedAssign = this.assign;
				this.assign = 0;
			} else if (val == "accept") {
				this.assign = this.savedAssign;
				this.savedAssign = "0";
			}
		},

		request(req) {
			this.reason = req.assignment_comment;
			this.assign = req.helpers_assigned;
			this.savedAssign = 0;
			this.action = req.helpers_assigned > 0 ? "accept" : "reject";
		},
	},

	created() {
		this.fetchGroup();
	},

	methods: {
		async fetchGroup() {
			if (this.request?.group_id == null) {
				this.group = null;
				return;
			}

			const url = this.$store.getters.groupsUrl + this.request.group_id;
			this.group = (await this.$http.get(url)).data;
		},

		onAssign(event) {
			const formData = new FormData(event.target);
			// Convert to JSON
			const data = Object.fromEntries(formData.entries());
			data.amount ||= 0;

			this.$emit("submit", data);
		},

		log(...objs) {
			console.log(...objs);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";

.base {
	padding: 10px 20px 20px 20px;
	margin-bottom: 30px;
	border: 2px solid #0002;
	border-radius: 8px;
	box-shadow: 2px 1px 20px #0002;

	color: $dark;

	.confirmation-amount {
		background-color: rgba($mint, 0.3);
		border-radius: 10px;
		padding: 10px 15px;
		border: 1px solid $mint;
		color: darken($mint, 20%);
		text-align: center;

		&.zero {
			color: darken($red, 20%);
			border-color: $red;
			background-color: rgba($red, 0.3);
		}
	}

	.confirmation-box {
		display: flex;
		align-items: center;
		gap: 20px;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 30px;
		margin-top: 10px;

		.status {
			color: $white;
			padding: 7px 15px;
			background-color: $dark;
			border-radius: 6px;
			box-shadow: 2px 1px 15px #0003;
			font-weight: bold;
			text-transform: uppercase;

			&.accepted {
				background-color: $mint;
			}

			&.rejected {
				background-color: $red;
			}

			&.unconfirmed {
				background-color: $yellow;
				color: $blue;
			}

			&.past {
				background-color: $blue;
			}
		}
	}

	.details {
		display: grid;
		grid-template-columns: repeat(6, auto);
		gap: 15px 20px;
		overflow: auto;
		align-items: center;

		.title {
			// text-transform: uppercase;
			color: $blue;
		}

		.value {
			font-weight: bold;
			padding-right: 30px;
		}

		.remarks {
			padding: 7px;
			border: 1.5px solid #0002;
			border-radius: 8px;
			margin: 8px 0;
		}

		.small {
			grid-column: span 2;
		}

		.medium {
			grid-column: span 3;
		}

		.wide {
			grid-column: span 4;
		}

		.fill-row {
			grid-column: 1 / -1;
		}
	}
}

textarea.admin-toolbar {
	font-family: $app-sans-font;
	background-color: white;
	border: 1px solid rgba($blue, 0.2);
	margin: 0;
}

input[type="number"].admin-toolbar {
	border: 1px solid rgba($blue, 0.2);
	box-shadow: 0 0 15px #0001;
	border-radius: 5px;
	font-size: 20pt;
	max-width: 3em;
	text-align: center;

	&:disabled {
		content: "0";
	}
}

.admin-toolbar.action-select {
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	height: 100%;

	input[type="radio"] {
		display: none;

		& + label {
			border: 1px solid rgba($blue, 0.2);
			padding: 5px;
			flex: 1;
			text-align: center;
			line-height: 1.5em;
			font-weight: bold;

			&:first-of-type {
				border-radius: 5px 5px 0 0;
			}

			&:last-of-type {
				border-radius: 0 0 5px 5px;
			}

			&:hover {
				cursor: pointer;
			}
		}

		&:checked + label {
			background-color: $blue;
			color: white;

			&.green {
				background-color: $mint;
			}

			&.red {
				background-color: $red;
			}
		}
	}
}

#admin-form {
	display: flex;
	grid-column: 1 / -1;
	gap: 20px;

	& > * {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: stretch;
		gap: 10px;

		& > *:last-child {
			flex: 1;
		}
	}

	.block-action-select {
		flex-basis: 12em;
	}
}

input[type="submit"].admin-toolbar {
	margin: 0;
}

@media screen and (max-width: $m-width) {
	.base {
		box-sizing: border-box;

		.header {
			flex-direction: column-reverse;
			gap: 20px;
			align-items: flex-start;
			box-sizing: border-box;
		}

		.details {
			grid-template-columns: repeat(2, 1fr);
			grid-auto-flow: row;
			box-sizing: border-box;

			.small,
			.medium,
			.wide,
			.fill {
				grid-column: span 2;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.base .details * {
		grid-column: 1 / -1;
	}

	#admin-form {
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		max-width: 100%;

		& > * {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: flex-start;
			gap: 10px;
			position: relative;
			max-width: 100%;
			box-sizing: border-box;

			& > *:last-child {
				flex: 0;
			}
		}

		.block-action-select {
			flex-basis: auto;
		}

		.block-num-assigned input[type="number"] {
			box-sizing: border-box;
			max-width: 100%;
		}
	}
}
</style>
