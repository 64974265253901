<template>
	<div>
		<directus-page page-id="stuetzpunkte"></directus-page>
	</div>
</template>
<script>
import DirectusPage from "@/components/DirectusPage";

export default {
	components: { DirectusPage },
};
</script>
<style lang="scss" scoped>
@import "src/styles/vars";

.legend {
	font-family: $app-serif-font;
}
</style>
