<template>
	<div id="container">
		<h3 class="title">Admin-Toolbar</h3>
		<p>Die Cevi WIE Website wird neuerdings über das Directus CMS verwaltet.</p>
		<div id="button-container">
			<!-- <router-link to="/admin/articles/manage" class="button styled padded red"
				>News-Artikel verwalten</router-link
			>
			<router-link to="/admin/aktuelles/manage" class="button styled padded red"
				>"Aktuelles" Bilder verwalten</router-link
			> -->
			<a :href="`${apiUrl}directus/public/`" target="_blank" class="button styled red">
				Cevi WIE CMS
			</a>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		apiUrl() {
			return this.$store.getters.apiUrl;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/vars";

button {
	margin: 5px 10px;
	font-weight: normal;
	width: 100%;
}

.title {
	margin-top: 0;
}

#container {
	width: 100%;
	box-sizing: border-box;
	background-color: $light-grey;
	border-radius: $border-radius-small;
	margin-bottom: 30px;
	padding: 20px;
}

#button-container {
	display: flex;
	justify-content: space-evenly;
	align-items: center;

	a {
		align-self: stretch;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-basis: 100%;
		margin-bottom: 0;
	}
}

@media (max-width: $m-width) {
	#button-container {
		flex-direction: column;
	}
}
</style>
