<template>
	<div id="nav">
		<navbar-desktop id="navbar-desktop" :navbar-items="navbarItems" />
		<navbar-mobile
			id="navbar-mobile"
			:navbar-items="navbarItems"
			@mobile-menu-enabled="$emit('mobile-menu-enabled', $event)"
		/>
	</div>
</template>

<script>
import NavbarDesktop from "@/views/Navbar/NavbarDesktop";
import NavbarMobile from "@/views/Navbar/NavbarMobile";
export default {
	components: {
		NavbarDesktop,
		NavbarMobile,
	},
	props: {
		navbarItems: {
			type: Array,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

#navbar-desktop {
	display: none;
}
#nav {
	position: sticky;
	z-index: 100;
	top: 0;
}
@media (min-width: $m-width) {
	#navbar-desktop {
		display: block;
	}
	#navbar-mobile {
		display: none;
	}
}
</style>
