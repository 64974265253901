<template>
	<div>
		<directus-page page-id="dokumente"></directus-page>
	</div>
</template>
<script>
import DirectusPage from "@/components/DirectusPage";

export default {
	components: { DirectusPage },
};
</script>
<style lang="scss" scoped>
@import "src/styles/vars.scss";

a.styled {
	display: inline-block;
	box-sizing: border-box;
	margin: 10px 0;
	color: $cevi-blue;

	&:hover {
		border-color: $cevi-blue;
	}
}

a.styled:not(:hover) {
	border-bottom: 2px solid #0000;
}
</style>
