<template>
	<div>
		<h1>"Aktuelles"-Bild hinzufügen</h1>
		<div v-if="idValid">
			<span class="field-caption">Titel</span>
			<input v-model="item.title" type="text" class="styled block input" />
			<span class="field-caption">Verlinkung</span>
			<span style="margin-left: 10px">Leer lassen für keine Verlinkung</span>
			<input v-model="item.link" type="text" class="styled block input" />
			<span class="field-caption">Bild</span>
			<image-upload ref="imageFilePicker" v-model="file" class="filepicker" required />
			<check-box v-model="addDesc">Beschreibung hinzufügen</check-box>
			<div v-if="addDesc">
				<span class="field-caption">Beschreibung (optional)</span>
				<textarea v-model="item.description" type="text" class="styled block input"></textarea>
			</div>
			<button class="styled mint padded" @click="postTopical()">Änderungen speichern</button>
			<button class="styled red padded" @click="$refs.popupConfirmCancel.open()">Abbrechen</button>
		</div>
		<div v-else>
			<p>Den Artikel, den du bearbeiten willst, gibt es nicht, oder der Link ist falsch.</p>
			<router-link to="/admin/articles/create" class="styled button mint"
				>Neuen Artikel erstellen</router-link
			>
		</div>
		<popup-view ref="popupConfirmCancel" @confirm="$router.push('/admin/aktuelles/manage')">
			<span
				>Bist du dir sicher, dass du das Bearbeiten abbrechen willst? Jegliche Änderungen werden
				zurückgestzt.</span
			>
		</popup-view>
	</div>
</template>

<script>
import CheckBox from "@/components/CheckBox";
import ImageUpload from "@/components/ImageUpload";
import PopupView from "@/views/Popup";

export default {
	components: {
		CheckBox,
		ImageUpload,
		PopupView,
	},

	data() {
		return {
			item: null,
			addDesc: false,
			file: null,
			idValid: false,
		};
	},

	computed: {
		emptyItem() {
			return {
				img_url: null,
				title: null,
				link: null,
				description: null,
			};
		},
	},

	created() {
		this.item = this.emptyItem;
		this.loadItem();
	},

	methods: {
		postTopical() {
			this.$refs.imageFilePicker
				.upload()
				.then((res) => {
					this.item.img_url = res.body;
					this.$http
						.post(this.$store.getters.apiUrl + "topical_images", this.item)
						.then((_x) =>
							this.$store.dispatch("alert", {
								message: '"Aktuelles"-Bild wurde erfolgreich erstellt.',
								type: "success",
							})
						)
						.catch((err) => {
							this.$store.dispatch("httpError");
							console.error("Failed to post topical news item", err);
						});
				})
				.catch((err) => {
					this.$store.dispatch("httpError");
					console.error("Failed to upload image", err);
				});
		},

		loadItem() {
			let id = this.$route.params.id;
			this.$http.get(this.$store.getters.apiUrl + "topical_images/" + id).then((res) => {
				this.idValid = !res.body.empty;
				if (this.idValid) {
					this.item = res.body;
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

span.field-caption {
	font-weight: bold;
	font-size: 18px;
}
input.input {
	width: 400px;
	margin-bottom: 20px;
}
textarea.input {
	margin-top: 10px;
	font-size: 16px;
	font-family: $app-sans-font;
}
.filepicker {
	margin: 20px 0;
}
</style>
