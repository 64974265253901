<template>
	<div id="diagram">
		<div v-for="(row, rowIndex) of rows" :key="rowIndex" class="row">
			<div class="field year">{{ row.year }}</div>
			<div class="field group female">
				<div v-if="row.female" class="group-name">{{ row.female.name }}</div>
				<a v-if="row.female" :href="getGroupEmail(row.female.name)" class="group-lead">{{
					row.female.lead
				}}</a>
			</div>
			<div class="field group male">
				<div v-if="row.male" class="group-name">{{ row.male.name }}</div>
				<a v-if="row.male" :href="getGroupEmail(row.male.name)" class="group-lead">{{
					row.male.lead
				}}</a>
			</div>
		</div>
	</div>
</template>

<script>
import groups from "@/resources/organigramm/stufen.json";

export default {
	data() {
		return {
			rows: groups,
		};
	},

	methods: {
		getGroupEmail(groupName) {
			return "mailto:" + groupName.toLowerCase() + "@ceviwie.ch";
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";
#diagram {
	.row {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 2fr 2fr;
		grid-gap: 10px;
		margin-bottom: 10px;
		.field {
			padding: 10px 0;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
		}
		.field.year {
			align-self: center;
		}
		.field.group {
			background-color: #f7f7f7;
			padding: 10px;
			padding-left: 15px;
			position: relative;

			&::before {
				content: "";
				width: 5px;
				background-color: #0003;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
			}
			&.male::before {
				background-color: $light-blue;
			}
			&.female::before {
				background-color: $rose;
			}

			.group-name {
				font-weight: bold;
				font-size: 1.1em;
				margin-bottom: 5px;
			}
			.group-lead {
				font-weight: lighter;
				color: saturate($blue, 50%);
			}
		}
	}
}

@media screen and (min-width: $m-width) {
	#diagram {
		display: flex;
		.row {
			margin-right: 10px;
			&:last-of-type {
				margin-right: 0;
			}
			grid-template-columns: unset;
			grid-template-rows: 1fr 2fr 2fr;

			.field.year {
				align-self: center;
			}
		}
	}
}

@media (max-width: 480px) {
	#diagram {
		font-size: 12px;
	}
}
</style>
