<template>
	<div>
		<h1>"Aktuelles"-Bild hinzufügen</h1>
		<div>
			<span class="field-caption">Titel</span>
			<input v-model="item.title" type="text" class="styled block input" required />
			<span class="field-caption">Verlinkung</span>
			<span style="margin-left: 10px">Leer lassen für keine Verlinkung</span>
			<input v-model="item.link" type="text" class="styled block input" />
			<span class="field-caption">Bild</span>
			<image-upload ref="imageFilePicker" v-model="file" class="filepicker" required />
			<check-box v-model="addDesc">Beschreibung hinzufügen</check-box>
			<div v-if="addDesc">
				<span class="field-caption">Beschreibung (optional)</span>
				<textarea v-model="item.description" type="text" class="styled block input"></textarea>
			</div>
			<button class="styled mint padded" @click="postTopical()">Erstellen</button>
			<button class="styled red padded" @click="$refs.popupConfirmCancel.open()">Abbrechen</button>
			<popup-view ref="popupConfirmCancel" @confirm="$router.push('/admin/aktuelles/manage')">
				<span
					>Bist du dir sicher, dass du das Bearbeiten abbrechen willst? Jegliche Änderungen werden
					zurückgestzt.</span
				>
			</popup-view>
		</div>
	</div>
</template>

<script>
import CheckBox from "@/components/CheckBox";
import ImageUpload from "@/components/ImageUpload";
import PopupView from "@/views/Popup";

export default {
	components: {
		CheckBox,
		ImageUpload,
		PopupView,
	},

	data() {
		return {
			item: null,
			addDesc: false,
			file: null,
		};
	},

	computed: {
		emptyItem() {
			return {
				img_url: null,
				title: null,
				link: null,
				description: null,
			};
		},
	},

	created() {
		this.item = this.emptyItem;
	},

	methods: {
		postTopical() {
			if (!this.file) {
				this.$store.dispatch("alert", {
					message: "Bitte Bild auswählen",
					title: "Upload fehlgeschlagen",
					type: "error",
				});
				return;
			}
			this.$refs.imageFilePicker
				.upload()
				.then((res) => {
					this.item.img_url = res.body;
					this.$http
						.post(this.$store.getters.apiUrl + "topical_images", this.item)
						.then((_x) => {
							this.$store.dispatch("alert", {
								message: '"Aktuelles"-Bild wurde erfolgreich erstellt.',
								type: "success",
							});
							this.$router.go(-1);
						})
						.catch((err) => {
							this.$store.dispatch("httpError");
							console.error("Failed to post topical news item", err);
						});
				})
				.catch((err) => {
					this.$store.dispatch("httpError");
					console.error("Failed to upload image", err);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

span.field-caption {
	font-weight: bold;
	font-size: 18px;
}
input.input {
	width: 400px;
	margin-bottom: 20px;
}
textarea.input {
	margin-top: 10px;
	font-size: 16px;
	font-family: $app-sans-font;
}
.filepicker {
	margin: 20px 0;
}
</style>
