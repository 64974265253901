<template>
	<div>
		<h1>Profil</h1>
		<profile-management />
		<group-settings />
		<router-link to="/home" class="styled">Zurück zur Homepage</router-link>
	</div>
</template>

<script>
import GroupSettings from "./Settings/GroupSettings";
import ProfileManagement from "@/pages/Settings/ProfileManagement";

export default {
	components: {
		GroupSettings,
		ProfileManagement,
	},
};
</script>

<style scoped></style>
