<template>
	<transition name="msg-box">
		<div v-show="visible" id="message-box" key="0" :class="type">
			<h3>
				<span v-html="title"></span>
			</h3>
			<p>
				<span style="font-family: 'Montserrat', sans-serif" v-html="message"></span>
			</p>
		</div>
	</transition>
</template>

<script>
export default {
	data() {
		return {
			visible: false,
			title: "Some message goes here",
			message:
				"This is the message. This is supposed to be a bit longer than the title, so I'm writing " +
				"this.",
			type: "normal",

			messageQueue: [],
		};
	},
	computed: {
		maxItems: () => 5,
	},
	mounted() {
		this.setTopOffset();

		// bus.$on("alert", this.addMessageToQueue);
		// bus.$on("setPermanentMessage", this.setPermanentMessage);
		// bus.$on("removePermanentMessage", this.removePermanentMessage);
	},
	methods: {
		setTopOffset() {
			if (document.getElementById("navbar")) {
				document.getElementById("message-box").style.top =
					document.getElementById("navbar").clientHeight + "px";
			}
		},
		setMessage(message) {
			this.title = message.title;
			this.message = message.message;
			this.type = message.type;
		},
		showMessageBox() {
			//Return if no message in queue
			if (this.messageQueue.length == 0) {
				return;
			}
			//shift message from queue
			var message = this.messageQueue.shift();
			//set view text, show view
			this.setMessage(message);
			this.visible = true;
			//Set timeout for message show
			setTimeout(() => {
				//hide message once done
				this.hideMessageBox();
				//Show next message in queue (wait first)
				setTimeout(this.showMessageBox, 300);
			}, message.duration * 1000);
		},
		hideMessageBox() {
			this.visible = false;
		},
		addMessageToQueue(arg) {
			//Create message
			var message = {
				message: arg.message || arg,
				duration: arg.duration || 3,
				title: arg.title || null,
				type: arg.type || "normal",
			};

			//Add it to the queue
			if (this.messageQueue.length <= this.maxItems) {
				this.messageQueue.push(message);
			}

			//If not already showing, start showing sequence
			if (!this.visible) {
				this.showMessageBox();
			}
		},
		//Shows permanent notification. Warning: disables all other incoming messages, until hidden
		//Todo: Cache permanent message, show incoming messages, show permanent message again once done
		setPermanentMessage(msg, title = "", type = "normal") {
			this.setMessage({ message: msg, title: title, type: type });
			this.visible = true;
		},
		removePermanentMessage() {
			this.visible = false;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../styles/vars";

#message-box {
	position: fixed;
	z-index: 1000;
	width: calc(100% - 30px);
	margin: 15px;
	padding: $d-el-padding $d-el-padding * 2;
	box-sizing: border-box;
	background-color: $white;
	box-shadow: 0 0 30px #0003;
	border-radius: $border-radius-large;

	h3 {
		margin: 10px 0;
	}
	p {
		margin-bottom: 10px;
	}

	&.warning {
		background-color: $yellow;
	}
	&.error {
		background-color: $red-vibrant;
		color: $white;
	}
	&.success {
		background-color: $mint;
		color: $white;
	}
}
//Animation
.msg-box-enter-active {
	animation: msg-box-enter 500ms;
}
.msg-box-leave-active {
	animation: msg-box-leave 500ms;
}

@keyframes msg-box-enter {
	0% {
		transform: translateY(-300px);
		opacity: 0;
	}
	50% {
		transform: translateY(20%);
	}
	100% {
		transform: none;
		opacity: 1;
	}
}
@keyframes msg-box-leave {
	0% {
		transform: none;
		opacity: 1;
	}
	20% {
		transform: translateY(40px);
	}
	100% {
		transform: translateY(-300px);
		opacity: 0;
	}
}
</style>
