<template>
	<div>
		<h1>Zopfback-Aktion {{ year }}</h1>
		<h2>Online-Bestellung</h2>
		<p>
			Falls Sie nicht zuhause waren, als wir vorbeigekommen sind, können Sie hier ihren Zopf
			alternativ online bestellen. Die Zöpfe werden am <strong>{{ deliveryDate }}</strong> verteilt.
			Bitte halten Sie an diesem Morgen den zu zahlenden Betrag bereit.
		</p>
		<p>Die Online-Bestellung ist {{ onlineOrderDates }} verfügbar.</p>
		<div v-if="isDevMode">
			<button class="styled" style="margin-bottom: 20px" @click="fillSampleData">
				Fill with sample data
			</button>
		</div>
		<auto-form
			ref="orderForm"
			v-model="formModel"
			:validator="formValidator"
			:submit-disabled="submitDisabled"
			class="wide columns bold"
			@submit="submitOrder($event)"
		></auto-form>
		<h3>Speziallieferungen</h3>
		<p>
			Unsere Hauptliefergebiete sind Wiesendangen, Elsau und Hegi. Andernfalls schicken Sie einfach
			Ihre Bestellung ab, und wir benachrichtigen Sie, ob wir den Zopf zu Ihnen nach hause liefern
			können.
		</p>
		<h3>Datenschutz</h3>
		<p>
			Ihre Email wird ausschliesslich dazu gebraucht, Ihnen eine Bestätigung zu schicken. Falls Sie
			benachrichtigt werden wollen, falls z.B. eine Verspätung vorliegt, geben Sie bitte auch eine
			Telefonnummer an. Alle Daten werden nach der Zopfback-Aktion wieder gelöscht.
		</p>
	</div>
</template>

<script>
import AutoForm from "../../components/AutoForm";
import formModel from "../../resources/zoback-order-form-model";

const _ = require("lodash");

const CITY_ID_SPC = "spc";
const ORDER_LIST_SPC_ID = 0; // TODO: Put in config
const WEB_AUTHOR_ID = 0;
const cityMappings = {
	8542: "wie",
	8352: "els",
	8409: "heg",
};

const sampleOrder = {
	firstName: "David",
	lastName: "Davidoff",
	address_street: "Stadthausstrasse",
	address_nr: "10b",
	address_zip: "8400",
	address_city: "Winterthur",
	email: "hankoon@cevi.ws",
	tel: "+41796164585",
	amount_small: "12",
	amount_large: "96",
	time: "time_10_11",
	comment: "Dies ist ein Test.",
};

export default {
	components: {
		AutoForm,
	},

	data() {
		return {
			formValidator: {
				func(data) {
					if (data.amount_large + data.amount_small <= 0) return false;
					if (data.amount_large < 0 || data.amount_small < 0) return false;
					return true;
				},
				errorMessage: "Bitte Anzahl angeben",
			},
			submitDisabled: false,
		};
	},
	computed: {
		year() {
			return new Date(this.$store.getters.features.zoback.delivery_date).getFullYear().toString();
		},
		deliveryDate() {
			return new Date(this.$store.getters.features.zoback.delivery_date).toLocaleDateString(
				"de-CH"
			);
		},
		onlineOrderDates() {
			return (
				"vom " +
				this.$store.getters.features.zoback.online_order_dates
					.map((x) => new Date(x).toLocaleDateString("de-CH"))
					.join(" bis am ")
			);
		},
		formModel() {
			return _.cloneDeep(formModel);
		},
		isDevMode() {
			return process.env.NODE_ENV === "development";
		},
	},

	methods: {
		async submitOrder(order) {
			// Prompt the user to confirm his order
			let userConfirms = confirm("Bestellung abschicken?");
			if (userConfirms) {
				// Disable the submit button so the user can't accidentally send multiple
				this.submitDisabled = true;
				this.$store.commit("setAppLoadingText", "Bestellung wird verarbeitet...");
				this.$store.commit("setAppLoadingStatus", true);

				// Prepare order for staging
				// Order has no separate first/last names
				order.name = order.firstName + " " + order.lastName;
				order.paid = false; // Pay on delivery
				// Set other variables
				const cityId = cityMappings[order.address_zip] || CITY_ID_SPC;
				if (cityId === CITY_ID_SPC) {
					order.city_special = order.address_city;
				}
				order.author = WEB_AUTHOR_ID;
				order.address_city = cityId;
				order.localId = null;
				order.status = "LOCAL";
				order.order_list = ORDER_LIST_SPC_ID;

				try {
					// Stage order
					const apiUrl = this.$store.getters.features.zoback.api_url;
					const stageEndpoint = apiUrl + "/orders/stage";
					const stageParams = {
						no_publish: false,
						ignore_address_not_found: false,
					};
					const stageResponse = await this.$http.post(stageEndpoint, order, {
						params: stageParams,
					});
					const successMsg = stageResponse.data.message;
					// Send confirmation and ZoBack admin email
					const _response = await this.$http.post(
						this.$store.getters.apiUrl + "sendmail/zoback-order",
						order
					);
					// If it was successful, show an alert to the user and navigate back
					this.$store.dispatch("alert", {
						type: "success",
						title: successMsg || "Bestellung versendet!",
						message:
							"Deine Zopf-Bestellung wurde erfolgreich abgesendet. Du erhälst in Kürze noch eine " +
							"Email-Bestätigung.",
					});
					// Clear the form
					this.$refs.orderForm.clearForm();
					// Navigate back
					this.$router.go(-1);
				} catch (err) {
					// Handle error: Show error message
					console.error(err);
					this.$store.dispatch("alert", {
						type: "error",
						title: "Senden fehlgeschlagen",
						message:
							err.body.message ||
							err.body.errmsg ||
							"Etwas ist beim Senden der Bestellung fehlgeschlagen. Bitte versuche es später " +
								"erneut oder kontaktiere den Administrator.",
					});
				} finally {
					// In any case, reset the disabled state of the submit button
					this.submitDisabled = false;
					this.$store.commit("setAppLoadingStatus", false);
				}
			} else {
				console.log("User cancelled order request");
			}
		},
		fillSampleData() {
			this.$refs.orderForm.setFieldValues(sampleOrder);
		},
	},
};
</script>

<style lang="scss" scoped></style>
