import validateEmail from "./validators/email.validator";
import validateTel from "./validators/tel.validator";

export default {
	title: "Reservationsanfrage Pizzamobil",
	description: "Reservieren Sie hier unverbindlich unser Pizzamobil",
	items: {
		firstName: {
			type: "text",
			description: "Vorname",
			required: true,
			autocomplete: "given-name",
		},
		lastName: {
			type: "text",
			description: "Name",
			required: true,
			autocomplete: "family-name",
		},
		company: {
			type: "text",
			description: "Firma/Verein",
			required: false,
			autocomplete: "organization",
		},
		occasion: {
			type: "text",
			description: "Anlass",
			required: true,
		},
		email: {
			type: "email",
			description: "Email",
			required: true,
			validator: {
				validate: validateEmail,
				errorMessage: "Keine gültige Email-Adresse",
			},
			autocomplete: "email",
		},
		tel: {
			type: "tel",
			description: "Telefon",
			required: true,
			validator: {
				validate: validateTel,
				errorMessage: "Keine gültige Telefonnummer",
			},
			autocomplete: "tel",
		},
		address: {
			type: "text",
			description: "Adresse",
			required: true,
		},
		town: {
			type: "text",
			description: "PLZ/Ort",
			required: true,
		},
		startDate: {
			type: "date",
			description: "Datum von",
			required: true,
		},
		endDate: {
			type: "date",
			description: "Datum bis",
			required: true,
		},
		pickupDate: {
			type: "datetime-local",
			description: "Gewünschte Abholung",
			placeholder: "Datum, Zeit",
			required: true,
		},
		returnDate: {
			type: "datetime-local",
			description: "Gewünschte Rückgabe",
			placeholder: "Datum, Zeit",
			required: true,
		},
		remarks: {
			type: "textarea",
			description: "Bemerkungen",
			class: "wide colspan-12",
		},
		submit: {
			type: "submit",
			value: "Versenden",
			class: "mobile-span",
		},
	},
};
