<template>
	<div v-if="enabled" class="advent-base">
		<div
			v-for="(door, index) of visibleDoors"
			:key="index"
			class="container"
			:style="getRandomPositionStyle()"
		>
			<a class="door-link" :href="door.fileUrl" target="_blank">
				<img class="door-image" :src="door.image" alt="Advent" />
			</a>
		</div>
	</div>
</template>

<script>
import { adventCalendar, file } from "@/cms";

const positionStyles = {
	tl: { top: 0, left: 0 },
	tr: { top: 0, right: 0 },
	bl: { bottom: 0, left: 0 },
	br: { bottom: 0, right: 0 },
};

const positionStyleKeys = Object.keys(positionStyles);

export default {
	data() {
		return {
			enabled: false,
			currentRoute: null,
			doors: [],
		};
	},

	computed: {
		visibleDoors() {
			return this.doors.filter((x) => this.isVisible(x));
		},
	},

	watch: {
		$route: function (to) {
			this.currentRoute = to.fullPath;
		},
	},

	mounted() {
		this.fetchData();
		this.currentRoute = this.$route.fullPath;
	},

	methods: {
		setup() {
			const config = this.$store.getters.features.advent_calendar;
			console.log(config);
			this.enabled = config.enabled;
		},
		async fetchData() {
			// Get today's date
			const today = new Date();
			// If it's past christmas, don't show anything
			// All of the doors are still visible until the 26th
			if (today.getDate() > 26) {
				return;
			}
			// It's christmas time!!!
			const todayString = today.toISOString().split("T")[0];
			try {
				// Get all doors before this day
				const doors = await adventCalendar.getManyByField("active_date", todayString, "lte");
				// Get all door files (as promise)
				const doorFilePromises = doors.map((x) => x.door_contents).map((fileId) => file(fileId));
				// Await all
				const doorFileUrls = await Promise.all(doorFilePromises).then((files) =>
					files.map((file) => file.data.data.data.full_url)
				);
				// Add necessary properties
				doors.forEach((door, index) => {
					door.fileUrl = doorFileUrls[index];
					const doorDay = new Date(door.active_date).getDate();
					door.image = require(`../assets/img/advent-2020/Cevi_Adventskalender_${doorDay}.png`);
				});
				// Save in state
				this.doors = doors;
			} catch (error) {
				// We should not have any errors
				console.error(error, error.response);
			}
		},

		isVisible(door) {
			return door.activated_route == this.currentRoute;
		},

		getRandomPositionStyle() {
			return positionStyles[positionStyleKeys.random()];
		},
	},
};
</script>

<style lang="scss" scoped>
.advent-base {
	position: fixed;
	top: 60px;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 101;
	pointer-events: none;

	.container {
		position: absolute;
	}

	.door-link {
		display: block;
		width: 80px;
		height: 80px;
		margin: 30px;
		pointer-events: all;

		.door-image {
			width: 100%;
			height: 100%;
		}
	}
}
</style>
