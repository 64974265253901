<template>
	<div id="action-bar-base" :class="mode">
		<div id="action-bar" :class="mode">
			<input id="toggle-toolbar" v-model="open" type="checkbox" name="toggle-toolbar" />
			<label for="toggle-toolbar"><i class="fa-solid fa-angles-left"></i></label>
			<div class="content">
				<span class="action-title">Stufen-Schnellzugriff</span>
				<router-link
					v-if="showMatOrderLink"
					class="button styled blue"
					to="/material/bestellung"
					@click.native="onClick"
					>Matbestellung</router-link
				>
				<router-link
					v-if="groupLink"
					class="button styled blue"
					:to="groupLink"
					@click.native="onClick"
					>Stufe verwalten</router-link
				>

				<router-link
					v-if="showHelperAdminLink"
					class="button styled blue"
					to="/helfer/admin"
					@click.native="onClick"
					>Helfer-Tool</router-link
				>
				<router-link
					v-if="showHelperRequestLink"
					class="button styled blue"
					to="/helfer/uebersicht"
					@click.native="onClick"
					>Helfer anfragen</router-link
				>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "GroupToolbar",

	props: {
		mode: {
			type: String,
			default: "desktop",
		},
	},

	data() {
		return {
			groups: [],
			open: false,
		};
	},

	computed: {
		administeredGroupIds() {
			if (!this.$store.state.loggedIn) return [];
			const adminGroups = JSON.parse(this.$store.state.user.administered_groups);
			if (adminGroups.includes("*")) return "*";
			else return adminGroups;
		},

		groupLink() {
			const ids = this.administeredGroupIds;
			if (!ids || ids.length == 0 || ids.includes("*")) return null;
			return `/stufen/${ids[0]}`;
		},

		showHelperAdminLink() {
			if (!this.$store.state.user) return false;
			return ["global_admin", "helper_user"].includes(this.$store.state.user.role);
		},

		showHelperRequestLink() {
			if (!this.$store.state.user) return false;
			return ["global_admin", "group_user"].includes(this.$store.state.user.role);
		},

		showMatOrderLink() {
			if (!this.$store.state.user) return false;
			return ["group_user", "helper_user"].includes(this.$store.state.user.role);
		},
	},

	created() {},

	methods: {
		onClick() {
			this.$emit("click");
			this.open = false;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";

#action-bar-base {
	width: 100%;

	#action-bar {
		background-color: $mint;
		display: flex;
		gap: 15px;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;

		.content {
			gap: 15px;
			align-items: center;
			justify-content: flex-end;
			transition: max-width 500ms;
		}

		&.desktop {
			padding-right: 10px;
			padding-left: 10px;
		}

		&.mobile {
		}

		button,
		.button {
			font-size: 10pt;
			padding: 5px 10px;
		}

		.action-title {
			font-weight: bold;
			color: white;
			text-shadow: 0 0 5px #0003;
		}

		.action-button {
			margin: 0;
		}
	}
}

.desktop#action-bar-base {
	#action-bar {
		margin: 10px 10px 0 auto;
		position: absolute;
		right: 0;
		border-radius: 10px;
		box-shadow: 0 0 20px #0006;
		height: 50px;
		min-width: 50px;

		.content {
			display: flex;
			max-width: 0px;
			overflow: hidden;
			margin-left: -15px;
		}
	}

	#toggle-toolbar {
		display: none;

		& + label {
			display: inline-block;
			color: white;
			width: 1em;
			height: 1em;
			font-size: 1em;
			line-height: 1em;
			text-align: center;
			vertical-align: middle;
			border-radius: 5px;
			padding: 5px;
			transition: transform 200ms;

			&:hover {
				background-color: #fff6;
				cursor: pointer;
			}
		}
		&:checked + label {
			transform: scaleX(-1);
		}
		&:checked ~ .content {
			max-width: 100vw;
		}
	}
}

.mobile#action-bar-base {
	#action-bar {
		width: 100%;
		display: block;
		padding: 10px;

		.content {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
			justify-content: center;
			align-items: stretch;

			*:not(:first-child) {
				flex: 1;
			}

			*:first-child {
				width: 100%;
				text-align: center;
			}
		}

		a {
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		#toggle-toolbar,
		#toggle-toolbar + label {
			display: none;
		}
	}
}
</style>
